import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectUserList } from "../../../../redux/oms/omsSelectors";
import "./UserSelector.scss";

interface UserSelectorProps {
  descriptionRef: React.MutableRefObject<HTMLDivElement | undefined | null>;
  handleAdd: (val: string, id?: number) => void;
  search: string | null;
  keyAction: { action: string };
}

const UserSelector: React.FC<UserSelectorProps> = ({
  descriptionRef,
  handleAdd,
  search,
  keyAction,
}) => {
  const userList = useSelector((state) => selectUserList(state, search));
  const outputRef = useRef<null | HTMLDivElement>(null);
  const [position, setPostion] = useState<{ x: string; y: string }>({
    x: "0px",
    y: "0px",
  });
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    if (keyAction.action === "enter") {
      handleAdd(userList[selectedIndex].name, userList[selectedIndex].id);
    } else {
      const len = userList.length;
      const inc = keyAction.action === "up" ? -1 : 1;
      const newIndex = (len + selectedIndex + inc) % len;
      setSelectedIndex(newIndex);
    }
  }, [keyAction]);

  useEffect(() => {
    const textArea = descriptionRef?.current;
    const output = outputRef?.current;
    if (textArea && output) {
      const selection = window.getSelection()?.getRangeAt(0);
      if (selection) {
        const lastIndex = selection.startOffset;
        const selectedText =
          selection.startContainer.textContent?.substring(0, lastIndex) || "";
        const textAreaWidth = textArea.getBoundingClientRect().width - 16;
        let lineNumber = 0;
        textArea.innerHTML.split("<br>").forEach((text) => {
          output.innerHTML = text;
          lineNumber +=
            Math.round(output.getBoundingClientRect().width / textAreaWidth) +
            1;
        });
        output.innerHTML = selectedText;
        const maxX = textAreaWidth - 210;
        const x =
          Math.min(
            (output.getBoundingClientRect().width % textAreaWidth) + 4,
            maxX,
          ) + "px";
        const y = (1 - lineNumber) * 15 - 8 + "px";
        setPostion({ x, y });
      }
    }
  }, []);

  useEffect(() => {
    setSelectedIndex(0);
  }, [search]);

  return (
    <>
      {position && search != null && (
        <div
          className="user-selector-container"
          style={{ bottom: `calc(100% + ${position?.y})`, left: position?.x }}
        >
          {userList.map((option, index) => {
            return (
              <div
                className={`option ${selectedIndex === index && "selected"}`}
                key={index}
                onClick={() => handleAdd(option.name, option.id)}
              >
                <p className="value xetgo-font-tag">{option.name}</p>
              </div>
            );
          })}
        </div>
      )}
      <div ref={outputRef} className="textarea-input xetgo-font-tag"></div>
    </>
  );
};

export default UserSelector;
