import NotificationsIcon from "@mui/icons-material/Notifications";

export interface SideNavData {
  name: string;
  image: string;
  activeImage: string;
  link: string;
  options: { name: string; image: string; link: string; activeImage: string }[];
}

export const DefaultTabs: SideNavData[] = [
  {
    name: "EQS",
    image:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231222114948-book.svg",
    activeImage:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231222114948-book.svg",
    options: [
      {
        name: "RFQs",
        image:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231225100249-file-symlink+%281%29.svg",
        activeImage:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231222102432-rfq.svg",
        link: "/eqs/rfq",
      },
      {
        name: "Sourcing",
        image:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231222102358-folder-closed.svg",
        activeImage:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231223175611-folder-closed.svg",
        link: "/eqs/sourcing",
      },
      {
        name: "Quotation",
        image:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231222102358-construction.svg",
        activeImage:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231223175611-construction.svg",
        link: "/eqs/quotation",
      },
      {
        name: "Sampling",
        image:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231222102358-container.svg",
        activeImage:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231223175611-container.svg",
        link: "/eqs/sampling",
      },
    ],
    link: "/eqs",
  },
  {
    name: "XeTrack",
    image:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231225100750-school.svg",
    activeImage:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231006131745-school.svg",
    options: [
      {
        name: "Purchase Order",
        image:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231225100750-user-cog-2.svg",
        activeImage:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231225103838-user-cog-2+%281%29.svg",
        link: "/xetrack/purchase-order",
      },
      {
        name: "Sales Order",
        image:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231222154248-shovel.svg",
        activeImage:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231225103335-shovel.svg",
        link: "/xetrack/sales-order",
      },
      {
        name: "Delivery",
        image:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231222154248-truck.svg",
        activeImage:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231225103336-truck.svg",
        link: "/xetrack/delivery",
      },
      {
        name: "Artwork",
        image:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231222154248-file-box.svg",
        activeImage:
          "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231225103335-file-box.svg",
        link: "/xetrack/artwork",
      },
    ],
    link: "/xetrack",
  },
  {
    name: "Delivery Calendar",
    image:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240102063953-calendar_inactive.svg",
    activeImage:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240102063000-calendar.svg",
    options: [],
    link: "/delivery-calendar",
  },
  {
    name: "SKU",
    image:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240403075208-sku-list-inactive-icon.svg",
    activeImage:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240403060605-sku-active-icon.svg",
    options: [],
    link: "/sku",
  },
  {
    name: "Notification Centre",
    image:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240121173029-notifications-circle.svg",
    activeImage:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240121173121-Vector.svg",
    options: [],
    link: "/notifications",
  },
];

export const SideNavbarData = (role: string[]): SideNavData[] => {
  const tabList = [...DefaultTabs];
  if (role.indexOf("FILE_UPLOAD") > -1) {
    tabList.push({
      name: "Files",
      image:
        "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231005195431-quotation-icon.svg",
      activeImage:
        "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231006140848-quotationActive-icon.svg",
      options: [],
      link: "/files",
    });
  }
  return tabList;
};
