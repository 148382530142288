import Home from "../components/home/Home";
import Authentication from "../components/authentication/Authentication";
import Oms from "../components/oms/Oms";
import TaskDetail from "../components/oms/TaskDetail/TaskDetail";
import PurchaseOrderDetail from "../components/oms/PurchaseOrderDetail/PurchaseOrderDetail";
import XetTrack from "../components/xetTrack/XetTrack";
import XetDeliveryCalendar from "../components/XetDeliveryCalendar/XetDeliveryCalendar";
import XetNotification from "../components/shared/xet-notification/XetNotification";
import Sku from "../components/Sku/Sku";

const routes = [
  {
    path: "/files",
    Component: Home,
    protected: true,
  },
  {
    path: "/",
    Component: Authentication,
    protected: false,
  },
  {
    path: "/eqs/:category",
    Component: Oms,
    protected: true,
  },
  {
    path: "/eqs/:category/:id",
    Component: TaskDetail,
    protected: true,
  },
  {
    path: "/eqs/:category/:id/vendor/:vendor_id",
    Component: TaskDetail,
    protected: true,
  },
  {
    path: "/xetrack/:category",
    Component: XetTrack,
    protected: true,
  },
  {
    path: "/xetrack/:category/:id",
    Component: PurchaseOrderDetail,
    protected: true,
  },
  {
    path: "/xetrack/:category/:id/vendor/:vendor_id",
    Component: PurchaseOrderDetail,
    protected: true,
  },
  {
    path: "/delivery-calendar",
    Component: XetDeliveryCalendar,
    protected: true,
  },
  {
    path: "/sku",
    Component: Sku,
    protected: true,
  },
  {
    path: "/notifications",
    Component: XetNotification,
    protected: true,
  },
];

export default routes;
