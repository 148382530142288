import React, { useState } from "react";
import "./SkuCard.scss";
import { SKU } from "../../models/Sku";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import { ToastContainer, toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";

interface SkuCardProps {
  skuDetails: SKU;
  handleFileUpload: (
    event: React.ChangeEvent<HTMLInputElement>,
    tpmId: number,
  ) => void;
  updateLeadTime: (tpmId: number, updatedLeadTime: number) => void;
  deleteFile: (tpm_id: number, attachmentId: number) => void;
}

const SkuCard: React.FC<SkuCardProps> = ({
  skuDetails,
  handleFileUpload,
  updateLeadTime,
  deleteFile,
}) => {
  const [fileList, setFileList] = useState<File[]>([]);
  const [displayNames, setDisplayNames] = useState<string[]>([]);
  const [lead_time, setLeadTime] = useState<number>(skuDetails.lead_time);

  const handleLeadTimeChange = async (
    event: React.KeyboardEvent<HTMLInputElement>,
    tpmId: number,
    leadTime: number,
  ) => {
    if (event.key === "Enter") {
      try {
        const { data } = await ApiService().client.put(
          URL.OMS.UPDATE_SKU_LIST(tpmId),
          { lead_time: leadTime },
        );

        updateLeadTime(tpmId, data.data.lead_time);
      } catch (error) {
        toast.error("Failed Updating Lead Time", toastOptions);
      }
    }
  };

  return (
    <div className="sku-card-container">
      <div className="sku-card-upper-side flex-row full-width justify-content-space-between">
        <div className="px-10 py-12 ">
          <p className="sku-product-type xetgo-font-tag-bolder py-5 px-8">
            {skuDetails.product_name}
          </p>
        </div>
        <div style={{ width: "30%" }} className="px-10 py-12 flex-column gap-4">
          <p className="sku-card-heading xetgo-font-tag bold">
            {" "}
            ₹ {skuDetails.price_per_unit}
          </p>
          <p className="sku-card-subheading xetgo-font-tag"> Price Per Unit</p>
        </div>
        <div style={{ width: "30%" }} className="px-10 py-12 flex-column gap-4">
          <p className="sku-card-heading  xetgo-font-tag bold ">
            {" "}
            {skuDetails.quantity} {skuDetails.unit}
          </p>
          <p className="sku-card-subheading  xetgo-font-tag"> Quantity</p>
        </div>
      </div>
      <div className="px-12">
        <div className="sku-description xetgo-font-tag p-12">
          {skuDetails.description}
        </div>
      </div>
      <div className="flex-row gap-16 xetgo-font-tag bold p-12">
        <div className="flex-column gap-8 sku-attachment-container">
          <p>Attachments</p>
          <div style={{ position: "relative" }} className="">
            <input
              placeholder="File Upload"
              disabled
              className="sku-attachment-input py-8 px-12 border-box"
              value={displayNames.length > 0 ? displayNames[0] : ""}
            />
            <input
              onChange={(event) => {
                handleFileUpload(event, skuDetails.tpm_id);
              }}
              id={`fileInput${skuDetails.tpm_id}`}
              name="file"
              type="File"
              style={{ display: "none" }}
              multiple
              className="sku-attachment-input py-8 px-12 border-box"
            />

            <label
              htmlFor={`fileInput${skuDetails.tpm_id}`}
              className="cursor-pointer sku-file-input-label sku-attachment-input py-8 px-12 border-box"
            >
              <img
                className="label-attachment-icon"
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240308093408-upload.svg"
                alt="upload-icon"
              />
            </label>
          </div>
        </div>
        <div className=" sku-attachment-container flex-column gap-8">
          <p>Lead Time</p>
          <div style={{ position: "relative" }} className="">
            <input
              type="number"
              onKeyPress={(e) =>
                handleLeadTimeChange(e, skuDetails.tpm_id, lead_time)
              }
              onChange={(e) => setLeadTime(Number(e.target.value))}
              value={lead_time || ""}
              className="sku-attachment-input py-8 px-12 border-box"
            />
            <p className="label-attachment-icon"> | Days</p>
          </div>
        </div>
      </div>

      {skuDetails.attachments?.length > 0 && (
        <div className=" flex-row gap-4 sku-attachment-main-container p-12">
          {skuDetails.attachments.map((attachment, index) => (
            <div
              key={index}
              className="attachment-tag flex-row align-items-center gap-10 xetgo-font-tag px-10 py-4 border-box"
            >
              <a
                style={{ color: "#5151EC", textDecoration: "none" }}
                href={attachment.url}
                target="blank"
              >
                <p>{attachment.name}</p>
              </a>

              <img
                onClick={() => deleteFile(skuDetails.tpm_id, attachment.id)}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240229103344-x+%282%29.svg"
                alt="remove-icon"
                className="cursor-pointer"
              />
            </div>
          ))}
        </div>
      )}
      <ToastContainer position="top-right" />
    </div>
  );
};

export default SkuCard;
