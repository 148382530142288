import {
  AddProductSchema,
  ClientPOC,
  FilterOptionSchema,
  FilterPayload,
  OmsActionTypes,
  OmsActions,
  POCSchema,
  SamplingPayload,
  SelectedFilterPayload,
  UpdateTaskDetailResponseSchema,
  VENDOR_DETAIL_PAYLOAD,
  UpdateCoaPayloadSchema,
  AddRFQProduct,
  UpdatePurchaseOrderDetailResponseSchema,
  UpdatePurchaseOrderProductSchema,
  UpdateDeliveryScheduleSchema,
} from "./omsTypes";
import {
  DeliverySchedule,
  ProductVendorDetails,
  PurchaseOrderDetail,
  TaskCard,
  TaskCommentSchema,
  TaskDetail,
  TaskFileSchema,
} from "../../models/Task";

export interface OMSState {
  search: string;
  assignees: FilterOptionSchema[];
  clients: FilterOptionSchema[];
  vendors: FilterOptionSchema[];
  client_type: FilterOptionSchema[];
  products: FilterOptionSchema[];
  status_allowed: { [key: string]: string[] };
  selected: {
    assignees: FilterOptionSchema[];
    clients: FilterOptionSchema[];
    vendors: FilterOptionSchema[];
    client_type: FilterOptionSchema[];
  };
  loading: boolean;
  error: string | null | undefined;
  client_addresses: FilterOptionSchema[];
  client_pocs: ClientPOC[];
  tasks: TaskCard[];
  statuses: string[];
  detail: TaskDetail | null;
  po_detail: PurchaseOrderDetail | null;
  detailsLoading: boolean;
}

const initialState: OMSState = {
  search: "",
  assignees: [],
  clients: [],
  vendors: [],
  client_type: [],
  products: [],
  status_allowed: {},
  selected: {
    assignees: [],
    clients: [],
    vendors: [],
    client_type: [],
  },
  loading: false,
  error: null,
  tasks: [],
  statuses: [],
  detail: null,
  client_addresses: [],
  client_pocs: [],
  po_detail: null,
  detailsLoading: false,
};

const omsReducer = (state = initialState, action: OmsActionTypes): OMSState => {
  switch (action.type) {
    case OmsActions.FETCH_FILTER_LIST:
      return {
        ...state,
        loading: true,
      };

    case OmsActions.FETCH_FILTER_LIST_SUCCESS: {
      const payload = action.payload as FilterPayload;
      return {
        ...state,
        assignees: payload?.assignees || [],
        clients: payload?.clients || [],
        vendors: payload?.vendors || [],
        client_type: payload?.client_type || [],
        products: payload?.products || [],
        status_allowed: payload?.status_allowed || [],
        loading: false,
        error: null,
      };
    }

    case OmsActions.FETCH_FILTER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case OmsActions.SET_FILTER: {
      const payload = action.payload as SelectedFilterPayload;
      return {
        ...state,
        selected: {
          ...state.selected,
          [payload.category]: payload.options,
        },
        loading: false,
        error: null,
      };
    }
    case OmsActions.SET_SEARCH: {
      const payload = action.payload as string;
      return {
        ...state,
        search: payload,
        loading: false,
        error: null,
      };
    }
    case OmsActions.NEW_USER_START:
      return {
        ...state,
        loading: true,
      };
    case OmsActions.NEW_USER_SUCCESS: {
      const payload = action.payload as FilterOptionSchema;
      const key = payload.role === "CLIENT" ? "clients" : "vendors";
      return {
        ...state,
        [key]: [...state[key], payload],
        loading: false,
      };
    }
    case OmsActions.NEW_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case OmsActions.NEW_COMPANY_START:
      return {
        ...state,
        loading: true,
      };
    case OmsActions.NEW_COMPANY_SUCCESS: {
      const payload = action.payload as {
        company: FilterOptionSchema;
        company_pocs: ClientPOC[];
      };
      const key = payload.company.role === "CLIENT" ? "clients" : "vendors";
      return {
        ...state,
        [key]: [...state[key], payload.company],
        client_pocs:
          key === "clients"
            ? [...state["client_pocs"], ...payload.company_pocs]
            : [...state["client_pocs"]],
        loading: false,
      };
    }
    case OmsActions.NEW_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case OmsActions.NEW_COMPANY_ADDRESS_START:
      return {
        ...state,
        loading: true,
      };
    case OmsActions.NEW_COMPANY_ADDRESS_SUCCESS: {
      const payload = action.payload as FilterOptionSchema;
      return {
        ...state,
        client_addresses: [...state.client_addresses, payload],
        loading: false,
      };
    }
    case OmsActions.NEW_COMPANY_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case OmsActions.REMOVE_COMPANY_ADDRESS:
      return {
        ...state,
        client_addresses: [],
        loading: false,
      };
    case OmsActions.FETCH_COMPANY_ADDRESS_START:
      return {
        ...state,
        loading: true,
      };
    case OmsActions.FETCH_COMPANY_ADDRESS_SUCCESS: {
      const payload = action.payload as FilterOptionSchema[];
      return {
        ...state,
        client_addresses: payload,
        loading: false,
      };
    }
    case OmsActions.FETCH_COMPANY_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case OmsActions.FETCH_COMPANY_POC_START:
      return {
        ...state,
        loading: true,
      };
    case OmsActions.FETCH_COMPANY_POC_SUCCESS: {
      const payload = action.payload as ClientPOC[];
      return {
        ...state,
        client_pocs: [...state.client_pocs, ...payload],
        loading: false,
      };
    }
    case OmsActions.FETCH_COMPANY_POC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case OmsActions.FETCH_TASKS:
      return {
        ...state,
        loading: true,
      };
    case OmsActions.FETCH_TASKS_SUCCESS: {
      const payload = action.payload as {
        tasks: TaskCard[];
        statuses: string[];
      };
      return {
        ...state,
        loading: false,
        tasks: payload.tasks,
        statuses: payload.statuses,
        error: null,
      };
    }
    case OmsActions.FETCH_TASKS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case OmsActions.FETCH_TASK_DETAIL:
      return {
        ...state,
        loading: true,
        tasks: [],
        detailsLoading: true,
      };
    case OmsActions.FETCH_TASK_DETAIL_SUCCESS: {
      const payload = action.payload as TaskDetail;
      return {
        ...state,
        loading: false,
        detail: payload,
        error: null,
        detailsLoading: false,
      };
    }
    case OmsActions.FETCH_TASK_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        detailsLoading: false,
      };
    case OmsActions.UPDATE_TASK_DETAIL_START:
      return {
        ...state,
        loading: true,
      };

    case OmsActions.UPDATE_TASK_DETAIL_SUCCESS: {
      const payload = action.payload as UpdateTaskDetailResponseSchema;
      const detail = state.detail;
      const rfqList = state.tasks.map((rfqCard) => {
        if (rfqCard.category_id === payload.id) {
          return { ...rfqCard, ...payload };
        }
        return rfqCard;
      });
      return {
        ...state,
        tasks: [...rfqList],
        loading: false,
        detail: detail ? { ...detail, ...payload } : null,
        error: null,
      };
    }

    case OmsActions.UPDATE_TASK_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case OmsActions.UPDATE_TASK_ATTACHMENTS_START:
      return {
        ...state,
        loading: true,
      };

    case OmsActions.UPDATE_TASK_ATTACHMENTS_SUCCESS: {
      const payload = action.payload as TaskFileSchema[];
      const detail = state.detail;
      const updatedAssets = detail?.assets
        ? [...detail.assets, ...payload]
        : [...payload];
      return {
        ...state,
        loading: false,
        detail: detail ? { ...detail, assets: updatedAssets } : null,
        error: null,
      };
    }

    case OmsActions.UPDATE_TASK_ATTACHMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case OmsActions.DELETE_TASK_ATTACHMENT_START:
      return {
        ...state,
        loading: true,
      };

    case OmsActions.DELETE_TASK_ATTACHMENT_SUCCESS: {
      const payload = action.payload as { asset_record_id: number };
      const detail = state.detail;
      const updatedAssets = detail?.assets
        ? detail.assets.filter((asset) => asset.id !== payload.asset_record_id)
        : [];
      return {
        ...state,
        loading: false,
        detail: detail ? { ...detail, assets: updatedAssets } : null,
        error: null,
      };
    }

    case OmsActions.DELETE_TASK_ATTACHMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case OmsActions.CREATE_TASK:
      return {
        ...state,
        loading: true,
      };

    case OmsActions.CREATE_TASK_SUCCESS: {
      const payload = action.payload as TaskCard;
      return {
        ...state,
        tasks: [...state.tasks, payload],
      };
    }

    case OmsActions.CREATE_TASK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case OmsActions.UPDATE_TASK_POC:
      return {
        ...state,
        loading: true,
      };

    case OmsActions.UPDATE_TASK_POC_SUCCESS: {
      const payload = action.payload as POCSchema[];
      return {
        ...state,
        detail: state.detail ? { ...state.detail, pocs: payload } : null,
      };
    }

    case OmsActions.UPDATE_TASK_POC_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case OmsActions.ADD_VENDOR_DETAIL:
      return {
        ...state,
        loading: true,
      };

    case OmsActions.ADD_VENDOR_DETAIL_SUCCESS: {
      const payload = action.payload as ProductVendorDetails;

      if (state.detail) {
        const updatedProductDetails = state.detail.product_details.map(
          (productDetail) => {
            if (productDetail.products) {
              const updatedProducts = productDetail.products.map((product) => {
                if (product.tpm_id === payload.tpm_id) {
                  return {
                    ...product,
                    vendor_details: product.vendor_details
                      ? [...product.vendor_details, payload]
                      : [payload],
                  };
                } else {
                  return product;
                }
              });

              return {
                ...productDetail,
                products: updatedProducts,
              };
            }

            return productDetail;
          },
        );

        return {
          ...state,
          detail: { ...state.detail, product_details: updatedProductDetails },
        };
      }
      return {
        ...state,
      };
    }

    case OmsActions.ADD_VENDOR_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case OmsActions.UPDATE_VENDOR_DETAIL:
      return {
        ...state,
        loading: true,
      };

    case OmsActions.UPDATE_VENDOR_DETAIL_SUCCESS: {
      const payload = action.payload as VENDOR_DETAIL_PAYLOAD;
      const { tpm_id, vendor_id, ...rest } = payload;
      if (state.detail) {
        const updatedProductDetails = state.detail.product_details.map(
          (productDetail) => {
            if (productDetail.products) {
              const updatedProducts = productDetail.products.map((product) => {
                if (product.tpm_id === tpm_id) {
                  if (payload.description) {
                    product.description = payload.description;
                  }
                  if (payload.quantity) {
                    product.quantity = payload.quantity;
                  }
                  if (payload.margin) {
                    product.margin = payload.margin;
                  }
                  if (payload.vendor_target_price) {
                    product.vendor_target_price = payload.vendor_target_price;
                  }
                  if (payload.client_target_price) {
                    product.client_target_price = payload.client_target_price;
                  }
                  const vendor_details = [...(product.vendor_details || [])];
                  vendor_details.map((vendor_detail) => {
                    if (vendor_detail.vendor_id === payload.vendor_id) {
                      if (payload.price) {
                        vendor_detail.price = payload.price;
                      }

                      if (payload.approved != null) {
                        vendor_detail.approved = payload.approved;
                      }
                    }
                    if (
                      payload.approved === true &&
                      payload.vendor_id !== vendor_detail.vendor_id
                    ) {
                      vendor_detail.approved = false;
                    }

                    return vendor_detail;
                  });
                }
                return {
                  ...product,
                };
              });

              return {
                ...productDetail,
                products: updatedProducts,
              };
            }
            return productDetail;
          },
        );

        return {
          ...state,
          detail: { ...state.detail, product_details: updatedProductDetails },
        };
      }

      return {
        ...state,
        detail: state.detail,
      };
    }

    case OmsActions.UPDATE_VENDOR_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case OmsActions.DELETE_VENDOR_DETAIL:
      return {
        ...state,
        loading: true,
      };

    case OmsActions.DELETE_VENDOR_DETAIL_SUCCESS: {
      const payload = action.payload as { tpm_id: number; vendor_id: number };

      if (state.detail) {
        const updatedProductDetails = state.detail.product_details.map(
          (productDetail) => {
            if (productDetail.products) {
              const updatedProducts = productDetail.products.map((product) => {
                if (product.tpm_id === payload.tpm_id) {
                  return {
                    ...product,
                    vendor_details: product.vendor_details?.filter(
                      (vd) => vd.vendor_id != payload.vendor_id,
                    ),
                  };
                } else {
                  return product;
                }
              });

              return {
                ...productDetail,
                products: updatedProducts,
              };
            }

            return productDetail;
          },
        );

        return {
          ...state,
          detail: { ...state.detail, product_details: updatedProductDetails },
        };
      }

      return {
        ...state,
        detail: state.detail,
      };
    }

    case OmsActions.DELETE_VENDOR_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case OmsActions.ADD_TASK_PRODUCT_START:
      return {
        ...state,
        loading: true,
      };

    case OmsActions.ADD_TASK_PRODUCT_SUCCESS: {
      const payload = action.payload as AddRFQProduct;
      const detail = state.detail;
      if (detail) {
        const address = detail?.product_details.map((val) => {
          const newProducts = payload[val.address_id];
          if (newProducts) {
            const productList = [...val.products, ...newProducts];
            return { ...val, products: productList };
          }
          return val;
        });
        return {
          ...state,
          detail: { ...detail, product_details: address },
          loading: false,
        };
      }
      return { ...state, loading: true };
    }

    case OmsActions.ADD_TASK_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case OmsActions.ADD_TASK_COMMENT:
      return {
        ...state,
        loading: true,
      };

    case OmsActions.ADD_TASK_COMMENT_SUCCESS: {
      const payload = action.payload as TaskCommentSchema;
      const detail = state.detail ? { ...state.detail } : null;
      if (detail?.comments && payload) {
        detail.comments = [...detail.comments, payload];
      }
      return {
        ...state,
        detail: detail,
        loading: true,
      };
    }

    case OmsActions.ADD_TASK_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case OmsActions.SAMPLE_ADDRESS_START:
      return {
        ...state,
        loading: true,
      };
    case OmsActions.SAMPLE_ADDRESS_SUCCESS: {
      const payload = action.payload as SamplingPayload;
      const detail = state.detail;
      if (detail) {
        detail.product_details.map((product_detail, index) => {
          product_detail.products.map((productDetail) => {
            productDetail.sample_details = payload[productDetail.tpm_id];
          });
        });
      }
      return {
        ...state,
        detail,
        loading: false,
      };
    }

    case OmsActions.SAMPLE_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case OmsActions.UPDATE_COA:
      return {
        ...state,
        loading: true,
      };

    case OmsActions.UPDATE_COA_SUCCESS: {
      const payload = action.payload as UpdateCoaPayloadSchema;
      const { tpm_id } = payload;
      if (state.detail) {
        const updatedProductDetails = state.detail.product_details.map(
          (productDetail) => {
            if (productDetail.products) {
              const updatedProducts = productDetail.products.map((product) => {
                if (product.tpm_id === tpm_id) {
                  return {
                    ...product,
                    coa_details: payload,
                  };
                }

                return {
                  ...product,
                };
              });

              return {
                ...productDetail,
                products: updatedProducts,
              };
            }
            return productDetail;
          },
        );

        return {
          ...state,
          detail: { ...state.detail, product_details: updatedProductDetails },
        };
      }

      return {
        ...state,
        detail: state.detail,
      };
    }

    case OmsActions.UPDATE_COA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case OmsActions.DELETE_TASK_PRODUCT:
      return {
        ...state,
        loading: true,
      };
    case OmsActions.DELETE_TASK_PRODUCT_SUCCESS: {
      const payload = action.payload as { tpm_id: number };
      if (state.detail) {
        const updatedProductDetails = state.detail.product_details.map(
          (productDetail) => {
            if (productDetail.products) {
              const updatedProducts = productDetail.products.filter(
                (product) => product.tpm_id !== payload.tpm_id,
              );
              return {
                ...productDetail,
                products: updatedProducts,
              };
            }

            return productDetail;
          },
        );

        return {
          ...state,
          detail: { ...state.detail, product_details: updatedProductDetails },
          loading: false,
        };
      }
      return {
        ...state,
        detail: state.detail,
        loading: false,
      };
    }
    case OmsActions.DELETE_TASK_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case OmsActions.PURCHASE_ORDER_DETAIL:
      return {
        ...state,
        loading: true,
        tasks: [],
      };
    case OmsActions.PURCHASE_ORDER_DETAIL_SUCCESS: {
      const payload = action.payload as PurchaseOrderDetail; //schema of purchaseOrderDetails to be made an changed
      return {
        ...state,
        loading: false,
        po_detail: payload,
        error: null,
      };
    }
    case OmsActions.PURCHASE_ORDER_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case OmsActions.FETCH_PURCHASE_REQUESTS:
      return {
        ...state,
        loading: true,
      };
    case OmsActions.FETCH_PURCHASE_REQUESTS_SUCCESS: {
      const payload = action.payload as {
        purchase_requests: TaskCard[];
        statuses: string[];
      };
      return {
        ...state,
        loading: false,
        tasks: payload.purchase_requests,
        statuses: payload.statuses,
        error: null,
      };
    }
    case OmsActions.FETCH_PURCHASE_REQUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case OmsActions.CREATE_PURCHASE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case OmsActions.CREATE_PURCHASE_REQUEST_SUCCESS: {
      const payload = action.payload as TaskCard;
      return {
        ...state,
        tasks: [...state.tasks, payload],
      };
    }

    case OmsActions.CREATE_PURCHASE_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case OmsActions.ADD_PURCHASE_PRODUCT_START:
      return {
        ...state,
        loading: true,
      };

    case OmsActions.ADD_PURCHASE_PRODUCT_SUCCESS: {
      const payload = action.payload as AddProductSchema;
      return {
        ...state,
      };
    }

    case OmsActions.ADD_PURCHASE_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case OmsActions.ADD_PRODUCT_DELIVERY_SCHEDULE_START:
      return {
        ...state,
        loading: true,
      };

    case OmsActions.ADD_PURCHASE_REQUEST_COMMENT:
      return {
        ...state,
        loading: true,
      };

    case OmsActions.ADD_PURCHASE_REQUEST_COMMENT_SUCCESS: {
      const payload = action.payload as TaskCommentSchema;
      const po_detail = state.po_detail ? { ...state.po_detail } : null;
      if (po_detail?.comments && payload) {
        po_detail.comments = [...po_detail.comments, payload];
      }
      return {
        ...state,
        po_detail: po_detail,
        loading: true,
      };
    }

    case OmsActions.ADD_PURCHASE_REQUEST_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case OmsActions.ADD_PRODUCT_DELIVERY_SCHEDULE_SUCCESS: {
      const payload = action.payload as DeliverySchedule;
      const po_details = state.po_detail;
      if (po_details) {
        po_details.product_details.map((productDetail) => {
          if (productDetail.products) {
            productDetail.products.forEach((product) => {
              if (product.prtpm_id === payload.prtpm_id) {
                product.delivery_schedules.push(payload);
              }
            });
          }
          return productDetail;
        });
        return {
          ...state,
          po_detail: po_details,
          loading: false,
        };
      }
      return {
        ...state,
      };
    }
    case OmsActions.ADD_PRODUCT_DELIVERY_SCHEDULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case OmsActions.UPDATE_PURCHASE_ORDER_DETAIL_START:
      return {
        ...state,
        loading: true,
      };

    case OmsActions.UPDATE_PURCHASE_ORDER_DETAIL_SUCCESS: {
      const payload = action.payload as UpdatePurchaseOrderDetailResponseSchema;
      const detail = state.po_detail;
      const rfqList = state.tasks.map((purchaseCard) => {
        if (purchaseCard.category_id === payload.id) {
          return { ...purchaseCard, ...payload };
        }
        return purchaseCard;
      });
      return {
        ...state,
        tasks: [...rfqList],
        loading: false,
        po_detail: detail ? { ...detail, ...payload } : null,
        error: null,
      };
    }

    case OmsActions.UPDATE_PURCHASE_ORDER_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case OmsActions.DELETE_TASK:
      return {
        ...state,
        loading: true,
      };
    case OmsActions.DELETE_TASK_SUCCESS: {
      return {
        ...state,
        detail: null,
        loading: false,
      };
    }
    case OmsActions.DELETE_TASK_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case OmsActions.UPDATE_PURCHASE_ORDER_PRODUCT_START:
      return {
        ...state,
        loading: true,
      };
    case OmsActions.UPDATE_PURCHASE_ORDER_PRODUCT_SUCCESS: {
      const payload = action.payload as UpdatePurchaseOrderProductSchema;
      if (state.po_detail) {
        const updatedProductDetails = state.po_detail.product_details.map(
          (detail) => {
            if (detail.vendor_id === payload.vendor_id) {
              const productList = detail.products.map((product) => {
                if (product.prtpm_id === payload.prtpm_id) {
                  return { ...product, description: payload.description };
                }
                return product;
              });
              return { ...detail, products: productList };
            }
            return detail;
          },
        );

        return {
          ...state,
          po_detail: {
            ...state.po_detail,
            product_details: updatedProductDetails,
          },
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };
    }
    case OmsActions.UPDATE_PURCHASE_ORDER_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case OmsActions.UPDATE_DELIVERY_DETAILS_START:
      return {
        ...state,
        loading: true,
      };
    case OmsActions.UPDATE_DELIVERY_DETAILS_SUCCESS: {
      const payload = action.payload as UpdateDeliveryScheduleSchema;
      if (state.po_detail) {
        const updatedProductDetails = state.po_detail.product_details.map(
          (detail) => {
            if (detail.vendor_id === payload.vendor_id) {
              const productList = detail.products.map((product) => {
                if (product.prtpm_id === payload.prtpm_id) {
                  const schedules = product.delivery_schedules.map((entry) => {
                    if (entry.id === payload.id) {
                      return { ...entry, [payload.key]: payload.value };
                    }
                    return entry;
                  });
                  return { ...product, delivery_schedules: schedules };
                }
                return product;
              });
              return { ...detail, products: productList };
            }
            return detail;
          },
        );

        return {
          ...state,
          po_detail: {
            ...state.po_detail,
            product_details: updatedProductDetails,
          },
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };
    }
    case OmsActions.UPDATE_DELIVERY_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case OmsActions.DELETE_PRODUCT_DELIVERY_SCHEDULE_START:
      return {
        ...state,
        loading: true,
      };
    case OmsActions.DELETE_PRODUCT_DELIVERY_SCHEDULE_SUCCESS: {
      const schedule_id = action.payload as number;
      if (state.po_detail) {
        const updatedProductDetails = state.po_detail.product_details.map(
          (detail) => {
            const productList = detail.products.map((product) => {
              const schedules = product.delivery_schedules.filter(
                (row) => row.id !== schedule_id,
              );
              return { ...product, delivery_schedules: schedules };
            });
            return { ...detail, products: productList };
          },
        );

        return {
          ...state,
          po_detail: {
            ...state.po_detail,
            product_details: updatedProductDetails,
          },
          loading: false,
        };
      }
      return {
        ...state,
        loading: false,
      };
    }
    case OmsActions.DELETE_PRODUCT_DELIVERY_SCHEDULE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case OmsActions.FETCH_PURCHASE_REQUEST_COMMENT: {
      const detail = state.po_detail
        ? { ...state.po_detail, comments: [] }
        : null;
      return {
        ...state,
        po_detail: detail,
        loading: false,
      };
    }

    case OmsActions.FETCH_PURCHASE_REQUEST_COMMENT_SUCCESS: {
      const payload = action.payload as TaskCommentSchema[];
      const detail = state.po_detail
        ? { ...state.po_detail, comments: payload }
        : null;
      return {
        ...state,
        po_detail: detail,
        loading: true,
      };
    }

    case OmsActions.FETCH_PURCHASE_REQUEST_COMMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case OmsActions.DELETE_PURCHASE_REQUEST_VENDOR: {
      return {
        ...state,
        loading: true,
      };
    }
    case OmsActions.DELETE_PURCHASE_REQUEST_VENDOR_SUCCESS: {
      const vendor_id = action.payload;
      const products =
        state.po_detail?.product_details.filter(
          (row) => row.vendor_id !== vendor_id,
        ) || [];
      return {
        ...state,
        po_detail: state.po_detail
          ? { ...state.po_detail, product_details: products }
          : state.po_detail,
        loading: true,
      };
    }
    case OmsActions.DELETE_PURCHASE_REQUEST_VENDOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default omsReducer;
