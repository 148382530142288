import { put, call, takeLatest, all } from "redux-saga/effects";
import { fetchUserSuccess, fetchUserFailure, logOutUser } from "./userActions";
import { User } from "../../models/User";
import { FetchUserAction, UserActions } from "./userTypes";
import URL from "../../urls/Urls";
import ApiService from "../../services/apiService";
import { isNotNullOrUndefined } from "../../utils/Helper";

function* getUserSaga(action: FetchUserAction) {
  try {
    const { data } = yield call(ApiService().client.get, URL.USERS.GET_USER, {
      params: action.payload,
    });
    const user: User = data.user;
    if (isNotNullOrUndefined(user)) {
      yield put(fetchUserSuccess(user));
    } else {
      yield put(logOutUser());
    }
  } catch (error) {
    yield put(fetchUserFailure("Error fetching user data."));
    yield put(logOutUser());
  }
}

function* watchGetUser() {
  yield takeLatest(UserActions.FETCH_USER, getUserSaga);
}

export default function* userSaga() {
  yield all([watchGetUser()]);
}
