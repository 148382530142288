import React, { useEffect } from "react";
import SideNav from "../shared/sidenav/SideNav";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "../oms/Oms.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchPurchaseRequests } from "../../redux/oms/omsActions";
import {
  selectFilteredTasks,
  selectTaskStatuses,
} from "../../redux/oms/omsSelectors";
import Options from "../oms/Options/Options";
import TaskOverview from "../TaskOverview/TaskOverview";

const XetTrack: React.FC = () => {
  const dispatch = useDispatch();
  const statuses = useSelector(selectTaskStatuses);
  const { category } = useParams();
  const filteredTasks = useSelector(selectFilteredTasks);

  useEffect(() => {
    if (category) {
      dispatch(fetchPurchaseRequests(category.toUpperCase()));
    }
  }, [category, dispatch]);

  return (
    <>
      <div className="flex-row">
        <SideNav></SideNav>
        <div className="content-section flex-1 flex-column">
          <Options showStatusTabs={false} />
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240109190634-box+1.svg"
            alt="box-background"
            className="box-background"
          />
          {filteredTasks && (
            <TaskOverview
              statuses={statuses}
              tasks={filteredTasks}
            ></TaskOverview>
          )}
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};

export default XetTrack;
