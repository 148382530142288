export const BADGES = [
  {
    text: "Purchase Order",
    color: "#854D0E",
    backgroundColor: "#FEF9C3",
    value: "PURCHASE_ORDER",
  },
  {
    text: "Sales Order",
    color: "#991B1B",
    backgroundColor: "#FEE2E2",
    value: "SALES_ORDER",
  },
  {
    text: "Vendor Purchase Order",
    color: "#1E40AF",
    backgroundColor: "#DBEAFE",
    value: "VENDOR_PURCHASE_ORDER",
  },
  {
    text: "Artwork",
    color: "#115E59",
    backgroundColor: "#CCFBF1",
    value: "ARTWORK",
  },
];
