import React from "react";
import "./TaskCard.scss";
import { TaskCard as TaskCardSchema } from "../../../models/Task";
import { Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { formatDate } from "../../../utils/date-helper";
import { formatValue } from "../../../utils/Helper";
import { TASK_PRIORITY } from "../../../models/RFQ";
import XetImage from "../../shared/xet-image/XetImage";
import { useNavigate } from "react-router-dom";

interface TaskCardProps {
  category?: string;
  task: TaskCardSchema;
  status: string;
  dragging: number;
  setDragging: (val: number) => void;
}

const TaskCard: React.FC<TaskCardProps> = ({
  category,
  task,
  status,
  dragging,
  setDragging,
}) => {
  const navigate = useNavigate();

  const fetchTaskDetail = (id: number): void => {
    const type = window.location.pathname.split("/")[1];

    if (!showVendorList(category)) {
      navigate(`/${type}/${category}/${id}`);
    }
  };

  const taskDrag = (event: React.DragEvent, id: number): void => {
    event.dataTransfer.setData("id", JSON.stringify({ id, status }));
    setDragging(id);
    event.stopPropagation();
  };

  const fetchSamplingDetails = (id: number, vendor_id: number) => {
    if (category === "sampling") {
      navigate(`/eqs/${category}/${id}/vendor/${vendor_id}`);
    } else {
      navigate(`/xetrack/${category}/${id}/vendor/${vendor_id}`);
    }
  };

  const showVendorList = (category: string | undefined): boolean => {
    if (category != undefined) {
      const list = ["sampling", "delivery"];
      if (list.indexOf(category) > -1) {
        return true;
      }
    }
    return false;
  };

  const strToDate = (str: string) => {
    return dayjs(str.split("-").reverse().join("-"));
  };

  const differenceInDays = (date1: string): number => {
    const date2 = dayjs();
    const hours = date2.diff(strToDate(formatDate(date1)), "hours");
    return Math.floor(hours / 24);
  };

  return (
    <div
      className={`task-card-container flex-column xetgo-font-tag ${
        dragging === task.category_id ? "task-dragging" : ""
      } ${task.status_due && "crossed-status-due"} ${
        !showVendorList(category) && "cursor-pointer"
      } ${task.escalated && "escalated"}`}
      onClick={() => fetchTaskDetail(task.category_id)}
      draggable
      onDragStart={(e) => taskDrag(e, task.category_id)}
    >
      <div className="task-card-upper-section flex-column gap-12">
        <div className="task-card-header flex-row justify-content-space-between">
          <div className="flex-row gap-6 align-items-center">
            {Number(task.deal_value) > 0 && (
              <div className="deal-value-container px-8 py-4 xetgo-font-tag bolder">
                {formatValue(task.deal_value)} <span className="unit">₹</span>
              </div>
            )}
            <img
              className="priority-flag"
              src={
                task.priority === TASK_PRIORITY[0].name
                  ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231025104330-flag.svg"
                  : task.priority === TASK_PRIORITY[1].name
                  ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231018095710-flag.svg"
                  : "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231108100822-flag.svg"
              }
              alt="np"
            />
          </div>
          <div className="flex-row align-items-center gap-2 ">
            <span className="card-start-date xetgo-font-tag-bold">
              {formatDate(task.start_date)}
            </span>
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231025104929-arrow-forward.svg"
              alt=""
            />
            <span className="card-due-date xetgo-font-tag-bolder">
              {formatDate(task.due_date)}
            </span>

            {(task.task_due || task.status_due) && (
              <span
                className={`${task.task_due && "task-due-tag"}
                        card-task-due-tag xetgo-font-mini bold`}
              >
                {task.task_due
                  ? differenceInDays(task.due_date)
                  : differenceInDays(task.status_due_date)}
              </span>
            )}
          </div>
        </div>

        <div className="task-content flex-column gap-8">
          <span className="task-title">{task.title}</span>
        </div>

        {showVendorList(category) && (
          <div className="vendor-list py-4 px-8">
            {task.vendors.map((vendor) => {
              return (
                <div
                  key={vendor.po_number}
                  className="flex-row justify-content-space-between xetgo-font-tag-bold px-8 py-4 vendor-name"
                  onClick={() =>
                    fetchSamplingDetails(task.category_id, vendor.id)
                  }
                >
                  <p> {vendor.name} </p>
                  <p> {vendor.po_number} </p>
                </div>
              );
            })}
          </div>
        )}

        <div className="task-users-associated flex-row justify-content-space-between align-items-center">
          <span className="task-client flex-row align-items-center gap-8">
            <Tooltip title={task.client.client_name}>
              <span className="client-user-circle">
                <XetImage
                  className="client-image"
                  src={task.client.client_avatar}
                  alt={task.client.client_name}
                />
              </span>
            </Tooltip>
            <span
              className={`client-type-pill ${
                task.client.client_type == "NEW" ? "new" : "existing"
              }`}
            >
              {task.client.client_type.toLowerCase()}
            </span>
          </span>
          <p className="rfq-uuid xetgo-font-tag">
            {task.po_number ? task.po_number : `XGPQ${90000 + task.task_id}`}
          </p>
        </div>
      </div>
      <div
        className={`${task.task_due && "task-due"} ${
          task.status_due && "status-due"
        }
                                sales-operation-section
                                flex-row align-items-center justify-content-space-between`}
      >
        <span className="sales-operation-heading">Sales & Operations :</span>

        <span className="assignees flex-row align-items-center">
          {task.assignees.map((assignee, index) => {
            return (
              <Tooltip key={index} title={assignee.name}>
                <div className="assignee-user-circle" key={index}>
                  <XetImage
                    className="assignee-image"
                    src={assignee.avatar}
                    alt="ap"
                  />
                </div>
              </Tooltip>
            );
          })}
        </span>
      </div>
    </div>
  );
};

export default TaskCard;
