import React, { useEffect, useState } from "react";
import "./XetNotificationPreference.scss";
import {
  NOTIFICATION_PREFERENCES_FORM,
  NotificationPreferences,
} from "../../../models/NotificationSchema";
import ApiService from "../../../services/apiService";
import URL from "../../../urls/Urls";
import XetToggle from "../xet-toggle/XetToggle";

interface XetNotificationPreferenceProps {
  openPreferenceSetting: boolean;
  notificationPreferences: NotificationPreferences | undefined;
}

const XetNotificationPreference: React.FC<XetNotificationPreferenceProps> = ({
  openPreferenceSetting,
  notificationPreferences,
}) => {
  const [notificationPreferenceForm, setNotificationPreferenceForm] = useState(
    NOTIFICATION_PREFERENCES_FORM,
  );
  const [shouldUpdateForm, setShouldUpdateForm] = useState(false);

  useEffect(() => {
    if (openPreferenceSetting) {
      setShouldUpdateForm(true);
    }
  }, [openPreferenceSetting]);

  useEffect(() => {
    if (notificationPreferences) {
      setNotificationPreferenceForm(notificationPreferences);
    }
  }, [notificationPreferences]);

  useEffect(() => {
    if (shouldUpdateForm) {
      updateNotificationPreferences().then((r) => {
        return r;
      });
    }
  }, [notificationPreferenceForm]);

  const updateNotificationPreferences = async () => {
    await ApiService().client.put(URL.USERS.UPDATE_NOTIFICATION_PREFERENCE, {
      notification_preference: JSON.stringify(notificationPreferenceForm),
    });
  };

  const handlePreferenceToggle = (preference: string, value: boolean) => {
    if (
      notificationPreferenceForm[
        preference as keyof typeof notificationPreferenceForm
      ] !== value
    ) {
      setNotificationPreferenceForm((prevForm) => ({
        ...prevForm,
        [preference]: value,
      }));
    }
  };

  return (
    <>
      {openPreferenceSetting && (
        <div className="preference-dialog flex-column">
          <span className="preference-header p-12">Preferences</span>
          <span className="preference-body flex-column p-12">
            <span className="preference-option flex-row justify-content-space-between align-items-center">
              Tags
              <XetToggle
                toggleValue={
                  notificationPreferenceForm.tag_notifications_enabled
                }
                onToggle={(value) =>
                  handlePreferenceToggle("tag_notifications_enabled", value)
                }
              ></XetToggle>
            </span>
          </span>

          <span className="preference-body flex-column p-12">
            <span className="preference-option flex-row justify-content-space-between align-items-center">
              Comment
              <XetToggle
                toggleValue={
                  notificationPreferenceForm.push_notifications_enabled
                }
                onToggle={(value) =>
                  handlePreferenceToggle("push_notifications_enabled", value)
                }
              ></XetToggle>
            </span>
          </span>

          <span className="preference-body flex-column p-12">
            <span className="preference-option flex-row justify-content-space-between align-items-center">
              Status
              <XetToggle
                toggleValue={
                  notificationPreferenceForm.task_status_notifications_enabled
                }
                onToggle={(value) =>
                  handlePreferenceToggle(
                    "task_status_notifications_enabled",
                    value,
                  )
                }
              ></XetToggle>
            </span>
          </span>

          <span className="preference-body flex-column p-12">
            <span className="preference-option flex-row justify-content-space-between align-items-center">
              Notification on Gmail
              <XetToggle
                toggleValue={
                  notificationPreferenceForm.email_notifications_enabled
                }
                onToggle={(value) =>
                  handlePreferenceToggle("email_notifications_enabled", value)
                }
              ></XetToggle>
            </span>
          </span>
        </div>
      )}
    </>
  );
};

export default XetNotificationPreference;
