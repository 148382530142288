import React, { useEffect, useState } from "react";
import "./TaskOverview.scss";
import {
  TaskCard as TaskCardSchema,
  TaskStatusColorSchema,
} from "../../models/Task";
import { useParams } from "react-router-dom";
import { formatValue } from "../../utils/Helper";
import TaskCardsList from "../TaskCardsList/TaskCardsList";

interface TaskOverviewProps {
  statuses: string[];
  tasks: TaskCardSchema[];
}

const TaskOverview: React.FC<TaskOverviewProps> = ({ statuses, tasks }) => {
  const [dealValueMap, setDealValueMap] = useState<{ [key: string]: string }>(
    {},
  );
  const { category } = useParams();

  useEffect(() => {
    const dealMap: { [key: string]: number } = {};
    const formattedDealMap: { [key: string]: string } = {};
    statuses.forEach((status) => {
      dealMap[status] = 0;
    });
    if (category?.toUpperCase() === "RFQ") {
      tasks.forEach((val) => {
        dealMap[val.status] = dealMap[val.status] + val.deal_value;
      });
      statuses.forEach((status) => {
        formattedDealMap[status] = formatValue(dealMap[status]);
      });
    }
    setDealValueMap(formattedDealMap);
  }, [tasks, statuses, category]);

  return (
    <div className="taskOverview-layout-container flex-1">
      <div className="taskOverview-lower-container flex-row gap-24">
        {statuses.map((status: string, sIndex) => (
          <div key={sIndex} className="flex-column full-height gap-16">
            <div className="task-name-container flex-row justify-content-space-between align-items-center py-6 px-8">
              <div className="flex-row gap-12 align-items-center">
                <p
                  className="task-status"
                  style={{
                    color: TaskStatusColorSchema[status]["color"],
                    background: TaskStatusColorSchema[status]["background"],
                  }}
                >
                  {status}
                </p>
                {dealValueMap[status] > "0" && (
                  <p className="total-deal-value px-8 py-4 xetgo-font-tag bolder">
                    <span className="unit">₹</span>
                    {dealValueMap[status]}
                  </p>
                )}
              </div>
              <p className="left-task">
                {tasks.filter((task) => task.status === status).length}
              </p>
            </div>
            <TaskCardsList
              tasks={tasks.filter((task) => task.status === status)}
              status={status}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskOverview;
