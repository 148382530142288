import React, { useState } from "react";
import "./RFQAddressCard.scss";
import XetModal from "../shared/xet-modal/XetModal";
import AddProductDialog from "../AddProductDialog/AddProductDialog";
import RFQProductCard from "../RFQProductCard/RFQProductCard";
import SamplingProductCard from "../SamplingProductCard/SamplingProductCard";
import { TaskProductDetails } from "../../models/Task";

interface AddressCardProps {
  address: TaskProductDetails;
  taskId: number;
  clientId: number;
  status: string;
}

const RFQAddressCard: React.FC<AddressCardProps> = ({
  address,
  taskId,
  clientId,
  status,
}) => {
  const [openAddProduct, setOpenAddProduct] = useState(false);

  const handleAddProduct = () => {
    setOpenAddProduct(true);
  };

  const handleProductClose = () => {
    setOpenAddProduct(false);
  };

  return (
    <>
      {status === "Samples" ? (
        <SamplingProductCard products={address.products} address={address} />
      ) : (
        <>
          <div className="product-details-container flex-row justify-content-space-between">
            <div>
              <p className="xetgo-font-tag-bolder" style={{ color: "#000000" }}>
                {address.address_city}
                <span
                  className="xetgo-font-tag-bold"
                  style={{ color: "#484848", marginLeft: "8px" }}
                >
                  {address.address_line}, {address.address_state},{" "}
                  {address.address_country}, {address.address_pincode}
                </span>
              </p>
            </div>
            <span
              className="flex-row xetgo-font-tag-bold cursor-pointer gap-2"
              style={{
                color: "#5151EC",
                width: "fit-content",
              }}
              onClick={handleAddProduct}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                alt="plus"
                width={16}
                height={16}
              />
              Add product
            </span>
          </div>

          <div className="product-name-list  flex-column gap-12">
            {address.products.map((product, index: number) => {
              return (
                <RFQProductCard
                  product={product}
                  key={`${product.tpm_id} - ${index}`}
                />
              );
            })}
          </div>
          {
            <XetModal
              backdropClose={false}
              open={openAddProduct}
              handleClose={handleProductClose}
            >
              <AddProductDialog
                handleClose={handleProductClose}
                taskId={taskId}
                clientId={clientId}
              />
            </XetModal>
          }
        </>
      )}
    </>
  );
};

export default RFQAddressCard;
