import { IOmsStatusTab } from "../../models/Oms";
import { RfqCard } from "../../models/RFQ";
import {
  DeliverySchedule,
  ProductVendorDetails,
  PurchaseOrderDetail,
  TaskCard,
  TaskCommentSchema,
  TaskDetail,
  TaskFileSchema,
} from "../../models/Task";
import { Task } from "../../services/task.service";

export const OmsActions = {
  FETCH_FILTER_LIST: "FETCH_FILTER_LIST",
  FETCH_FILTER_LIST_SUCCESS: "FETCH_FILTER_LIST_SUCCESS",
  FETCH_FILTER_LIST_FAILURE: "FETCH_FILTER_LIST_FAILURE",
  FETCH_TASKS: "FETCH_TASKS",
  FETCH_TASKS_SUCCESS: "FETCH_TASKS_SUCCESS",
  FETCH_TASKS_FAILURE: "FETCH_TASKS_FAILURE",
  SET_FILTER: "SET_FILTER",
  SET_SEARCH: "SET_SEARCH",
  NEW_USER_START: "NEW_USER_START",
  NEW_USER_SUCCESS: "NEW_USER_SUCCESS",
  NEW_USER_FAILURE: "NEW_USER_FAILURE",
  NEW_COMPANY_START: "NEW_COMPANY_START",
  NEW_COMPANY_SUCCESS: "NEW_COMPANY_SUCCESS",
  NEW_COMPANY_FAILURE: "NEW_COMPANY_FAILURE",
  NEW_COMPANY_ADDRESS_START: "NEW_COMPANY_ADDRESS_START",
  NEW_COMPANY_ADDRESS_SUCCESS: "NEW_COMPANY_ADDRESS_SUCCESS",
  NEW_COMPANY_ADDRESS_FAILURE: "NEW_COMPANY_ADDRESS_FAILURE",
  FETCH_COMPANY_ADDRESS_START: "FETCH_COMPANY_ADDRESS_START",
  FETCH_COMPANY_ADDRESS_SUCCESS: "FETCH_COMPANY_ADDRESS_SUCCESS",
  FETCH_COMPANY_ADDRESS_FAILURE: "FETCH_COMPANY_ADDRESS_FAILURE",
  REMOVE_COMPANY_ADDRESS: "REMOVE_COMPANY_ADDRESS",
  FETCH_COMPANY_POC_START: "FETCH_COMPANY_POC_START",
  FETCH_COMPANY_POC_SUCCESS: "FETCH_COMPANY_POC_SUCCESS",
  FETCH_COMPANY_POC_FAILURE: "FETCH_COMPANY_POC_FAILURE",
  FETCH_TASK_DETAIL: "FETCH_TASK_DETAIL",
  FETCH_TASK_DETAIL_SUCCESS: "FETCH_TASK_DETAIL_SUCCESS",
  FETCH_TASK_DETAIL_FAILURE: "FETCH_TASK_DETAIL_FAILURE",
  UPDATE_TASK_DETAIL_START: "UPDATE_TASK_DETAIL_START",
  UPDATE_TASK_DETAIL_SUCCESS: "UPDATE_TASK_DETAIL_SUCCESS",
  UPDATE_TASK_DETAIL_FAILURE: "UPDATE_TASK_DETAIL_FAILURE",
  UPDATE_TASK_ATTACHMENTS_START: "UPDATE_TASK_ATTACHMENTS_START",
  UPDATE_TASK_ATTACHMENTS_SUCCESS: "UPDATE_TASK_ATTACHMENTS_SUCCESS",
  UPDATE_TASK_ATTACHMENTS_FAILURE: "UPDATE_TASK_ATTACHMENTS_FAILURE",
  DELETE_TASK_ATTACHMENT_START: "DELETE_TASK_ATTACHMENT_START",
  DELETE_TASK_ATTACHMENT_SUCCESS: "DELETE_TASK_ATTACHMENT_SUCCESS",
  DELETE_TASK_ATTACHMENT_FAILURE: "DELETE_TASK_ATTACHMENT_FAILURE",
  UPDATE_TASK_START: "UPDATE_TASK_ASSIGNEE_START",
  UPDATE_TASK_SUCCESS: "UPDATE_TASK_ASSIGNEE_SUCCESS",
  UPDATE_TASK_FAILURE: "UPDATE_TASK_ASSIGNEE_FAILURE",
  CREATE_TASK: "CREATE_TASK",
  CREATE_TASK_SUCCESS: "CREATE_TASK_SUCCESS",
  CREATE_TASK_FAILURE: "CREATE_TASK_FAILURE",
  ADD_TASK_PRODUCT_START: "ADD_TASK_PRODUCT_START",
  ADD_TASK_PRODUCT_SUCCESS: "ADD_TASK_PRODUCT_SUCCESS",
  ADD_TASK_PRODUCT_FAILURE: "ADD_TASK_PRODUCT_FAILURE",
  UPDATE_TASK_POC: "UPDATE_TASK_POC",
  UPDATE_TASK_POC_SUCCESS: "UPDATE_TASK_POC_SUCCESS",
  UPDATE_TASK_POC_FAILURE: "UPDATE_TASK_POC_FAILURE",
  UPDATE_VENDOR_DETAIL: "UPDATE_VENDOR_DETAIL",
  UPDATE_VENDOR_DETAIL_SUCCESS: "UPDATE_VENDOR_DETAIL_SUCCESS",
  UPDATE_VENDOR_DETAIL_FAILURE: "UPDATE_VENDOR_DETAIL_FAILURE",
  ADD_VENDOR_DETAIL: "ADD_VENDOR_DETAIL",
  ADD_VENDOR_DETAIL_SUCCESS: "ADD_VENDOR_DETAIL_SUCCESS",
  ADD_VENDOR_DETAIL_FAILURE: "ADD_VENDOR_DETAIL_FAILURE",
  DELETE_VENDOR_DETAIL: "DELETE_VENDOR_DETAIL",
  DELETE_VENDOR_DETAIL_SUCCESS: "DELETE_VENDOR_DETAIL_SUCCESS",
  DELETE_VENDOR_DETAIL_FAILURE: "DELETE_VENDOR_DETAIL_FAILURE",
  ADD_TASK_COMMENT: "ADD_TASK_COMMENT",
  ADD_TASK_COMMENT_SUCCESS: "ADD_TASK_COMMENT_SUCCESS",
  ADD_TASK_COMMENT_FAILURE: "ADD_TASK_COMMENT_FAILURE",
  SAMPLE_ADDRESS_START: "SAMPLE_ADDRESS_START",
  SAMPLE_ADDRESS_SUCCESS: "SAMPLE_ADDRESS_SUCCESS",
  SAMPLE_ADDRESS_FAILURE: "SAMPLE_ADDRESS_FAILURE",
  UPDATE_COA: "UPDATE_COA",
  UPDATE_COA_SUCCESS: "UPDATE_COA_SUCCESS",
  UPDATE_COA_FAILURE: "UPDATE_COA_FAILURE",
  DELETE_TASK_PRODUCT: "DELETE_TASK_PRODUCT",
  DELETE_TASK_PRODUCT_SUCCESS: "DELETE_TASK_PRODUCT_SUCCESS",
  DELETE_TASK_PRODUCT_FAILURE: "DELETE_TASK_PRODUCT_FAILURE",
  DELETE_TASK: "DELETE_TASK",
  DELETE_TASK_SUCCESS: "DELETE_TASK_SUCCESS",
  DELETE_TASK_FAILURE: "DELETE_TASK_FAILURE",
  PURCHASE_ORDER_DETAIL: "PURCHASE_ORDER_DETAIL",
  PURCHASE_ORDER_DETAIL_SUCCESS: "PURCHASE_ORDER_DETAIL_SUCCESS",
  PURCHASE_ORDER_DETAIL_FAILURE: "PURCHASE_ORDER_DETAIL_FAILURE",
  FETCH_PURCHASE_REQUESTS: "FETCH_PURCHASE_REQUESTS",
  FETCH_PURCHASE_REQUESTS_SUCCESS: "FETCH_PURCHASE_REQUESTS_SUCCESS",
  FETCH_PURCHASE_REQUESTS_FAILURE: "FETCH_PURCHASE_REQUESTS_FAILURE",
  CREATE_PURCHASE_REQUEST: "CREATE_PURCHASE_REQUEST",
  CREATE_PURCHASE_REQUEST_SUCCESS: "CREATE_PURCHASE_REQUEST_SUCCESS",
  CREATE_PURCHASE_REQUEST_FAILURE: "CREATE_PURCHASE_REQUEST_FAILURE",
  ADD_PURCHASE_PRODUCT_START: "ADD_PURCHASE_PRODUCT_START",
  ADD_PURCHASE_PRODUCT_SUCCESS: "ADD_PURCHASE_PRODUCT_SUCCESS",
  ADD_PURCHASE_PRODUCT_FAILURE: "ADD_PURCHASE_PRODUCT_FAILURE",
  ADD_PRODUCT_DELIVERY_SCHEDULE_START: "ADD_PRODUCT_DELIVERY_SCHEDULE_START",
  ADD_PRODUCT_DELIVERY_SCHEDULE_SUCCESS:
    "ADD_PRODUCT_DELIVERY_SCHEDULE_SUCCESS",
  ADD_PRODUCT_DELIVERY_SCHEDULE_FAILURE:
    "ADD_PRODUCT_DELIVERY_SCHEDULE_FAILURE",
  ADD_PURCHASE_REQUEST_COMMENT: "ADD_PURCHASE_REQUEST_COMMENT",
  ADD_PURCHASE_REQUEST_COMMENT_SUCCESS: "ADD_PURCHASE_REQUEST_COMMENT_SUCCESS",
  ADD_PURCHASE_REQUEST_COMMENT_FAILURE: "ADD_PURCHASE_REQUEST_COMMENT_FAILURE",
  UPDATE_PURCHASE_ORDER_DETAIL_START: "UPDATE_PURCHASE_ORDER_DETAIL_START",
  UPDATE_PURCHASE_ORDER_DETAIL_SUCCESS: "UPDATE_PURCHASE_ORDER_DETAIL_SUCCESS",
  UPDATE_PURCHASE_ORDER_DETAIL_FAILURE: "UPDATE_PURCHASE_ORDER_DETAIL_FAILURE",
  UPDATE_PURCHASE_ORDER_PRODUCT_START: "UPDATE_PURCHASE_ORDER_PRODUCT_START",
  UPDATE_PURCHASE_ORDER_PRODUCT_SUCCESS:
    "UPDATE_PURCHASE_ORDER_PRODUCT_SUCCESS",
  UPDATE_PURCHASE_ORDER_PRODUCT_FAILURE:
    "UPDATE_PURCHASE_ORDER_PRODUCT_FAILURE",
  UPDATE_DELIVERY_DETAILS_START: "UPDATE_DELIVERY_DETAILS_START",
  UPDATE_DELIVERY_DETAILS_SUCCESS: "UPDATE_DELIVERY_DETAILS_SUCCESS",
  UPDATE_DELIVERY_DETAILS_FAILURE: "UPDATE_DELIVERY_DETAILS_FAILURE",
  DELETE_PRODUCT_DELIVERY_SCHEDULE_START:
    "DELETE_PRODUCT_DELIVERY_SCHEDULE_START",
  DELETE_PRODUCT_DELIVERY_SCHEDULE_SUCCESS:
    "DELETE_PRODUCT_DELIVERY_SCHEDULE_SUCCESS",
  DELETE_PRODUCT_DELIVERY_SCHEDULE_FAILURE:
    "DELETE_PRODUCT_DELIVERY_SCHEDULE_FAILURE",
  FETCH_PURCHASE_REQUEST_COMMENT: "FETCH_PURCHASE_REQUEST_COMMENT",
  FETCH_PURCHASE_REQUEST_COMMENT_SUCCESS:
    "FETCH_PURCHASE_REQUEST_COMMENT_SUCCESS",
  FETCH_PURCHASE_REQUEST_COMMENT_FAILURE:
    "FETCH_PURCHASE_REQUEST_COMMENT_FAILURE",
  DELETE_PURCHASE_REQUEST_VENDOR: "DELETE_PURCHASE_REQUEST_VENDOR",
  DELETE_PURCHASE_REQUEST_VENDOR_SUCCESS:
    "DELETE_PURCHASE_REQUEST_VENDOR_SUCCESS",
  DELETE_PURCHASE_REQUEST_VENDOR_FAILURE:
    "DELETE_PURCHASE_REQUEST_VENDOR_FAILURE",
};

export interface FilterOptionSchema {
  name: string;
  id: number;
  role?: string | string[];
  avatar?: string;
  material?: string;
  code?: string;
}

export interface ClientPOC {
  id: number;
  name: string;
  email: string;
  phone_number: string;
  designation: string;
  company_id: number;
}

export interface FilterPayload {
  assignees: FilterOptionSchema[];
  vendors: FilterOptionSchema[];
  clients: FilterOptionSchema[];
  client_type: FilterOptionSchema[];
  products: FilterOptionSchema[];
  status_allowed: { [key: string]: string[] };
}

export interface SelectedFilterPayload {
  options: FilterOptionSchema[];
  category: FilterKeys;
}

export enum FilterKeys {
  ASSIGNEES = "assignees",
  VENDORS = "vendors",
  CLIENTS = "clients",
  CLIENT_TYPE = "client_type",
}

export interface SetFilter {
  type: typeof OmsActions.SET_FILTER;
  payload?: SelectedFilterPayload;
  error?: null;
}

export interface SetSearch {
  type: typeof OmsActions.SET_SEARCH;
  payload: string;
  error?: null;
}

export interface FetchTasksResponse {
  tasks: Task[];
  statuses: string[];
}

export interface CreateTask {
  taskName: string;
  taskDescription: string;
  sourceAssignee?: string;
  operationAssignee?: string;
  salesAssignee: string;
  clientType?: string;
  client?: string;
  vendor?: string;
  fileList?: string;
  dueDate?: string;
}

export interface NewUserPayloadSchema {
  name: string;
  email: string;
  countryCode: string;
  phoneNumber: string;
  role: string;
  domain: string;
}

export interface NewClientPayloadSchema {
  company_name: string;
  domain: string;
  contactDetails: {
    poc_name: string;
    email: string;
    phoneNumber: string;
    designation: string;
  }[];
}

export interface NewCompanyAddressPayloadSchema {
  line: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
  user_id: number;
}

export interface UpdateTaskDetailSchema {
  id: number;
  category: string;
  title?: string;
  description?: string;
  status?: string;
  due_date?: string;
  role?: string;
  assignee_id?: number[];
  priority?: string;
  reason?: string;
  deal_value?: string;
  escalated?: boolean | null;
  escalated_by_user_id?: number | null;
  escalated_id?: number | null;
}

export interface UpdateTaskDetailResponseSchema {
  id: number;
  title: string;
  description: string;
  status: string;
  assignees: {
    id: number;
    name: string;
    avatar: string;
    role: string;
  }[];
  priority: string;
  due_date: string;
}

export interface UpdatePurchaseOrderDetailSchema {
  id: number;
  category: string;
  title?: string;
  description?: string;
  status?: string;
  due_date?: string;
  role?: string;
  assignee_id?: number[];
  priority?: string;
  reason?: string;
  deal_value?: string;
}

export interface UpdatePurchaseOrderDetailResponseSchema {
  id: number;
  title: string;
  description: string;
  status: string;
  assignees: {
    id: number;
    name: string;
    avatar: string;
    role: string;
  }[];
  priority: string;
  due_date: string;
}

export interface UpdatePurchaseOrderProductSchema {
  id: number;
  description: string;
  prtpm_id: number;
  vendor_id: number;
}
export interface AddProductSchema {
  address_id: number;
  products: {
    name: string;
    material: string;
    quantity: string;
    description: string;
    tpm_id: number;
  }[];
}

export interface RFQProduct {
  name: string;
  material: string;
  quantity: string;
  unit: string;
  description: string;
  tpm_id: number;
}

export interface AddRFQProduct {
  [key: number]: RFQProduct[];
}

export interface POCSchema {
  [key: string]: any;
  id?: number;
  name: string;
  email: string;
  phone_number: string;
  designation: string;
}

export interface POCPayload {
  id: number;
  company_id: number;
  existing: POCSchema[];
  new: POCSchema[];
}

export interface VENDOR_DETAIL_PAYLOAD {
  tpm_id: number | undefined;
  vendor_id?: number;
  margin?: number;
  vendor_target_price?: number;
  client_target_price?: number;
  quantity?: string;
  description?: string;
  price?: number;
  approved?: boolean;
  product_accepted?: boolean;
}

export interface SamplingPayload {
  [key: number]: string[];
}

export interface UpdateCoaPayloadSchema {
  id: number;
  name: string;
  url: string;
  tpm_id: number;
}

export interface SampleProduct {
  id: string;
  name: string;
  description: string;
  address_line: string;
  state: string;
  country: string;
  pincode: string;
  tpm_id: number;
  city: string;
}

export interface UpdateDeliveryScheduleSchema {
  key: string;
  value: string;
  id: number;
  prtpm_id: number;
  vendor_id: number;
}
export interface FetchFilterAction {
  type: typeof OmsActions.FETCH_FILTER_LIST;
  payload: {
    category: string;
  };
  error?: null;
}

export interface FetchFilterSuccessAction {
  type: typeof OmsActions.FETCH_FILTER_LIST_SUCCESS;
  payload: FilterPayload;
  error?: null;
}

export interface FetchFilterFailureAction {
  type: typeof OmsActions.FETCH_FILTER_LIST_FAILURE;
  payload?: null;
  error: string;
}

export interface FetchTasksAction {
  type: typeof OmsActions.FETCH_TASKS;
  payload: {
    category: string;
    status: IOmsStatusTab;
  };
  error?: string | null;
}

export interface FetchTasksSuccessAction {
  type: typeof OmsActions.FETCH_TASKS_SUCCESS;
  payload: { tasks: TaskCard[]; statuses: string[] };
  error?: string | null;
}

export interface FetchTasksFailureAction {
  type: typeof OmsActions.FETCH_TASKS_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface SetFilter {
  type: typeof OmsActions.SET_FILTER;
  payload?: SelectedFilterPayload;
  error?: null;
}

export interface NewUserStartAction {
  type: typeof OmsActions.NEW_USER_START;
  payload: NewUserPayloadSchema;
  error?: null;
}

export interface NewUserSucccessAction {
  type: typeof OmsActions.NEW_USER_SUCCESS;
  payload: FilterOptionSchema;
  error?: null;
}

export interface NewUserFailureAction {
  type: typeof OmsActions.NEW_USER_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface NewCompanyStartAction {
  type: typeof OmsActions.NEW_COMPANY_START;
  payload: NewClientPayloadSchema;
  error?: null;
}

export interface NewCompanySuccessAction {
  type: typeof OmsActions.NEW_COMPANY_SUCCESS;
  payload: { company: FilterOptionSchema; company_pocs: ClientPOC[] };
  error?: null;
}

export interface NewCompanyFailureAction {
  type: typeof OmsActions.NEW_COMPANY_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface NewCompanyAddressStartAction {
  type: typeof OmsActions.NEW_COMPANY_ADDRESS_START;
  payload: NewCompanyAddressPayloadSchema;
  error?: null;
}

export interface NewCompanyAddressSuccessAction {
  type: typeof OmsActions.NEW_COMPANY_ADDRESS_SUCCESS;
  payload: FilterOptionSchema;
  error?: null;
}

export interface NewCompanyAddressFailureAction {
  type: typeof OmsActions.NEW_COMPANY_ADDRESS_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface RemoveCompanyAddress {
  type: typeof OmsActions.REMOVE_COMPANY_ADDRESS;
  payload?: null;
  error?: null;
}

export interface FetchCompanyAddressStartAction {
  type: typeof OmsActions.FETCH_COMPANY_ADDRESS_START;
  payload?: number;
  error?: null;
}

export interface FetchCompanyAddressSuccessAction {
  type: typeof OmsActions.FETCH_COMPANY_ADDRESS_SUCCESS;
  payload: FilterOptionSchema[];
  error?: null;
}

export interface FetchCompanyAddressFailureAction {
  type: typeof OmsActions.FETCH_COMPANY_ADDRESS_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface FetchCompanyPocStartAction {
  type: typeof OmsActions.FETCH_COMPANY_POC_START;
  payload: { company_id: number };
  error?: null;
}

export interface FetchCompanyPocSuccessAction {
  type: typeof OmsActions.FETCH_COMPANY_POC_SUCCESS;
  payload: ClientPOC[];
  error?: null;
}

export interface FetchCompanyPocFailureAction {
  type: typeof OmsActions.FETCH_COMPANY_POC_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface FetchTaskDetailAction {
  type: typeof OmsActions.FETCH_TASK_DETAIL;
  payload: { id: number; category: string; vendor_id?: string };
  error?: null;
}

export interface FetchTaskDetailSuccessAction {
  type: typeof OmsActions.FETCH_TASK_DETAIL_SUCCESS;
  payload: TaskDetail;
  error?: null;
}

export interface FetchTaskDetailFailureAction {
  type: typeof OmsActions.FETCH_TASK_DETAIL_FAILURE;
  payload?: null;
  error: string;
}
export interface PurchaseOrderDetailAction {
  type: typeof OmsActions.PURCHASE_ORDER_DETAIL;
  payload: { id: number; category: string; vendor_id?: string };
  error?: null;
}
export interface PurchaseOrderDetailSuccessAction {
  type: typeof OmsActions.PURCHASE_ORDER_DETAIL_SUCCESS;
  payload: PurchaseOrderDetail;
  error?: null;
}
export interface PurchaseOrderDetailFailureAction {
  type: typeof OmsActions.PURCHASE_ORDER_DETAIL_FAILURE;
  payload?: null;
  error: string;
}

export interface UpdateTaskDetailStartAction {
  type: typeof OmsActions.UPDATE_TASK_START;
  payload?: UpdateTaskDetailSchema;
  error?: null;
}

export interface UpdateTaskDetailSuccessAction {
  type: typeof OmsActions.UPDATE_TASK_SUCCESS;
  payload?: UpdateTaskDetailResponseSchema;
  error?: null;
}

export interface UpdateTaskDetailFailureAction {
  type: typeof OmsActions.UPDATE_TASK_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface UpdateTaskAttachmentsStartAction {
  type: typeof OmsActions.UPDATE_TASK_ATTACHMENTS_START;
  payload?: { taskId: number; attachments: FormData };
  error?: null;
}

export interface UpdateTaskAttachmentsSuccessAction {
  type: typeof OmsActions.UPDATE_TASK_ATTACHMENTS_SUCCESS;
  payload?: TaskFileSchema[];
  error?: null;
}

export interface UpdateTaskAttachmentsFailureAction {
  type: typeof OmsActions.UPDATE_TASK_ATTACHMENTS_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface DeleteTaskAttachmentStartAction {
  type: typeof OmsActions.DELETE_TASK_ATTACHMENT_START;
  payload?: { taskId: number; asset_record_id: number };
  error?: null;
}

export interface DeleteTaskAttachmentSuccessAction {
  type: typeof OmsActions.DELETE_TASK_ATTACHMENT_SUCCESS;
  payload?: { asset_record_id: number };
  error?: null;
}

export interface DeleteTaskAttachmentFailureAction {
  type: typeof OmsActions.DELETE_TASK_ATTACHMENT_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface CreateTaskAction {
  type: typeof OmsActions.CREATE_TASK;
  payload: FormData;
  error?: null;
}

export interface CreateTaskSuccessAction {
  type: typeof OmsActions.CREATE_TASK_SUCCESS;
  payload: RfqCard;
  error?: null;
}

export interface CreateTaskFailureAction {
  type: typeof OmsActions.CREATE_TASK_FAILURE;
  payload?: null;
  error: string;
}

export interface AddTaskProductStartAction {
  type: typeof OmsActions.ADD_TASK_PRODUCT_START;
  payload: FormData; //create a interface of what kind of data we want to send
  error?: null;
}

export interface AddTaskProductSuccessAction {
  type: typeof OmsActions.ADD_TASK_PRODUCT_SUCCESS;
  payload: AddRFQProduct; // response exactly as we get from backend
  error?: null;
}

export interface AddTaskProductFailureAction {
  type: typeof OmsActions.ADD_TASK_PRODUCT_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface UpdateTaskPOCAction {
  type: typeof OmsActions.UPDATE_TASK_POC;
  payload: POCPayload;
  error?: null;
}

export interface UpdateTaskPOCSuccessAction {
  type: typeof OmsActions.UPDATE_TASK_POC_SUCCESS;
  payload: POCSchema[];
  error?: null;
}

export interface UpdateTaskPOCFailureAction {
  type: typeof OmsActions.UPDATE_TASK_POC_FAILURE;
  payload?: null;
  error: string;
}

export interface AddVendorDetailAction {
  type: typeof OmsActions.ADD_VENDOR_DETAIL;
  payload: { sourcingId: number; tpm_id: number; vendor_id: number };
  error?: null;
}

export interface AddVendorDetailSuccessAction {
  type: typeof OmsActions.ADD_VENDOR_DETAIL_SUCCESS;
  payload?: ProductVendorDetails;
  error?: null;
}

export interface AddVendorDetailFailureAction {
  type: typeof OmsActions.ADD_VENDOR_DETAIL_FAILURE;
  payload?: null;
  error: string;
}

export interface UpdateVendorDetailAction {
  type: typeof OmsActions.UPDATE_VENDOR_DETAIL;
  payload: VENDOR_DETAIL_PAYLOAD;
  error?: null;
}

export interface UpdateVendorDetailSuccessAction {
  type: typeof OmsActions.UPDATE_VENDOR_DETAIL_SUCCESS;
  payload?: VENDOR_DETAIL_PAYLOAD;
  error?: null;
}

export interface UpdateVendorDetailFailureAction {
  type: typeof OmsActions.UPDATE_VENDOR_DETAIL_FAILURE;
  payload?: null;
  error: string;
}

export interface DeleteVendorDetailAction {
  type: typeof OmsActions.DELETE_VENDOR_DETAIL;
  payload: { sourcingId: number; tpm_id: number; vendor_id: number };
  error?: null;
}

export interface DeleteVendorDetailSuccessAction {
  type: typeof OmsActions.DELETE_VENDOR_DETAIL_SUCCESS;
  payload?: { tpm_id: number; vendor_id: number };
  error?: null;
}

export interface DeleteVendorDetailFailureAction {
  type: typeof OmsActions.DELETE_VENDOR_DETAIL_FAILURE;
  payload?: null;
  error: string;
}

export interface AddTaskCommentAction {
  type: typeof OmsActions.ADD_TASK_COMMENT;
  payload: FormData;
  error?: null;
}

export interface AddTaskCommentSuccessAction {
  type: typeof OmsActions.ADD_TASK_COMMENT_SUCCESS;
  payload?: TaskCommentSchema;
  error?: null;
}

export interface AddTaskCommentFailureAction {
  type: typeof OmsActions.ADD_TASK_COMMENT_FAILURE;
  payload?: null;
  error: string;
}

export interface SampleAddressStartAction {
  type: typeof OmsActions.SAMPLE_ADDRESS_START;
  payload: FormData;
  error?: null;
}

export interface SampleAddressSuccessAction {
  type: typeof OmsActions.SAMPLE_ADDRESS_SUCCESS;
  payload: SamplingPayload; //response exactly as we get from backend
  error?: null;
}

export interface SampleAddressFailureAction {
  type: typeof OmsActions.SAMPLE_ADDRESS_FAILURE;
  payload?: null;
  error: string;
}

export interface UpdateCoaAction {
  type: typeof OmsActions.UPDATE_COA;
  payload: FormData;
  error?: null;
}

export interface UpdateCoaSuccessAction {
  type: typeof OmsActions.UPDATE_COA_SUCCESS;
  payload?: UpdateCoaPayloadSchema;
  error?: null;
}

export interface UpdateCoaFailureAction {
  type: typeof OmsActions.UPDATE_COA_FAILURE;
  payload?: null;
  error: string;
}

export interface DeleteTaskProduct {
  type: typeof OmsActions.DELETE_TASK_PRODUCT;
  payload: { tpm_id: number };
  error?: null;
}

export interface DeleteTaskProductSuccessAction {
  type: typeof OmsActions.DELETE_TASK_PRODUCT_SUCCESS;
  payload?: { tpm_id: number };
  error?: null;
}

export interface DeleteTask {
  type: typeof OmsActions.DELETE_TASK;
  payload: { id: number };
  error?: null;
}

export interface DeleteTaskSuccessAction {
  type: typeof OmsActions.DELETE_TASK_SUCCESS;
  payload?: string;
  error?: null;
}

export interface DeleteTaskFailureAction {
  type: typeof OmsActions.DELETE_TASK_FAILURE;
  payload?: null;
  error: string;
}
export interface DeleteTaskProductFailureAction {
  type: typeof OmsActions.DELETE_TASK_PRODUCT_FAILURE;
  payload?: null;
  error: string;
}

export interface FetchPurchaseRequestAction {
  type: typeof OmsActions.FETCH_PURCHASE_REQUESTS;
  payload: {
    category: string;
  };
  error?: string | null;
}

export interface FetchPurchaseRequestSuccessAction {
  type: typeof OmsActions.FETCH_PURCHASE_REQUESTS_SUCCESS;
  payload: { purchase_requests: TaskCard[]; statuses: string[] };
  error?: string | null;
}

export interface FetchPurchaseRequestFailureAction {
  type: typeof OmsActions.FETCH_PURCHASE_REQUESTS_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface CreatePurchaseRequestAction {
  type: typeof OmsActions.CREATE_PURCHASE_REQUEST;
  payload: FormData;
  error?: null;
}

export interface CreatePurchaseRequestSuccessAction {
  type: typeof OmsActions.CREATE_PURCHASE_REQUEST_SUCCESS;
  payload: TaskCard;
  error?: null;
}

export interface CreatePurchaseRequestFailureAction {
  type: typeof OmsActions.CREATE_PURCHASE_REQUEST_FAILURE;
  payload?: null;
  error: string;
}
export interface AddPurchaseProductStartAction {
  type: typeof OmsActions.ADD_PURCHASE_PRODUCT_START;
  payload: FormData; //create a interface of what kind of data we want to send
  error?: null;
}

export interface AddPurchaseProductSuccessAction {
  type: typeof OmsActions.ADD_PURCHASE_PRODUCT_SUCCESS;
  payload: AddProductSchema; // response exactly as we get from backend
  error?: null;
}

export interface AddPurchaseProductFailureAction {
  type: typeof OmsActions.ADD_PURCHASE_PRODUCT_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface AddProductDeliveryScheduleStartAction {
  type: typeof OmsActions.ADD_PRODUCT_DELIVERY_SCHEDULE_START;
  payload: FormData; //create a interface of what kind of data we want to send
  error?: null;
}

export interface AddProductDeliveryScheduleSuccessAction {
  type: typeof OmsActions.ADD_PRODUCT_DELIVERY_SCHEDULE_SUCCESS;
  payload: DeliverySchedule; // response exactly as we get from backend
  error?: null;
}

export interface AddProductDeliveryScheduleFailureAction {
  type: typeof OmsActions.ADD_PRODUCT_DELIVERY_SCHEDULE_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface UpdatePurchaseOrderDetailStartAction {
  type: typeof OmsActions.UPDATE_PURCHASE_ORDER_DETAIL_START;
  payload: UpdatePurchaseOrderDetailSchema;
  error?: null;
}

export interface UpdatePurchaseOrderDetailSuccessAction {
  type: typeof OmsActions.UPDATE_PURCHASE_ORDER_DETAIL_SUCCESS;
  payload: UpdateTaskDetailResponseSchema;
  error?: null;
}

export interface UpdatePurchaseOrderDetailFailureAction {
  type: typeof OmsActions.UPDATE_PURCHASE_ORDER_DETAIL_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface AddPurchaseRequestCommentAction {
  type: typeof OmsActions.ADD_PURCHASE_REQUEST_COMMENT;
  payload: FormData;
  error?: null;
}

export interface AddPurchaseRequestCommentSuccessAction {
  type: typeof OmsActions.ADD_PURCHASE_REQUEST_COMMENT_SUCCESS;
  payload?: TaskCommentSchema;
  error?: null;
}

export interface AddPurchaseRequestCommentFailureAction {
  type: typeof OmsActions.ADD_PURCHASE_REQUEST_COMMENT_FAILURE;
  payload?: null;
  error: string;
}

export interface UpdatePurchaseOrderProductStartAction {
  type: typeof OmsActions.UPDATE_PURCHASE_ORDER_PRODUCT_START;
  payload: UpdatePurchaseOrderProductSchema;
  error?: null;
}

export interface UpdatePurchaseOrderProductSuccessAction {
  type: typeof OmsActions.UPDATE_PURCHASE_ORDER_PRODUCT_SUCCESS;
  payload: UpdatePurchaseOrderProductSchema;
  error?: null;
}

export interface UpdatePurchaseOrderProductFailureAction {
  type: typeof OmsActions.UPDATE_PURCHASE_ORDER_PRODUCT_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface UpdateDeliveryDetailsStartAction {
  type: typeof OmsActions.UPDATE_DELIVERY_DETAILS_START;
  payload: UpdateDeliveryScheduleSchema;
  error?: null;
}

export interface UpdateDeliveryDetailsSuccessAction {
  type: typeof OmsActions.UPDATE_DELIVERY_DETAILS_SUCCESS;
  payload: UpdateDeliveryScheduleSchema;
  error?: null;
}

export interface UpdateDeliveryDetailsFailureAction {
  type: typeof OmsActions.UPDATE_DELIVERY_DETAILS_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface DeleteProductDeliveryScheduleStartAction {
  type: typeof OmsActions.DELETE_PRODUCT_DELIVERY_SCHEDULE_START;
  payload: number;
  error?: null;
}

export interface DeleteProductDeliveryScheduleSuccessAction {
  type: typeof OmsActions.DELETE_PRODUCT_DELIVERY_SCHEDULE_SUCCESS;
  payload: number; // response exactly as we get from backend
  error?: null;
}

export interface DeleteProductDeliveryScheduleFailureAction {
  type: typeof OmsActions.DELETE_PRODUCT_DELIVERY_SCHEDULE_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface FetchPurchaseRequestCommentAction {
  type: typeof OmsActions.FETCH_PURCHASE_REQUEST_COMMENT;
  payload: { po_id: number; vendor_id: number };
  error?: null;
}

export interface FetchPurchaseRequestCommentSuccessAction {
  type: typeof OmsActions.FETCH_PURCHASE_REQUEST_COMMENT_SUCCESS;
  payload: TaskCommentSchema[];
  error?: null;
}

export interface FetchPurchaseRequestCommentFailureAction {
  type: typeof OmsActions.FETCH_PURCHASE_REQUEST_COMMENT_FAILURE;
  payload?: null;
  error?: string | null;
}

export interface DeletePurchaseRequestVendorAction {
  type: typeof OmsActions.DELETE_PURCHASE_REQUEST_VENDOR;
  payload: { po_id: number; vendor_id: number };
  error?: null;
}

export interface DeletePurchaseRequestVendorSuccessAction {
  type: typeof OmsActions.DELETE_PURCHASE_REQUEST_VENDOR_SUCCESS;
  payload: number;
  error?: null;
}

export interface DeletePurchaseRequestVendorFailureAction {
  type: typeof OmsActions.DELETE_PURCHASE_REQUEST_VENDOR_FAILURE;
  payload?: null;
  error?: string | null;
}

export type OmsActionTypes =
  | FetchFilterAction
  | FetchFilterSuccessAction
  | FetchFilterFailureAction
  | FetchTasksAction
  | FetchTasksSuccessAction
  | FetchTasksFailureAction
  | SetFilter
  | SetSearch
  | NewUserStartAction
  | NewUserSucccessAction
  | NewUserFailureAction
  | NewCompanyStartAction
  | NewCompanySuccessAction
  | NewCompanyFailureAction
  | NewCompanyAddressStartAction
  | NewCompanyAddressSuccessAction
  | NewCompanyAddressFailureAction
  | FetchCompanyAddressStartAction
  | FetchCompanyAddressSuccessAction
  | FetchCompanyAddressFailureAction
  | RemoveCompanyAddress
  | FetchCompanyPocStartAction
  | FetchCompanyPocSuccessAction
  | FetchCompanyPocFailureAction
  | FetchTaskDetailAction
  | FetchTaskDetailSuccessAction
  | FetchTaskDetailFailureAction
  | UpdateTaskDetailStartAction
  | UpdateTaskDetailSuccessAction
  | UpdateTaskDetailFailureAction
  | CreateTaskAction
  | CreateTaskSuccessAction
  | CreateTaskFailureAction
  | AddTaskProductStartAction
  | AddTaskProductSuccessAction
  | AddTaskProductFailureAction
  | UpdateTaskPOCAction
  | UpdateTaskPOCSuccessAction
  | UpdateTaskPOCFailureAction
  | AddVendorDetailAction
  | AddVendorDetailSuccessAction
  | AddVendorDetailFailureAction
  | UpdateVendorDetailAction
  | UpdateVendorDetailSuccessAction
  | UpdateVendorDetailFailureAction
  | DeleteVendorDetailAction
  | DeleteVendorDetailSuccessAction
  | DeleteVendorDetailFailureAction
  | AddTaskCommentAction
  | AddTaskCommentSuccessAction
  | SampleAddressStartAction
  | SampleAddressSuccessAction
  | SampleAddressFailureAction
  | UpdateCoaAction
  | UpdateCoaSuccessAction
  | UpdateCoaFailureAction
  | DeleteTaskProduct
  | DeleteTaskProductSuccessAction
  | DeleteTaskProductFailureAction
  | DeleteTask
  | DeleteTaskSuccessAction
  | DeleteTaskFailureAction
  | PurchaseOrderDetailAction
  | PurchaseOrderDetailSuccessAction
  | PurchaseOrderDetailFailureAction
  | FetchPurchaseRequestAction
  | FetchPurchaseRequestSuccessAction
  | FetchPurchaseRequestFailureAction
  | AddPurchaseProductStartAction
  | AddPurchaseProductSuccessAction
  | AddPurchaseProductFailureAction
  | AddProductDeliveryScheduleStartAction
  | AddProductDeliveryScheduleSuccessAction
  | AddProductDeliveryScheduleFailureAction
  | UpdatePurchaseOrderDetailStartAction
  | UpdatePurchaseOrderDetailSuccessAction
  | UpdatePurchaseOrderDetailFailureAction
  | UpdatePurchaseOrderProductStartAction
  | UpdatePurchaseOrderProductSuccessAction
  | UpdatePurchaseOrderProductFailureAction
  | UpdateDeliveryDetailsStartAction
  | UpdateDeliveryDetailsSuccessAction
  | UpdateDeliveryDetailsFailureAction
  | DeleteProductDeliveryScheduleStartAction
  | DeleteProductDeliveryScheduleSuccessAction
  | DeleteProductDeliveryScheduleFailureAction
  | FetchPurchaseRequestCommentAction
  | FetchPurchaseRequestCommentSuccessAction
  | FetchPurchaseRequestCommentFailureAction
  | UpdateTaskAttachmentsStartAction
  | UpdateTaskAttachmentsSuccessAction
  | UpdateTaskAttachmentsFailureAction
  | DeleteTaskAttachmentStartAction
  | DeleteTaskAttachmentSuccessAction
  | DeleteTaskAttachmentFailureAction
  | DeletePurchaseRequestVendorAction
  | DeletePurchaseRequestVendorSuccessAction
  | DeletePurchaseRequestVendorFailureAction;
