import React, { useEffect, useState } from "react";
import { ProductDetailSchema } from "../../models/Task";
import { useDispatch } from "react-redux";
import {
  deleteTaskProduct,
  updateVendorDetails,
} from "../../redux/oms/omsActions";
import { round } from "../../utils/Helper";
import "./RFQProductCard.scss";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";
import XetToggle from "../shared/xet-toggle/XetToggle";

interface RFQProductCardProps {
  product: ProductDetailSchema;
}

const RFQProductCard: React.FC<RFQProductCardProps> = ({ product }) => {
  const [quantity, setQuantity] = useState<string>(product.quantity);
  const [clientTargetPrice, setClientTargetPrice] = useState<string>(
    product.client_target_price?.toString() || "",
  );
  const [productAccepted, setProductAccepted] = useState(
    product.product_accepted,
  );
  const dispatch = useDispatch();

  const onEnterPress = (
    e: React.KeyboardEvent,
    key: string,
    tpm_id: number,
  ) => {
    const value = (e.target as HTMLInputElement).value;
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      dispatch(
        updateVendorDetails({
          tpm_id: tpm_id,
          [key]: value,
        }),
      );
    }
  };

  const handleQuantityChange = (e: React.ChangeEvent) => {
    const q = (e.target as HTMLInputElement).value as any;
    setQuantity(q);
  };

  const productChangeHandler = (e: React.ChangeEvent) => {
    const q = (e.target as HTMLInputElement).value as any;
    setClientTargetPrice(q);
  };

  const handleProductDelete = (tpm_id: number) => {
    dispatch(deleteTaskProduct({ tpm_id: tpm_id }));
    toast.success("Product Deleted!", toastOptions);
  };

  const handleProductAcceptedToggle = (tpm_id: number, accepted: boolean) => {
    setProductAccepted(accepted);
    dispatch(
      updateVendorDetails({
        tpm_id: tpm_id,
        product_accepted: accepted,
      }),
    );
  };

  return (
    <>
      <div className=" product-card-container p-8 flex-column gap-12">
        <div className="flex-row gap-4">
          <div className="flex-row justify-content-space-between flex-1 align-items-center">
            <p className="product-name xetgo-font-tag bolder">
              {product.name},{" "}
              <span className="capitalize">
                {product.material.toLocaleLowerCase()}.
              </span>
            </p>
            <div className="flex-row align-items-center gap-26 position-relative">
              <p className="xetgo-font-tag">Client Target Price</p>
              <input
                className="product-input xetgo-font-tag py-6 px-8"
                value={clientTargetPrice || ""}
                onKeyDown={(e) =>
                  onEnterPress(e, "client_target_price", product.tpm_id)
                }
                onChange={productChangeHandler}
              />
              <span className="product-unit xetgo-font-tag pcs-unit">₹</span>
            </div>
          </div>
          <span className="flex-row align-items-center">
            <img
              className="sourcing-img-icons cursor-pointer"
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114095750-trash.svg"
              alt="trash"
              onClick={() => handleProductDelete(product.tpm_id)}
            />
          </span>

          {/* <XetToggle
            onToggle={(value: boolean) =>
              handleProductAcceptedToggle(product.tpm_id, value)
            }
            toggleValue={productAccepted}
            backgroundColor="#FFFFFF"
            disableColor="#FF5252"
            enableColor="#00C414"
          /> */}
        </div>
        <div className="flex-row gap-8">
          <textarea
            className="productCardTextArea xetgo-font-tag product-input py-6 px-8 flex-1"
            defaultValue={product.description}
            onKeyDown={(e) => {
              onEnterPress(e, "description", product.tpm_id);
            }}
          />
          <div className="flex-column gap-8 product-card-mid-right-container">
            <div className="flex-row align-items-center gap-26 position-relative">
              <p className="pricing-text">Quantity </p>
              <input
                className="xetgo-font-tag product-input py-6 px-8"
                style={{ width: "156px" }}
                value={quantity}
                onKeyDown={(e) => {
                  onEnterPress(e, "quantity", product.tpm_id);
                }}
                onChange={handleQuantityChange}
              ></input>
              <span className="product-unit pcs-unit">
                {product.unit || "pcs"}
              </span>
            </div>
            <div className="flex-row align-items-center gap-12">
              <p className="pricing-text">Final Price </p>
              <p className="xetgo-font-tag-bolder py-6 px-8 invariant-input flex-row justify-content-space-between">
                {round(product.final_price || 0, 2) || "-"} <span>₹</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RFQProductCard;
