import { User } from "../../models/User";
import {
  FetchUserAction,
  FetchUserFailureAction,
  FetchUserSuccessAction,
  LogOutUserAction,
  SetUserAction,
  ToggleNewNotificationAction,
  UserActions,
} from "./userTypes";

export const fetchUser = (data: { jwtToken: string }): FetchUserAction => ({
  type: UserActions.FETCH_USER,
  payload: data,
});

export const fetchUserSuccess = (user: User): FetchUserSuccessAction => ({
  type: UserActions.FETCH_USER_SUCCESS,
  payload: user,
});

export const fetchUserFailure = (error: string): FetchUserFailureAction => ({
  type: UserActions.FETCH_USER_FAILURE,
  error,
});

export const setUser = (user: User | null): SetUserAction => ({
  type: UserActions.SET_USER,
  payload: user,
});

export const logOutUser = (): LogOutUserAction => ({
  type: UserActions.LOGOUT_USER,
});

export const toggleNewNotification = (): ToggleNewNotificationAction => ({
  type: UserActions.TOGGLE_NEW_NOTIFICATION,
});
