import React, { useEffect, useRef, useState } from "react";
import CommentCard from "./CommentCard/CommentCard";
import UserSelector from "./UserSelector/UserSelector";
import dayjs from "dayjs";
import "./TaskComment.scss";
import { useSelector } from "react-redux";
import {
  selectPurchaseOrderComments,
  selectTaskComments,
} from "../../../redux/oms/omsSelectors";
import { useDispatch } from "react-redux";
import {
  addPurchaseRequestComment,
  addTaskComment,
} from "../../../redux/oms/omsActions";
import { getFileIcon } from "../../../utils/Helper";
import { TaskCommentSchema } from "../../../models/Task";

interface TaskCommentProps {
  task_id?: number;
  vendor_id?: number;
  purchase_request_id?: number;
  handleClose: () => void;
}

const TaskComment: React.FC<TaskCommentProps> = ({
  task_id,
  purchase_request_id,
  vendor_id,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const commentList = task_id
    ? useSelector(selectTaskComments)
    : useSelector(selectPurchaseOrderComments);
  const [filteredCommentList, setFilteredCommentList] = useState<
    TaskCommentSchema[]
  >([]);
  const [showUserSelector, setShowUserSelector] = useState(false);
  const [fileList, setFileList] = useState<File[]>([]);
  const [search, setSearch] = useState<string | null>(null);
  const [searchIndex, setSearchIndex] = useState(-1);
  const [keyAction, setKeyAction] = useState({ action: "none" });
  const [showIndex, setShowIndex] = useState(-1);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const descriptionRef = useRef<null | HTMLDivElement>(null);
  const [taggedUserId, setTaggedUserId] = useState<number[]>([]);
  const [commentTypeTab, setCommentTypeTab] = useState<
    "INTERNAL_DISCUSSION" | "CLIENT_DISCUSSION"
  >("INTERNAL_DISCUSSION");

  useEffect(() => {
    todayIndex();
    scrollToBottom();
    setFilteredCommentList(commentList);
  }, [commentList]);

  const scrollToBottom = () => {
    if (messagesEndRef.current != null) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  const changeHandler = (event: React.KeyboardEvent) => {
    // const value = (event.target as HTMLTextAreaElement).value;
    const value = descriptionRef?.current?.innerHTML || "";
    const lastLetter = value.charAt(value.length - 1);
    if (lastLetter === "@") {
      setShowUserSelector(true);
      setSearch("");
      setSearchIndex(value.length);
    } else {
      if (showUserSelector) {
        setSearch(value.substring(searchIndex));
      }
    }
  };

  const addComment = async () => {
    if (descriptionRef.current) {
      const formData = new FormData();
      formData.set("comment", descriptionRef.current?.innerHTML);
      formData.set("comment_type", commentTypeTab);
      descriptionRef.current.textContent = "";
      if (fileList.length > 0) {
        fileList.forEach((file, index) => {
          formData.append("files[]", file);
          formData.append("displayNames[]", file.name);
        });
      }
      if (taggedUserId.length > 0) {
        taggedUserId.forEach((id) => {
          formData.append("tagged_user_id[]", id.toString());
        });
      }
      if (task_id) {
        formData.set("task_id", task_id.toString());
        dispatch(addTaskComment(formData));
      } else if (purchase_request_id && vendor_id) {
        formData.set("purchase_request_id", purchase_request_id.toString());
        formData.set("vendor_id", vendor_id.toString());
        dispatch(addPurchaseRequestComment(formData));
      }
      setFileList([]);
      setTaggedUserId([]);
    }
  };

  const addUser = (val: string, id?: number) => {
    if (descriptionRef.current && search != null) {
      const old = descriptionRef.current.innerHTML;
      descriptionRef.current.innerHTML =
        old.substring(0, old.length - 1 - search?.length) +
        `<span class="highlight">@${val}</span>` +
        "&nbsp;";
      setShowUserSelector(false);
      setKeyAction({ action: "none" });
      descriptionRef?.current?.focus();
      document.execCommand("selectAll", false);
      // collapse selection to the end
      document.getSelection()?.collapseToEnd();
    }
    if (id) {
      setTaggedUserId((val) => [...val, id]);
    }
  };

  const todayIndex = () => {
    const today = dayjs().format("DD-MM-YY");
    let found = false;
    filteredCommentList.forEach((comment, index) => {
      if (comment.timestamp.startsWith(today) && !found) {
        setShowIndex(index);
        found = true;
      }
    });
  };

  const onEnterPress = (e: React.KeyboardEvent) => {
    if (e.keyCode === 38) {
      e.preventDefault();
      setKeyAction({ action: "up" });
    }
    if (e.keyCode === 40) {
      e.preventDefault();
      setKeyAction({ action: "down" });
    }
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      if (showUserSelector) {
        setKeyAction({ action: "enter" });
      } else {
        addComment();
        if (descriptionRef.current) {
          descriptionRef.current.innerHTML = "";
        }
      }
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      setFileList(files);
      const displayNames = files.map((file) => file.name);
      const formData = new FormData();
      fileList.forEach((file, index) => {
        formData.append("files[]", file);
        const displayName = displayNames[index]?.trim();
        if (displayName) {
          formData.append("displayNames[]", displayName);
        }
      });
    }
  };

  const deleteFile = (file: File) => {
    setFileList((old) => old.filter((row) => row.name !== file.name));
  };

  return (
    <div className="task-comments-container border-box flex-column justify-content-space-between">
      <div
        className="flex-row p-16 justify-content-space-between align-items-center"
        style={{ borderBottom: "1px solid #E1E1E1" }}
      >
        <div className="flex-row gap-12 align-items-center">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240117073111-chatbox.svg"
            width={16}
            height={16}
            alt=""
          />
          <p style={{ color: "#5151EC" }} className="xetgo-font-button-bolder">
            Comments
          </p>
        </div>
        <img
          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231104133216-purple-close-circle.svg"
          width={24}
          height={24}
          className="cursor-pointer"
          onClick={handleClose}
          alt=""
        />
      </div>
      <div className="comment-list-container flex-column gap-12 p-16">
        {filteredCommentList.map((comment, index) => {
          return (
            <div key={index}>
              {showIndex === index && (
                <div className="today-border">
                  <p className="today xetgo-font-mini px-4">TODAY</p>
                </div>
              )}
              <CommentCard
                avatar={comment.avatar}
                name={comment.name}
                timestamp={comment.timestamp}
                description={comment.description}
                commentFiles={comment.files}
              />
            </div>
          );
        })}
        <div ref={messagesEndRef} />
      </div>
      <div className="add-comment-container full-width p-16 border-box">
        <div className="full-width p-8 border-box xetgo-font-tag comment-value">
          {fileList && (
            <div>
              {fileList.map((file: File, index: number) => {
                return (
                  <div
                    style={{ background: "white", height: "40px" }}
                    className="file-card flex-row p-8 justify-content-space-between border-box"
                    key={index}
                  >
                    <div className="file-left flex-row gap-16 align-items-center">
                      <img
                        src={getFileIcon(file)}
                        alt="file-icon"
                        height={24}
                        width={24}
                        className="file-preview"
                      />
                      <p className="xetgo-font-tag file-name">{file.name}</p>
                    </div>
                    <div className="flex-row gap-12 align-items-center">
                      <img
                        className="cursor-pointer"
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012084858-close.svg"
                        alt="close"
                        width={16}
                        height={16}
                        onClick={() => deleteFile(file)}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div
            style={{ outline: "none" }}
            className="full-width p-8 border-box xetgo-font-tag "
            ref={descriptionRef}
            onKeyDown={onEnterPress}
            contentEditable={true}
            onKeyUp={changeHandler}
          ></div>
        </div>

        <div className="attach-send-comment-container flex-row align-items-center justify-content-space-between">
          <input
            onChange={(event) => {
              handleFileChange(event);
            }}
            id="commentFileInput"
            name="file"
            type="File"
            style={{ display: "none" }}
            multiple
          />
          <label htmlFor="commentFileInput">
            <img
              className="cursor-pointer"
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240117075035-_WYSIWYG-toolbar-button.svg"
              alt="attachment-icon"
            />
          </label>
          <img
            onClick={addComment}
            className="cursor-pointer"
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240117075035-Send+Button.svg"
            alt="send-icon"
          />
        </div>

        {showUserSelector && (
          <UserSelector
            descriptionRef={descriptionRef}
            handleAdd={addUser}
            search={search}
            keyAction={keyAction}
          />
        )}
      </div>
    </div>
  );
};

export default TaskComment;
