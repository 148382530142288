import { FilterKeys } from "../../../../redux/oms/omsTypes";

export interface FilterTypes {
  key: FilterKeys;
  name: string;
}

export const filters: FilterTypes[] = [
  {
    key: FilterKeys.ASSIGNEES,
    name: "Assignees",
  },
  {
    key: FilterKeys.CLIENTS,
    name: "Client Name",
  },
  {
    key: FilterKeys.VENDORS,
    name: "Vendor Name",
  },
  {
    key: FilterKeys.CLIENT_TYPE,
    name: "Client Type",
  },
];

export const getFilterList = (role: string[]) => {
  const allFilters = [...filters];
  if (role.indexOf("SOURCING") > -1) {
    allFilters.splice(0, 1);
  }
  return allFilters;
};
