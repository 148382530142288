import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SideNav from "../shared/sidenav/SideNav";
import Options from "./Options/Options";
import { ToastContainer } from "react-toastify";
import "./Oms.scss";
import { useSelector } from "react-redux";
import {
  selectFilteredTasks,
  selectTaskStatuses,
} from "../../redux/oms/omsSelectors";
import { IOmsStatusTab } from "../../models/Oms";
import TaskOverview from "../TaskOverview/TaskOverview";

const Oms: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const statuses = useSelector(selectTaskStatuses);
  const filteredTasks = useSelector(selectFilteredTasks);
  const [selectedTab, setSelectedTab] = useState<IOmsStatusTab>(
    new URLSearchParams(location.search).get("status") as IOmsStatusTab,
  );

  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    const status = queryParams.get("status");

    // If no status is provided, redirect to the default status
    if (!status || (status !== "ongoing" && status !== "completed")) {
      queryParams.set("status", "ongoing");
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }

    setSelectedTab(status as IOmsStatusTab);
  }, [location.search, location.pathname, navigate]);

  const getQueryParams = (search: string) => {
    return new URLSearchParams(search);
  };

  return (
    <>
      <div className="flex-row">
        <SideNav></SideNav>
        <div className="content-section flex-1 position-relative flex-column">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240109190634-box+1.svg"
            alt="box-background"
            className="box-background"
          />
          <Options showStatusTabs={true} selectedTab={selectedTab} />
          {filteredTasks && (
            <TaskOverview
              statuses={statuses}
              tasks={filteredTasks}
            ></TaskOverview>
          )}
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};

export default Oms;
