import { FilterOptionSchema } from "../redux/oms/omsTypes";
import { FormBuilder, Validators } from "react-reactive-form";

export const TASK_PRIORITY: FilterOptionSchema[] = [
  {
    name: "HIGH",
    id: 1,
    avatar:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231025104330-flag.svg",
  },
  {
    name: "MEDIUM",
    id: 2,
    avatar:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231018095710-flag.svg",
  },
  {
    name: "LOW",
    id: 3,
    avatar:
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231108100822-flag.svg",
  },
];

export const RFQ_LOST_REASONS: FilterOptionSchema[] = [
  {
    id: 1,
    name: "Sourcing Regret",
  },
  {
    id: 2,
    name: "High Prices",
  },
  {
    id: 3,
    name: "No Response",
  },
  {
    id: 4,
    name: "Samples Rejected",
  },
  {
    id: 5,
    name: "Low MOQs",
  },
  {
    id: 6,
    name: "Late Quote Submission",
  },
  {
    id: 7,
    name: "No Pain Points",
  },
  {
    id: 8,
    name: "NPD",
  },
  {
    id: 9,
    name: "Wrong POC",
  },
  {
    id: 10,
    name: "Budgetory quote",
  },
  {
    id: 11,
    name: "Invalid RFI",
  },
  {
    id: 12,
    name: "High Proposed Lead Time",
  },
];

export const NEW_RFQ_FORM = FormBuilder.group({
  name: ["", Validators.required],
  priority: [null, [Validators.required, Validators.email]],
  description: ["", Validators.required],
  client: [null, Validators.required],
  client_type: ["EXISTING", Validators.required],
  poc: FormBuilder.array([
    FormBuilder.group({
      name: ["", Validators.required],
      mobile: ["", Validators.required],
      email: ["", Validators.required],
      designation: ["", Validators.required],
    }),
  ]),
  address: FormBuilder.array([
    FormBuilder.group({
      delivery: [null, Validators.required],
      products: FormBuilder.array([
        FormBuilder.group({
          name: [null, Validators.required],
          material: ["", Validators.required],
          quantity: ["", Validators.required],
          unit: ["", Validators.required],
          description: ["", Validators.required],
        }),
      ]),
    }),
  ]),
  uploads: FormBuilder.array([]),
});

export const NEW_CONTACT_FORM = FormBuilder.group({
  name: "",
  mobile: "",
  email: "",
  designation: "",
});

export const NEW_PRODUCT_FORM = FormBuilder.group({
  name: ["", Validators.required],
  material: ["", Validators.required],
  quantity: ["", Validators.required],
  unit: ["", Validators.required],
  description: ["", Validators.required],
});

export const NEW_ADDRESS_FORM = FormBuilder.group({
  delivery: null,
  products: FormBuilder.array([NEW_PRODUCT_FORM]),
});

export const MULTI_ADDRESS_FORM = FormBuilder.group({
  address: FormBuilder.array([NEW_ADDRESS_FORM]),
});

export const NEW_FILE_FORM = (id: number, name: string, category: string) =>
  FormBuilder.group({
    id: [id, Validators.required],
    name: [name, Validators.required],
    category: [category, Validators.required],
  });

export interface RfqCard {
  task_id: number;
  title: string;
  description: string;
  start_date: string;
  due_date: string;
  priority?: string;
  status: string;
  client: {
    client_name: string;
    client_avatar: string;
    client_type: string;
  };
  assignees: {
    name: string;
    avatar: string;
    role: string;
  }[];
}
