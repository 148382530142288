import React, { useState } from "react";
import "./POCDetails.scss";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectClientPocs } from "../../../redux/oms/omsSelectors";
import { POCSchema } from "../../../redux/oms/omsTypes";
import { TaskDetail } from "../../../models/Task";
import { updateTaskPOC } from "../../../redux/oms/omsActions";

interface POCDetailsProps {
  id: number;
  pocs: POCSchema[];
  client_detail: {
    id: number;
    name: string;
    avatar: string;
    client_type: string;
  };
  viewOnly?: boolean;
}

const POCDetails: React.FC<POCDetailsProps> = ({
  id,
  pocs,
  client_detail,
  viewOnly = false,
}) => {
  const dispatch = useDispatch();
  const clientPocs = useSelector(selectClientPocs);
  const [pocList, setPOCList] = useState<POCSchema[]>(pocs);

  const onChangeHandler = (
    index: number,
    key: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPOCList((old) => {
      return old.map((cur, ind) => {
        let poc = cur;
        if (ind === index) {
          poc = { ...poc, [key]: event.target.value };
        }
        return poc;
      });
    });
  };

  const newPOCHandler = () => {
    setPOCList((old) => [
      ...old,
      { name: "", email: "", phone_number: "", designation: "" },
    ]);
  };

  const savePOCDetail = () => {
    const payload = {
      id: id,
      company_id: client_detail.id,
      existing: pocList.filter((poc) => poc.id != null),
      new: pocList.filter((poc) => poc.id == null),
    };
    dispatch(updateTaskPOC(payload));
  };

  return (
    <div className="poc-details-container p-8 flex-column gap-16">
      <div className="flex-row justify-content-space-between align-items-center full-width border-box top-row">
        <p className="xetgo-font-button-bold" style={{ color: "#5151EC" }}>
          {client_detail.name} (Client POC)
        </p>
        {!viewOnly && (
          <div className="flex-row gap-12 align-items-center">
            <span
              className="flex-row xetgo-font-tag-bold cursor-pointer gap-2"
              style={{ color: "#5151EC", width: "fit-content" }}
              onClick={newPOCHandler}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                alt="plus"
                width={16}
                height={16}
              />
              Add POC
            </span>
            <button
              className="save-changes px-10 py-4 xetgo-font-tag cursor-pointer"
              onClick={savePOCDetail}
            >
              Save
            </button>
          </div>
        )}
      </div>
      <div className="flex-column gap-8">
        <div className="flex-row gap-8 full-width">
          <p className="xetgo-font-tag-bold flex-1">Name</p>
          <p className="xetgo-font-tag-bold flex-1">Email</p>
          <p className="xetgo-font-tag-bold flex-1">Phone Number</p>
          <p className="xetgo-font-tag-bold flex-1">Designation</p>
        </div>
        {pocList.map((poc, index) => {
          return (
            <div className="flex-row gap-8 full-width" key={index}>
              <input
                className="xetgo-font-tag py-5 px-8 contact-input flex-1"
                value={poc.name}
                onChange={(e) => onChangeHandler(index, "name", e)}
              />
              <input
                className="xetgo-font-tag py-5 px-8 contact-input flex-1"
                value={poc.email}
                onChange={(e) => onChangeHandler(index, "email", e)}
              />
              <input
                className="xetgo-font-tag py-5 px-8 contact-input flex-1"
                value={poc.phone_number}
                onChange={(e) => onChangeHandler(index, "phone_number", e)}
              />
              <input
                className="xetgo-font-tag py-5 px-8 contact-input flex-1"
                value={poc.designation}
                onChange={(e) => onChangeHandler(index, "designation", e)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default POCDetails;
