import React from "react";
import axios from "axios";
import "./UserSignIn.scss";
import { useNavigate } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../config/firebaseAuthConfig";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/user/userActions";
import URL from "../../urls/Urls";
import { ToastContainer, toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";

import "react-toastify/dist/ReactToastify.css";

const UserLoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      if (result.user.email?.endsWith("@xetgo.co")) {
        const response = await axios.post(URL.USERS.GOOGLE_LOGIN, {
          email: result.user.email,
          name: result.user.displayName,
        });
        dispatch(setUser(response.data.user));
        navigate("files");
      } else {
        toast.error("Not Authorised", toastOptions);
      }
    } catch (error) {
      console.error("Error occurred during sign-in:", error);
    }
  };

  return (
    <div className="signin-container">
      <div className="company-info">
        <h1>Xetgo</h1>
        <img
          src="https://avatars.githubusercontent.com/u/71543292?s=200&v=4"
          alt=""
        />
      </div>
      <div className="social-login-container">
        <button className="google-login-button" onClick={handleGoogleLogin}>
          <i className="fa-brands fa-google"></i>
          Sign in with Google
        </button>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default UserLoginForm;
