import { User } from "../../models/User";
export const UserActions = {
  FETCH_USER: "FETCH_USER",
  FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
  FETCH_USER_FAILURE: "FETCH_USER_FAILURE",
  SET_USER: "SET_USER",
  LOGOUT_USER: "LOGOUT_USER",
  TOGGLE_NEW_NOTIFICATION: "TOGGLE_NEW_NOTIFICATION",
};

export interface FetchUserAction {
  type: typeof UserActions.FETCH_USER;
  payload: { jwtToken: string };
  error?: null;
}

export interface FetchUserSuccessAction {
  type: typeof UserActions.FETCH_USER_SUCCESS;
  payload: User;
  error?: null;
}

export interface FetchUserFailureAction {
  type: typeof UserActions.FETCH_USER_FAILURE;
  payload?: null;
  error: string;
}

export type SetUserAction = {
  type: typeof UserActions.SET_USER;
  payload: User | null;
  error?: string | null;
};

export type LogOutUserAction = {
  type: typeof UserActions.LOGOUT_USER;
  payload?: null;
  error?: string | null;
};

export type ToggleNewNotificationAction = {
  type: typeof UserActions.TOGGLE_NEW_NOTIFICATION;
  payload?: null;
  error?: string | null;
};

export type UserActionTypes =
  | FetchUserAction
  | FetchUserSuccessAction
  | FetchUserFailureAction
  | SetUserAction
  | LogOutUserAction
  | ToggleNewNotificationAction;
