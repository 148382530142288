// src/redux/user/userSelector.ts
import { createSelector } from "reselect";
import { RootState } from "../store";
import { UserState } from "./userReducer";
import { isNotNull } from "../../utils/Helper";

// Non-memoized selector function to access the user state from the Redux store
const selectUserState = (state: RootState): UserState => state.user;

// Memoized selector using createSelector
export const selectUser = createSelector(
  [selectUserState],
  (userState) => userState,
);

export const isAuthenticated = createSelector([selectUserState], (userState) =>
  isNotNull(userState.user),
);
export const selectAuthToken = createSelector(
  [selectUserState],
  (userState) => userState.user?.jwtToken,
);
export const isUserLoading = createSelector(
  [selectUserState],
  (userState) => userState.loading,
);

export const userDetails = createSelector(
  [selectUserState],
  (userState) => userState.user,
);

export const userRoles = createSelector(
  [selectUserState],
  (userState) => userState.user.role,
);

export const newNotification = createSelector(
  [selectUserState],
  (userState) => userState.newNotification,
);
