import React from "react";
import FileUpload from "./fileupload/FileUpload";
import "./Home.scss";
import UploadedFileList from "./uploaded-file-list/UploadedFileList";
import SideNav from "../shared/sidenav/SideNav";

const Home: React.FC = () => {
  return (
    <div className="home-container">
      <div className="home-content-body">
        <SideNav></SideNav>
        <div className="content-section files-container">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240109190634-box+1.svg"
            alt="box-background"
            className="box-background"
          />
          <FileUpload></FileUpload>
          <UploadedFileList></UploadedFileList>
        </div>
      </div>
    </div>
  );
};

export default Home;
