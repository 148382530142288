import { Subject } from "rxjs";
import URL from "../urls/Urls";
import ApiService from "./apiService";

const apiClient = ApiService().client;
export const fileUploadSubject = new Subject<boolean>();
export interface FileData {
  name: string;
  url: string;
  id: number;
}
export interface FetchFilesResponse {
  data: FileData[];
  totalPages: number;
}

export const fetchFiles = async (
  pageNumber: number,
  searchQuery?: string,
): Promise<FetchFilesResponse> => {
  try {
    const params: { pageNumber: number; searchQuery?: string } = { pageNumber };
    if (searchQuery) {
      params.searchQuery = searchQuery;
    }

    const response = await apiClient.get(URL.FILES.GET_FILES, {
      params: params,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching files:", error);
    return { data: [], totalPages: 0 };
  }
};

export const handleDeleteFileFromServer = async (
  fileID: number,
): Promise<void> => {
  try {
    await apiClient.delete(URL.FILES.DELETE_FILE(fileID));
  } catch (error) {
    console.error("Error deleting file:", error);
  }
};

export const editFileFromDb = async (
  fileID: number,
  fileName: string,
): Promise<void> => {
  try {
    await apiClient.put(URL.FILES.UPDATE_FILE(fileID), {
      fileID: fileID,
      fileName: fileName,
    });
  } catch (error) {
    console.error("Error Editing file:", error);
  }
};
