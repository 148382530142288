import React from "react";
import "./NegotiationHistoryModal.scss";
import { VendorNegotiationSchema } from "../../../models/VendorNegotiation";
import { FinalPrice, round } from "../../../utils/Helper";

interface VendorNegotiationProps {
  vendorNegotiationData: VendorNegotiationSchema[] | null;
}

export const NegotiationHistoryModal: React.FC<VendorNegotiationProps> = ({
  vendorNegotiationData,
}) => {
  let defaultNegotiationData = {
    user_name: "-",
    base_price: "-",
    margin: "-",
    final_price: "-",
    vendor_target_price: "-",
    created_at: "-",
  };

  const setNegotiationData = (data: VendorNegotiationSchema) => {
    const trackableType = data.trackable_type;
    const obj = {
      user_name: data.user_name,
      [trackableType]: data.trackable_value,
      created_at: data.created_at,
    };
    let negotiationData = { ...defaultNegotiationData, ...obj };
    const final_price =
      negotiationData.base_price !== "-" && negotiationData.margin !== "-"
        ? FinalPrice(
            Number(negotiationData.base_price),
            Number(negotiationData.margin),
          ).toString()
        : "-";
    negotiationData = { ...negotiationData, final_price };
    return negotiationData;
  };

  return (
    <div className="history-modal-box">
      <div className="labels-row">
        <p>NAME </p>
        <p>BASE PRICE </p>
        <p>MARGIN </p>
        <p>FINAL PRICE </p>
        <p>TARGET PRICE </p>
        <p>DATE</p>
      </div>

      {vendorNegotiationData?.map((data, index) => {
        const negotiationData = setNegotiationData(data);
        defaultNegotiationData = negotiationData;

        return (
          <div className="heading-row heading-column" key={index}>
            <p> {negotiationData.user_name} </p>
            <p>
              {" "}
              {negotiationData.base_price !== "-"
                ? "₹ " + negotiationData.base_price
                : "-"}{" "}
            </p>
            <p>
              {" "}
              {negotiationData.margin !== "-"
                ? `${negotiationData.margin}%`
                : "-"}{" "}
            </p>
            <p>
              {" "}
              {negotiationData.final_price !== "-"
                ? "₹ " + round(Number(negotiationData.final_price), 2)
                : "-"}{" "}
            </p>
            <p>
              {" "}
              {negotiationData.vendor_target_price !== "-"
                ? "₹ " + negotiationData.vendor_target_price
                : "-"}{" "}
            </p>
            <p> {negotiationData.created_at} </p>
          </div>
        );
      })}
    </div>
  );
};
