import React from "react";
import "./Authentication.scss";
import UserSignIn from "../user-signin/UserSignIn";

const Authentication: React.FC = () => {
  return (
    <div className="login-container">
      <div className="login-box">
        <UserSignIn />
      </div>
    </div>
  );
};

export default Authentication;
