import React, { useEffect, useState } from "react";
import { FormBuilder, Validators } from "react-reactive-form";

import { useSelector } from "react-redux";
import {
  selectBaseProducts,
  selectFilterOptions,
  selectOrderedProducts,
} from "../../redux/oms/omsSelectors";
import {
  AbstractControl,
  FieldArray,
  FieldControl,
  FieldGroup,
  FormArray,
} from "react-reactive-form";
import Filter from "../oms/Options/Filter/Filter";
import { isNullOrUndefined } from "../../utils/Helper";
import {
  FilterOptionSchema,
  NewClientPayloadSchema,
} from "../../redux/oms/omsTypes";
import CSVReader from "../shared/CSVReader/CSVReader";
import { CSVRow } from "../../models/Task";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";
import { filters } from "../oms/Options/Filter/Filter.data";
import "./AddPurchaseProduct.scss";
import { generateSKUName } from "../../models/Product";
import NewCompanyForm from "../oms/NewCompanyForm/NewCompanyForm";
import { newCompanyStart } from "../../redux/oms/omsActions";
import { useDispatch } from "react-redux";

interface AddPurchaseProductProps {
  productForm: AbstractControl;
}

const AddPurchaseProduct: React.FC<AddPurchaseProductProps> = ({
  productForm,
}) => {
  const dispatch = useDispatch();
  const vendors = useSelector((state) =>
    selectFilterOptions(state, filters[2].key),
  );
  const product_lists = useSelector(selectBaseProducts);
  const [openNewVendorForm, setOpenNewVendorForm] = useState(false);
  const orderedProducts = useSelector(selectOrderedProducts);
  const [openOrderedProducts, setOpenOrderedProducts] = useState(-1);
  const [selectedOrdereredProduct, setSelectedOrdereredProduct] = useState<{
    [key: number]: number;
  }>({});

  const addNewProductHandler = () => {
    const productControls = productForm.get("products") as FormArray;
    const index = productControls.length + 1;
    const control = FormBuilder.group({
      name: ["", Validators.required],
      sku_name: ["", Validators.required],
      quantity: ["", Validators.required],
      description: ["", Validators.required],
    });
    control.meta = {
      key: index,
    };
    productControls.push(control);
  };

  const parsedData = (data: CSVRow[]) => {
    const result: { [key: number]: AbstractControl[] } = {};
    data.every((row: CSVRow) => {
      const vendor = vendors.find((val) => val.id === Number(row.vendor_id));
      const product = product_lists.find(
        (val) => val.id === Number(row.product_id),
      );
      if (!vendor || !product) {
        toast.error("Product or vendor not found !", toastOptions);
        return false;
      }
      addProductToForm(
        result,
        vendor,
        product,
        row.description,
        Number(row.quantity),
        row.vendor_po_number,
        Number(row.cost_price),
        Number(row.selling_price),
      );
      return true;
    });
    const values = Object.values(result);
    if (values.length > 0) {
      const form = productForm.get("products") as FormArray;
      if (form) {
        for (let i = 0; i < form.length; i++) {
          form.removeAt(0);
        }
        let ct = 0;
        values.forEach((rows: AbstractControl[]) => {
          rows.forEach((row: AbstractControl) => {
            row.meta = {
              key: ct,
            };
            form.push(row);
            ct++;
          });
        });
      }
    } else {
      toast.error("No Data Found !", toastOptions);
    }
  };

  const addProductToForm = (
    result: any,
    vendor: FilterOptionSchema,
    product: FilterOptionSchema,
    description: string,
    quantity: number,
    vendor_po_number: string,
    cost_price: number,
    selling_price: number,
  ) => {
    const productDetail = FormBuilder.group({
      name: [product, Validators.required],
      sku_name: [generateSKUName(product), Validators.required],
      material: [product.material, Validators.required],
      quantity: [quantity, Validators.required],
      vendor: [vendor, Validators.required],
      vendor_po_number: [vendor_po_number, Validators.required],
      cost_price: [cost_price, Validators.required],
      selling_price: [selling_price, Validators.required],
      description: [description, Validators.required],
    });
    if (result[vendor.id]) {
      const productControls = result[vendor.id];
      productControls.push(productDetail);
    } else {
      result[vendor.id] = [productDetail];
    }
  };

  const productSelected = (
    productControl: AbstractControl,
    product: FilterOptionSchema,
  ) => {
    productControl.patchValue({
      name: product,
    });
    productControl.patchValue({
      material: product.material,
    });
    productControl.patchValue({
      sku_name: generateSKUName(product),
    });
  };

  const addNewVendor = (payload: NewClientPayloadSchema) => {
    dispatch(newCompanyStart(payload));
    setOpenNewVendorForm(false);
  };

  const toggleOrderProductDropdown = (index: number) => {
    if (openOrderedProducts === index) {
      setOpenOrderedProducts(-1);
    } else {
      setOpenOrderedProducts(index);
    }
  };

  const orderedProductSelected = (
    index: number,
    opindex: number,
    productControl: AbstractControl,
  ) => {
    setSelectedOrdereredProduct((map) => {
      map[index] = opindex;
      return map;
    });
    setOpenOrderedProducts(-1);
    const selectedProduct = orderedProducts[opindex];
    const product = product_lists.filter(
      (product) => product.name === selectedProduct.product_name,
    )[0];
    const vendor = vendors.filter(
      (vendor) => vendor.name === selectedProduct.name,
    );
    productControl.patchValue({
      name: product,
      material: product.material,
      sku_name: generateSKUName(product),
      cost_price: selectedProduct.cost_price,
      selling_price: selectedProduct.selling_price,
      description: selectedProduct.description,
      vendor: vendor[0],
    });
  };

  return (
    <div className="add-purchase-form flex-column gap-12">
      <FieldGroup
        control={productForm}
        strict={false}
        render={({ value, pristine, invalid }) => (
          <>
            <CSVReader
              csvDataUploaded={parsedData}
              template_link="https://docs.google.com/spreadsheets/d/138pTDT0yD2wIAyBIthSTX7GO-LRcTa86ptiGMPQRpd0/edit#gid=0"
            />
            <FieldArray
              name="products"
              strict={false}
              render={(productForm) => {
                const { controls } = productForm as FormArray;
                return (
                  <div className=" add-purchase-box flex-column gap-12 p-8 product-list">
                    {controls.map(
                      (productControl: AbstractControl, index: number) => (
                        <div
                          key={`${productControl.meta.key}-${String(index)}`}
                        >
                          <FieldGroup
                            control={productControl}
                            strict={false}
                            render={() => (
                              <div className="flex-column gap-12">
                                <div
                                  className="full-width position-relative"
                                  onClick={() =>
                                    toggleOrderProductDropdown(index)
                                  }
                                >
                                  <div className="product-dropdown-input px-8 py-4 xetgo-font-tag border-box flex-row align-items-center justify-content-space-between">
                                    <span>
                                      {orderedProducts[
                                        selectedOrdereredProduct[index]
                                      ]?.product_name || "Enter Product Name "}
                                    </span>
                                    <img
                                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231005115527-chevron-down.svg"
                                      alt="dropdown-arrow"
                                      height={16}
                                      width={16}
                                    />
                                  </div>
                                  {openOrderedProducts === index && (
                                    <div className="product-dropdown-optionlist p-6">
                                      {orderedProducts.map(
                                        (product, opindex) => {
                                          return (
                                            <div
                                              className={`product-dropdown-option px-12 py-8 flex-column gap-10 cursor-pointer ${
                                                selectedOrdereredProduct[
                                                  index
                                                ] === opindex &&
                                                "order-product-selected"
                                              }`}
                                              key={opindex}
                                              onClick={() =>
                                                orderedProductSelected(
                                                  index,
                                                  opindex,
                                                  productControl,
                                                )
                                              }
                                            >
                                              <div className="flex-row justify-content-space-between">
                                                <div className="flex-row gap-12 align-items-center">
                                                  <div className="radio-btn"></div>
                                                  <p
                                                    className="xetgo-font-tag"
                                                    style={{ color: "#B9B9B9" }}
                                                  >
                                                    <span className="sku-name">
                                                      {product.product_name}
                                                    </span>
                                                    , {product.name}
                                                  </p>
                                                </div>
                                                <p className="xetgo-font-tag">
                                                  CP: ₹ {product.cost_price},
                                                  SP: ₹ {product.selling_price}
                                                </p>
                                              </div>
                                              <p className="xetgo-font-tag">
                                                {product.description}
                                              </p>
                                            </div>
                                          );
                                        },
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="add-purchase-middle-container gap-8"
                                  key={index}
                                >
                                  <FieldControl
                                    name="name"
                                    strict={false}
                                    render={() => (
                                      <span className="form-data flex-column">
                                        <p className="input-label  xetgo-font-tag-bold">
                                          Product Name
                                        </p>
                                        <Filter
                                          option={{
                                            key: "productName",
                                            name: "Product Name",
                                          }}
                                          optionList={product_lists}
                                          multipleSelection={false}
                                          onSelection={(val, _option) => {
                                            productSelected(
                                              productControl,
                                              val[0],
                                            );
                                          }}
                                          mode="showNames"
                                          showPlaceholder={false}
                                          borderStyle="solid"
                                          showId={true}
                                          preSelected={
                                            isNullOrUndefined(
                                              productControl.get("name").value,
                                            )
                                              ? []
                                              : [
                                                  productControl.get("name")
                                                    .value,
                                                ]
                                          }
                                          direction="left"
                                          addNew={false}
                                        />
                                      </span>
                                    )}
                                  />
                                  <span className="form-data flex-column">
                                    <p className="input-label xetgo-font-tag-bold">
                                      Material
                                    </p>
                                    <input
                                      value={
                                        productControl.get("name").value
                                          ?.material || ""
                                      }
                                      className="bg-white text-input px-8 py-4 xetgo-font-tag"
                                      disabled={true}
                                    />
                                  </span>
                                  <span className="form-data flex-column">
                                    <p className="input-label xetgo-font-tag-bold">
                                      SKU Name
                                    </p>
                                    <input
                                      value={
                                        productControl.get("sku_name")?.value ||
                                        ""
                                      }
                                      className="bg-white text-input px-8 py-4 xetgo-font-tag"
                                      disabled={true}
                                    />
                                  </span>
                                  <FieldControl
                                    name="quantity"
                                    render={({ handler }) => (
                                      <span className="form-data flex-column">
                                        <p className="input-label  xetgo-font-tag-bold">
                                          Quantity
                                        </p>
                                        <input
                                          {...handler()}
                                          className="text-input px-8 py-4 xetgo-font-tag"
                                        />
                                      </span>
                                    )}
                                  />
                                  <FieldControl
                                    name="vendor"
                                    strict={false}
                                    render={() => (
                                      <span className="form-data flex-column">
                                        <p className="input-label xetgo-font-tag-bold">
                                          Vendor
                                        </p>
                                        <Filter
                                          option={{
                                            key: "vendor",
                                            name: "Vendor Name",
                                          }}
                                          optionList={vendors}
                                          multipleSelection={false}
                                          onSelection={(val, _option) => {
                                            productControl.patchValue({
                                              vendor: val[0],
                                            });
                                          }}
                                          mode="showNames"
                                          showPlaceholder={false}
                                          borderStyle="solid"
                                          showId={true}
                                          direction="left"
                                          addNew={true}
                                          onAddNew={() =>
                                            setOpenNewVendorForm(true)
                                          }
                                          preSelected={
                                            isNullOrUndefined(
                                              productControl.get("vendor")
                                                ?.value,
                                            )
                                              ? []
                                              : [
                                                  productControl.get("vendor")
                                                    .value,
                                                ]
                                          }
                                        />
                                      </span>
                                    )}
                                  />
                                  <FieldControl
                                    name="vendor_po_number"
                                    render={({ handler }) => (
                                      <span className="form-data flex-column">
                                        <p className="input-label  xetgo-font-tag-bold">
                                          Vendor PO Number
                                        </p>
                                        <input
                                          {...handler()}
                                          className="text-input px-8 py-4 xetgo-font-tag"
                                        />
                                      </span>
                                    )}
                                  />
                                  <FieldControl
                                    name="cost_price"
                                    render={({ handler }) => (
                                      <span className="form-data flex-column">
                                        <p className="input-label  xetgo-font-tag-bold">
                                          Cost Price
                                        </p>
                                        <input
                                          {...handler()}
                                          className="text-input px-8 py-4 xetgo-font-tag"
                                        />
                                      </span>
                                    )}
                                  />
                                  <FieldControl
                                    name="selling_price"
                                    render={({ handler }) => (
                                      <span className="form-data flex-column">
                                        <p className="input-label  xetgo-font-tag-bold">
                                          Selling Price
                                        </p>
                                        <input
                                          {...handler()}
                                          className="text-input px-8 py-4 xetgo-font-tag"
                                        />
                                      </span>
                                    )}
                                  />
                                </div>
                                <FieldControl
                                  name="description"
                                  render={({ handler }) => (
                                    <span className="form-data flex-column gap-6">
                                      <p className="input-label   xetgo-font-tag-bold">
                                        Product Description
                                      </p>
                                      <textarea
                                        {...handler()}
                                        className="product-description px-8 py-4 xetgo-font-tag"
                                        rows={3}
                                      />
                                    </span>
                                  )}
                                />
                              </div>
                            )}
                          />
                        </div>
                      ),
                    )}
                    <span
                      className="addProductBtnContainer flex-row xetgo-font-tag-bold cursor-pointer gap-2"
                      style={{
                        color: "#5151EC",
                      }}
                      onClick={addNewProductHandler}
                    >
                      <img
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                        alt="plus"
                        width={16}
                        height={16}
                      />
                      Add product
                    </span>
                  </div>
                );
              }}
            />
          </>
        )}
      />
      {openNewVendorForm && (
        <div className="new-user-form-container position-relative">
          <NewCompanyForm
            type="VENDOR"
            onCancel={() => setOpenNewVendorForm(false)}
            onAddClient={(val) => addNewVendor(val)}
          />
        </div>
      )}
    </div>
  );
};

export default AddPurchaseProduct;
