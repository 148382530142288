import React, { useEffect, useState } from "react";
import "./AddProduct.scss";
import { FormBuilder, Validators } from "react-reactive-form";
import { useSelector } from "react-redux";
import {
  selectBaseProducts,
  selectClientAddresses,
} from "../../redux/oms/omsSelectors";
import {
  AbstractControl,
  FieldArray,
  FieldControl,
  FieldGroup,
  FormArray,
} from "react-reactive-form";
import Filter from "../oms/Options/Filter/Filter";
import { isNullOrUndefined, round } from "../../utils/Helper";
import { useDispatch } from "react-redux";
import {
  fetchCompanyAddressStart,
  newCompanyAddressStart,
} from "../../redux/oms/omsActions";
import {
  FilterOptionSchema,
  NewCompanyAddressPayloadSchema,
} from "../../redux/oms/omsTypes";
import NewAddressForm from "../oms/NewAddressForm/NewAddressForm";
import CSVReader from "../shared/CSVReader/CSVReader";
import { CSVRow } from "../../models/Task";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";
import QuantityDropdown from "../QuantityDropdown/QuantityDropdown";

interface AddProductProps {
  clientId: number;
  productForm: AbstractControl;
  upload?: boolean;
  onCsvFileAdded?: (file: any) => void;
}

const AddProduct: React.FC<AddProductProps> = ({
  clientId,
  productForm,
  upload = true,
  onCsvFileAdded,
}) => {
  const dispatch = useDispatch();
  const product_lists = useSelector(selectBaseProducts);

  const client_addresses = useSelector(selectClientAddresses);
  const [openNewAddressForm, setOpenNewAddressForm] = useState(false);
  const [openUploadCSV, setOpenUploadCSV] = useState(false);
  const [newUserData, setNewUserData] = useState<{
    name?: string;
    type?: string;
    control?: AbstractControl;
  }>();

  const addNewAddress = (payload: NewCompanyAddressPayloadSchema) => {
    dispatch(newCompanyAddressStart(payload));
    setNewUserData((old) => ({
      ...old,
      name: `${payload.city} - ${payload.line}`,
      type: "ADDRESS",
    }));
    setOpenNewAddressForm(false);
  };

  const addNewProductHandler = () => {
    const productControls = productForm.get("products") as FormArray;
    const addressId = productForm.get("delivery").value.id;
    const control = FormBuilder.group({
      name: ["", Validators.required],
      quantity: ["", Validators.required],
      unit: ["", Validators.required],
      description: ["", Validators.required],
    });
    control.meta = {
      key: `${addressId}-${Math.round(round(Math.random(), 2) * 100)}`,
    };
    productControls.push(control);
  };

  useEffect(() => {
    dispatch(fetchCompanyAddressStart(clientId));
  }, []);

  useEffect(() => {
    const address = client_addresses.filter((val) =>
      val.name.includes(newUserData?.name || ""),
    )[0];
    newUserData?.control?.patchValue({ delivery: address });

    setNewUserData({});
  }, [client_addresses]);

  const parsedData = (data: CSVRow[]) => {
    const result: { [key: number]: AbstractControl } = {};
    data.every((row: CSVRow) => {
      const address = client_addresses.find(
        (val) => val.id === Number(row.address_id),
      );
      const product = product_lists.find(
        (val) => val.id === Number(row.product_id),
      );
      if (!address || !product) {
        toast.error("Invalid CSV values !", toastOptions);
        return false;
      }
      addProductToForm(
        result,
        address,
        product,
        row.description,
        Number(row.quantity),
        row.unit,
        `${address.id}-${Math.round(round(Math.random(), 2) * 100)}`,
      );
      return true;
    });
    const values = Object.values(result);
    if (values.length > 0) {
      const addressForm = productForm.parent as FormArray;
      if (addressForm) {
        for (let i = 0; i < addressForm.length; i++) {
          addressForm.removeAt(0);
        }
        values.forEach((row: AbstractControl, index: number) => {
          row.meta = {
            key: index,
          };
          addressForm.push(row);
        });
      }
    } else {
      toast.error("No Data Found !", toastOptions);
    }
  };

  const addProductToForm = (
    result: any,
    address: FilterOptionSchema,
    product: FilterOptionSchema,
    description: string,
    quantity: number,
    unit: string,
    key: string,
  ) => {
    const productDetail = FormBuilder.group({
      name: [product, Validators.required],
      quantity: [quantity, Validators.required],
      unit: [unit, Validators.required],
      description: [description, Validators.required],
    });
    productDetail.meta = { key };
    if (result[address.id]) {
      const productControls = result[address.id].get("products") as FormArray;
      productControls.push(productDetail);
    } else {
      result[address.id] = FormBuilder.group({
        delivery: [address, Validators.required],
        products: FormBuilder.array([productDetail]),
      });
    }
  };

  const handleQuantityChange = (
    productControl: AbstractControl,
    newQuantity: number,
    measurement: string,
  ) => {
    productControl.patchValue({ quantity: newQuantity });
    productControl.patchValue({ unit: measurement });
  };

  const addingCsvToFileAttachment = (file: any) => {
    if (onCsvFileAdded) {
      onCsvFileAdded(file);
    }
  };

  const deleteProduct = (productList: FormArray, index: number) => {
    productList.removeAt(index);
  };

  return (
    <div className="add-product-form flex-column gap-12">
      <FieldGroup
        control={productForm}
        strict={false}
        render={() => (
          <>
            <FieldControl
              name="delivery"
              strict={false}
              render={() => (
                <span className="form-data flex-column">
                  <div className="flex-row align-items-center justify-content-space-between">
                    <p className=" input-label  xetgo-font-tag-bold">
                      Delivery
                    </p>
                    {!openUploadCSV && upload && (
                      <div
                        className="flex-row gap-2 align-items-center cursor-pointer"
                        onClick={() => setOpenUploadCSV(true)}
                      >
                        <img
                          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                          alt="plus"
                        />
                        <p className="xetgo-font-tag-bold download-template">
                          Upload CSV
                        </p>
                      </div>
                    )}
                  </div>
                  {openUploadCSV && (
                    <CSVReader
                      csvDataUploaded={parsedData}
                      template_link="https://docs.google.com/spreadsheets/d/1x32-MYyy0qoiXcJ3YxU1TVooQxnek2Ds7E3n-ZDYi-U/edit#gid=0"
                      onCsvFileAdded={addingCsvToFileAttachment}
                    />
                  )}
                  <Filter
                    option={{
                      key: "address",
                      name: "Address",
                    }}
                    optionList={client_addresses}
                    multipleSelection={false}
                    onSelection={(val, option) => {
                      productForm.patchValue({
                        delivery: val[0],
                      });
                    }}
                    mode="showNames"
                    showPlaceholder={false}
                    borderStyle="solid"
                    preSelected={
                      isNullOrUndefined(productForm.get("delivery").value)
                        ? []
                        : [productForm.get("delivery").value]
                    }
                    addNew={true}
                    onAddNew={() => {
                      setNewUserData({
                        control: productForm,
                      });
                      setOpenNewAddressForm(true);
                    }}
                  />
                </span>
              )}
            />
            <FieldArray
              name="products"
              strict={false}
              render={(productList) => {
                const { controls } = productList as FormArray;
                return (
                  <div className=" add-product-box flex-column gap-12 p-8 product-list">
                    {controls.map(
                      (productControl: AbstractControl, index: number) => (
                        <div
                          key={`${productControl.meta?.key}-${String(index)}`}
                        >
                          <FieldGroup
                            control={productControl}
                            key={index}
                            render={() => (
                              <div className="flex-column gap-12">
                                <div
                                  className="add-product-middle-container flex-row gap-8 position-relative"
                                  key={index}
                                >
                                  <FieldControl
                                    name="name"
                                    render={({ handler }) => (
                                      <span className="form-data flex-column gap-6">
                                        <p className="input-label  xetgo-font-tag-bold">
                                          Product Name
                                        </p>
                                        <Filter
                                          option={{
                                            key: "productName",
                                            name: "Product Name",
                                          }}
                                          optionList={product_lists}
                                          multipleSelection={false}
                                          onSelection={(val, _option) => {
                                            productControl.patchValue({
                                              name: val[0],
                                            });
                                          }}
                                          mode="showNames"
                                          showPlaceholder={false}
                                          borderStyle="solid"
                                          showId={true}
                                          preSelected={
                                            isNullOrUndefined(
                                              productControl.get("name").value,
                                            )
                                              ? []
                                              : [
                                                  productControl.get("name")
                                                    .value,
                                                  // eslint-disable-next-line no-mixed-spaces-and-tabs
                                                ]
                                          }
                                          direction="left"
                                          addNew={false}
                                        />
                                      </span>
                                    )}
                                  />
                                  <span className="form-data flex-column gap-6">
                                    <p className="input-label xetgo-font-tag-bold">
                                      Material
                                    </p>
                                    <input
                                      value={
                                        productControl.get("name").value
                                          ?.material || ""
                                      }
                                      className="bg-white text-input px-8 py-4 xetgo-font-tag"
                                      disabled={true}
                                    />
                                  </span>
                                  <FieldControl
                                    name="quantity"
                                    render={({ handler }) => (
                                      <span className="form-data flex-column gap-6">
                                        <p className="input-label  xetgo-font-tag-bold">
                                          Quantity
                                        </p>
                                        <QuantityDropdown
                                          value={
                                            productControl.get("quantity").value
                                          }
                                          unit={
                                            productControl.get("unit").value
                                          }
                                          onQuantityChange={(
                                            newQuantity,
                                            newMeasurementUnit,
                                          ) => {
                                            handleQuantityChange(
                                              productControl,
                                              newQuantity,
                                              newMeasurementUnit,
                                            );
                                          }}
                                        />
                                      </span>
                                    )}
                                  />
                                  <img
                                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114095750-trash.svg"
                                    className="delete-product cursor-pointer"
                                    alt="delete"
                                    onClick={() =>
                                      deleteProduct(
                                        productList as FormArray,
                                        index,
                                      )
                                    }
                                  />
                                </div>
                                <FieldControl
                                  name="description"
                                  render={({ handler }) => (
                                    <span className="form-data flex-column gap-6">
                                      <p className="input-label   xetgo-font-tag-bold">
                                        Product Description
                                      </p>
                                      <textarea
                                        {...handler()}
                                        className="product-description px-8 py-4 xetgo-font-tag"
                                        rows={3}
                                      />
                                    </span>
                                  )}
                                />
                              </div>
                            )}
                          />
                        </div>
                      ),
                    )}
                    <span
                      className="addProductBtnContainer flex-row xetgo-font-tag-bold cursor-pointer gap-2"
                      style={{
                        color: "#5151EC",
                      }}
                      onClick={addNewProductHandler}
                    >
                      <img
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                        alt="plus"
                        width={16}
                        height={16}
                      />
                      Add product
                    </span>
                  </div>
                );
              }}
            />
            {openNewAddressForm && (
              <div className="new-user-form-container position-relative">
                <NewAddressForm
                  type="CLIENT"
                  onCancel={() => setOpenNewAddressForm(false)}
                  user_id={clientId}
                  onAddAddress={addNewAddress}
                />
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default AddProduct;
