import React, { useEffect, useState } from "react";
import ApiService from "../../../services/apiService";
import URL from "../../../urls/Urls";
import { TaskFileSchema } from "../../../models/Task";
import { toast } from "react-toastify";
import { toastOptions } from "../../../utils/toast";
import FileList from "../FileList/FileList";
import "./TaskFiles.scss";

interface TaskFilesProps {
  fileList: TaskFileSchema[];
  handleFileUpload: (
    event: React.ChangeEvent<HTMLInputElement>,
    tagValue: string,
  ) => void;
  deleteFile: (file: TaskFileSchema) => void;
}

const TaskFiles: React.FC<TaskFilesProps> = ({
  fileList,
  handleFileUpload,
  deleteFile,
}) => {
  const [files, setFiles] = useState<TaskFileSchema[]>([]);

  useEffect(() => {
    setFiles(fileList);
  }, [fileList]);

  return (
    <div className="task-file-list-container px-16">
      <FileList
        files={files}
        deleteFile={deleteFile}
        handleFileChange={handleFileUpload}
      />
    </div>
  );
};

export default TaskFiles;
