import React, { useEffect, useState } from "react";
import { TaskFileSchema } from "../../../models/Task";
import "./FileList.scss";
import { BADGES } from "../PurchaseOrderDetail/purchaseOrderDetail.data";
import { getFileIcon } from "../../../utils/Helper";

interface FileListProps {
  files: TaskFileSchema[];
  deleteFile: (file: TaskFileSchema) => void;
  handleFileChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    tagValue: string,
  ) => void;
}

const FileList: React.FC<FileListProps> = ({
  files,
  deleteFile,
  handleFileChange,
}) => {
  const [tagSelectedIndex, setTagSelectedIndex] = useState(-1);
  const [chooseFileEnable, setChooseFileEnable] = useState(false);

  const handleAddTag = (index: number) => {
    setTagSelectedIndex(index);
  };

  useEffect(() => {
    setChooseFileEnable(tagSelectedIndex !== -1);
  }, [tagSelectedIndex]);

  return (
    <div className="flex-column gap-6 task-file-container">
      <p className="xetgo-font-tag-bold"> File Attachment</p>
      {files.length > 0 && (
        <div className="flex-row gap-12 ">
          <div className="file-listing flex-column gap-12 full-width">
            {files.map((file: TaskFileSchema, index: number) => {
              return (
                <div
                  style={{ background: "white", height: "40px" }}
                  className="file-card flex-row p-8 justify-content-space-between border-box"
                  key={index}
                >
                  <div className="file-left flex-row gap-16 align-items-center">
                    <img
                      src={getFileIcon(file)}
                      alt="file-icon"
                      height={24}
                      width={24}
                      className="file-preview"
                    />
                    <p className="xetgo-font-tag file-name">{file.name}</p>
                    {BADGES.map(
                      (badge, index) =>
                        file.category === badge.value && (
                          <div
                            key={index}
                            style={{
                              backgroundColor: badge.backgroundColor,
                            }}
                            className="xetgo-font-mini tag-box cursor-pointer px-4 py-2 flex-row justify-content-center align-items-center m-10"
                          >
                            <p
                              style={{
                                color: badge.color,
                              }}
                            >
                              {badge.text}
                            </p>
                          </div>
                        ),
                    )}
                  </div>
                  <div className="flex-row gap-12 align-items-center">
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012114821-download.svg"
                        alt="download"
                        width={12}
                        height={12}
                      />
                    </a>
                    <img
                      className="cursor-pointer"
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012084858-close.svg"
                      alt="close"
                      width={16}
                      height={16}
                      onClick={() => deleteFile(file)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="file-upload-container">
        <div className="flex-column gap-24 " style={{ paddingBottom: "12px" }}>
          <div>
            <div
              style={{ position: "relative" }}
              className=" flex-row align-items-center"
            >
              {tagSelectedIndex !== -1 && (
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: BADGES[tagSelectedIndex].backgroundColor,
                  }}
                  className="xetgo-font-mini tag-box cursor-pointer px-4 py-2 flex-row justify-content-center align-items-center m-10"
                >
                  <p
                    style={{
                      color: BADGES[tagSelectedIndex].color,
                    }}
                  >
                    {BADGES[tagSelectedIndex].text}
                  </p>
                </div>
              )}
              <input
                style={{ background: "white" }}
                className="text-input px-8 py-4 xetgo-font-tag-bold upload-input border-box full-width input-color"
                placeholder={
                  tagSelectedIndex === -1
                    ? "Select tag and then upload file"
                    : ""
                }
                disabled={true}
              />

              {chooseFileEnable ? (
                <input
                  onChange={(event) => {
                    handleFileChange(event, BADGES[tagSelectedIndex].value);
                    setTagSelectedIndex(-1);
                  }}
                  id="fileInputEnabled"
                  name="file"
                  type="File"
                  style={{ display: "none" }}
                  multiple
                />
              ) : (
                <input
                  disabled={true}
                  onChange={(event) => {
                    handleFileChange(event, BADGES[tagSelectedIndex].value);
                    setTagSelectedIndex(-1);
                  }}
                  id="fileInputDisabled"
                  name="file"
                  type="File"
                  style={{ display: "none" }}
                  multiple
                />
              )}

              <label htmlFor="fileInputEnabled">
                <div
                  className={`${
                    chooseFileEnable && "purchase-request-upload-active"
                  } purchase-request-upload-btn xetgo-font-tag-bold  px-16 py-8 border-box cursor-pointer`}
                >
                  Choose File
                </div>
              </label>
            </div>
          </div>
        </div>

        <div className="flex-row gap-12 tags-container details-tag-container px-16 py-7">
          <p className="tagAdd xetgo-font-tag">Add Tags</p>
          {BADGES.map((badge, index) => {
            return (
              <div
                key={index}
                onClick={() => handleAddTag(index)}
                style={{ backgroundColor: badge.backgroundColor }}
                className="xetgo-font-mini tag-box cursor-pointer px-4 py-2 flex-row justify-content-center align-items-center"
              >
                <p style={{ color: badge.color }}>{badge.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FileList;
