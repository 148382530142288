import React, { useEffect, useRef, useState } from "react";
import TaskComment from "../TaskComment/TaskComment";
import {
  DealValueStages,
  TaskFileSchema,
  TaskStatusColorSchema,
} from "../../../models/Task";
import { Tooltip } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { toastOptions } from "../../../utils/toast";
import { useDispatch, useSelector } from "react-redux";
import OutsideAlerter from "../../../utils/OutsideClickDetector";
import AssigneeSelector from "../AssigneeSelector/AssigneeSelector";
import POCDetails from "../POCDetails/POCDetails";
import Loader from "../../home/loader/Loader";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  createPurchaseRequest,
  createTask,
  deleteTask,
  deleteTaskAttachmentStart,
  fetchFilter,
  fetchTaskDetail,
  updateTaskAttachmentsStart,
  updateTaskDetailStart,
} from "../../../redux/oms/omsActions";
import XetImage from "../../shared/xet-image/XetImage";
import TaskFiles from "../TaskFiles/TaskFiles";
import SideNav from "../../shared/sidenav/SideNav";
import { TASK_PRIORITY } from "../../../models/RFQ";
import {
  selectStatusAllowed,
  selectTaskDetail,
  selectTaskDetailFiles,
  selectTaskDetailLoading,
} from "../../../redux/oms/omsSelectors";
import "./TaskDetail.scss";
import SourcingProductCard from "../../SourcingProductCard/SourcingProductCard";
import RFQAddressCard from "../../RFQAddressCard/RFQAddressCard";
import ApiService from "../../../services/apiService";
import URL from "../../../urls/Urls";
import XetModal from "../../shared/xet-modal/XetModal";
import SampleDialog from "../../SampleDialog/SampleDialog";
import SamplingProductCard from "../../SamplingProductCard/SamplingProductCard";
import AddProductDialog from "../../AddProductDialog/AddProductDialog";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import XetEditor from "../../shared/xet-editor/XetEditor";
import { isNullOrUndefined } from "../../../utils/Helper";
import RFQLostReasonDialog from "../../RFQLostReasonDialog/RFQLostReasonDialog";
import NewRFQForm from "../NewRFQForm/NewRFQForm";
import XetDrawer from "../../shared/xet-drawer/XetDrawer";
import { userDetails } from "../../../redux/user/userSelectors";
import environment from "../../../environments/environment";

const BackgroundColors = ["#FADAA4", "#DFE6D2", "#F7F7F7", "#F2BDEF"];

const TaskDetail = () => {
  const dispatch = useDispatch();
  const { id, category, vendor_id } = useParams();
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();
  const details = useSelector(selectTaskDetail);
  const statusAllowed = useSelector(selectStatusAllowed);
  const taskLoading = useSelector(selectTaskDetailLoading);
  const user = useSelector(userDetails);
  const [taskLoaded, setTaskLoaded] = useState(false);
  const [colorSchema, setColorSchema] = useState<{
    background: string;
    color: string;
  }>();
  const [descriptionActive, setDescriptionActive] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const [descriptionTracker, setDescriptionTracker] = useState<string>("");
  const [titleActive, setTitleActive] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [dealValueActive, setDealValueActive] = useState<boolean>(false);
  const [dealValue, setDealValue] = useState<string>("");
  const [statusDropdown, setStatusDropdown] = useState(false);
  const [sampleDialog, setSampleDialog] = useState(false);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [openCreateTaskForm, setOpenCreateTaskForm] = useState(false);
  const [escalated, setEscalate] = useState(false);
  const [openAssigneeSelection, setOpenAssigneeSelection] =
    useState<boolean>(false);

  const [openTaskTracker, setOpenTaskTracker] = useState(false);
  const [openPOCDetails, setOpenPOCDetails] = useState<boolean>(false);
  const [openPriority, setOpenPriority] = useState<boolean>(false);
  const [openCommentSection, setOpenCommentSection] = useState(false);
  const [openRFQLostReasonDialog, setOpenRFQLostReasonDialog] =
    useState<boolean>(false);
  const [taskCategoryStatuses, setTaskCategoryStatuses] = useState<
    { name: string; status: string; id: number }[]
  >([]);
  const files = useSelector(selectTaskDetailFiles);

  const handleAddProduct = () => {
    setOpenAddProduct(true);
  };

  const sales = details?.assignees?.filter((user) => user.role === "SALES");
  const sourcing = details?.assignees?.filter(
    (user) => user.role === "SOURCING",
  );
  const operation = details?.assignees?.filter(
    (user) => user.role === "OPERATIONS",
  );
  const titleRef = useRef<null | HTMLDivElement>(null);
  const dealValueRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (isNullOrUndefined(details) && !taskLoading && taskLoaded) {
      toast.error("Task not available", toastOptions);
      setTimeout(() => {
        navigate(`/eqs/${category}`);
      }, 2000);
    }
  }, [taskLoading, details]);

  useEffect(() => {
    dispatch(fetchFilter("EQS"));
    if (id) {
      dispatch(
        fetchTaskDetail({
          id: parseInt(id, 10),
          category: category || "",
          vendor_id: vendor_id,
        }),
      );
      setTaskLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (details) {
      setColorSchema(TaskStatusColorSchema[details.status]);
      setTitle(details.title);
      setDescription(details.description);
      setDescriptionTracker(details.description);
      setEscalate(details.escalated || false);
      if (titleRef.current) {
        titleRef.current.innerHTML = details.title;
      }
      if (dealValueRef.current) {
        dealValueRef.current.innerHTML = details.deal_value;
      }
    }
  }, [details]);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        navigate(`/eqs/${category}`);
      }
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [category]);

  useEffect(() => {
    setOpenCommentSection(params.get("comment") === "true");
  }, [params]);

  const descriptionChangeHandler = (data: string) => {
    if (!descriptionActive) {
      setDescriptionActive(true);
    }
    setDescriptionTracker(data);
  };

  const saveDescription = async () => {
    if (details) {
      dispatch(
        updateTaskDetailStart({
          id: details.id,
          description: descriptionTracker,
          category: category || "",
        }),
      );
      setDescription(descriptionTracker);
      setDescriptionActive(false);
      toast.success("Description updated successfully!", toastOptions);
    }
  };

  const titleChangeHandler = (event: React.KeyboardEvent) => {
    setTitleActive(true);
    setTitle((event.target as HTMLDivElement).innerHTML);
  };

  const dealValueChangeHandler = (event: React.KeyboardEvent) => {
    setDealValueActive(true);
    setDealValue((event.target as HTMLDivElement).innerHTML);
  };

  const onEnterPress = (e: React.KeyboardEvent) => {
    if (details && e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (titleActive) {
        dispatch(
          updateTaskDetailStart({
            id: details.id,
            title,
            category: category || "",
          }),
        );
        titleRef.current?.blur();
        setTitleActive(false);
        toast.success("Title updated successfully!", toastOptions);
      }
      if (dealValueActive) {
        dispatch(
          updateTaskDetailStart({
            id: details.id,
            deal_value: dealValue,
            category: category || "",
          }),
        );
        dealValueRef.current?.blur();
        setDealValueActive(false);
        toast.success("Deal value updated successfully!", toastOptions);
      }
    }
  };

  const toggleStatus = () => {
    if (details) {
      if ((statusAllowed[details.status] || []).length > 0) {
        setStatusDropdown((old) => !old);
      }
    }
  };

  const handleSampleClose = () => {
    setSampleDialog(false);
  };

  const updateStatus = (id: number, status: string, reason?: string) => {
    if (details) {
      dispatch(
        updateTaskDetailStart({
          id,
          status,
          category: category || "",
          reason: reason,
        }),
      );
      setStatusDropdown(false);
      toast.success("Status updated successfully!", toastOptions);
    }
  };

  const updateTaskStatus = (id: number, status: string) => {
    if (status == "Samples") {
      setSampleDialog(true);
      return;
    }
    if (status == "RFQ Lost") {
      setOpenRFQLostReasonDialog(true);
      return;
    }
    updateStatus(id, status);
  };

  const updateTaskPriority = (priority: string) => {
    if (details) {
      dispatch(
        updateTaskDetailStart({
          id: details.id,
          priority,
          category: category || "",
        }),
      );
      setOpenPriority(false);
      toast.success("Priority updated successfully!", toastOptions);
    }
  };

  const handleDueDateChange = async (dueDate: string | undefined) => {
    if (details) {
      dispatch(
        updateTaskDetailStart({
          id: details.id,
          due_date: dueDate,
          category: category || "",
        }),
      );
      toast.success("Due Date updated successfully!", toastOptions);
    }
  };

  const toggleTaskStatusTracker = async () => {
    if (details?.task_id && !openTaskTracker) {
      const { data } = await ApiService().client.get(
        URL.OMS.GET_TASK_CATEGORY_STATUSES(details?.task_id),
      );
      setTaskCategoryStatuses(data.status_list);
    }
    setOpenTaskTracker((old) => !old);
  };

  const differenceInDays = (date1: string): number => {
    const date2 = dayjs();
    const hours = date2.diff(FormatDate(date1), "hours");
    return Math.floor(hours / 24);
  };

  const FormatDate = (oldDate: string): any => {
    return dayjs(oldDate.split("-").reverse().join("-"));
  };

  const onDeleteTask = () => {
    if (details?.id) {
      confirm("Are you sure you want to delete this RFQ?")
        ? dispatch(deleteTask({ id: details.id }))
        : "";
    }
  };

  const deleteFile = (file: TaskFileSchema) => {
    dispatch(
      deleteTaskAttachmentStart({
        taskId: details?.task_id || -1,
        asset_record_id: file.id,
      }),
    );
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tagValue: string,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      const displayNames = files.map((file) => file.name);
      const formData = new FormData();
      formData.append("category_type", "Rfq");
      formData.append("category", tagValue);
      files.forEach((file, index) => {
        formData.append("files[]", file);
        const displayName = displayNames[index]?.trim();
        if (displayName) {
          formData.append("displayNames[]", displayName);
        }
      });

      dispatch(
        updateTaskAttachmentsStart({
          taskId: details?.task_id || -1,
          attachments: formData,
        }),
      );
    }
  };

  const handleCreateTaskSubmission = async (task: FormData) => {
    if (category === "rfq") {
      dispatch(createTask(task));
    } else {
      dispatch(createPurchaseRequest(task));
    }
    setOpenCreateTaskForm(false);
  };

  const updateURLParameter = (param: string, value: any) => {
    setSearchParams((params) => {
      params.set(param, value);
      if (value == null) {
        params.delete(param);
      }
      return params;
    });
  };

  const handleEscalateToggle = () => {
    try {
      if (details) {
        dispatch(
          updateTaskDetailStart({
            id: details.id,
            escalated: !escalated,
            escalated_by_user_id: user.id,
            escalated_id: details.escalated_id,
            category: category || "",
          }),
        );
      }
      if (!escalated) {
        toast.success("Task Escalated successfully!", toastOptions);
      } else {
        toast.success("Task De-Escalated !", toastOptions);
      }
      setEscalate(!escalated);
    } catch (error) {
      if (!escalated) {
        console.error("Error Escalating Task");
      } else {
        console.error("Task De-Escalated !");
      }
    }
  };

  return (
    <div className="flex-row position-relative">
      <SideNav></SideNav>
      <div className="border-box flex-1 task-detail-section">
        {details == null && <Loader />}
        {details && (
          <>
            <div className="task-details-container">
              <div className="task-title-container full-width flex-row justify-content-space-between align-items-center p-16 border-box">
                <div className="flex-row gap-16 align-items-center flex-1">
                  <div
                    ref={titleRef}
                    className="m-0 xetgo-font-h3 task-title"
                    onKeyDown={onEnterPress}
                    contentEditable={true}
                    onKeyUp={titleChangeHandler}
                  ></div>
                  {DealValueStages.indexOf(details.status) > -1 && (
                    <div className="total-deal-value-container px-8 py-4 flex-row gap-12">
                      <p
                        className="xetgo-font-tag"
                        style={{ color: "#636363" }}
                      >
                        Est. Deal Value
                      </p>
                      <div
                        ref={dealValueRef}
                        className="m-0 xetgo-font-tag deal-value"
                        onKeyDown={onEnterPress}
                        contentEditable={true}
                        onKeyUp={dealValueChangeHandler}
                      ></div>
                      <p
                        className="xetgo-font-tag bolder"
                        style={{ color: "#484848" }}
                      >
                        ₹
                      </p>
                    </div>
                  )}
                  {(details.task_overdue || details.status_overdue) && (
                    <p
                      className={`${
                        details.task_overdue ? "task-overdue" : "status-overdue"
                      } px-10 py-6 flex-row gap-6 align-items-center xetgo-font-tag`}
                    >
                      <span className="dot"></span>
                      {details.task_overdue ? (
                        <>
                          Overdue by{" "}
                          <strong>
                            {differenceInDays(details.due_date_formatted)}
                          </strong>{" "}
                          days
                        </>
                      ) : (
                        <>
                          Due since{" "}
                          <strong>
                            {Math.abs(
                              differenceInDays(details.due_date_formatted),
                            )}
                          </strong>{" "}
                          days
                        </>
                      )}
                      {/* In true statement due_date_formatted should be replaced by status_due_date */}
                    </p>
                  )}
                </div>

                <div className="flex-row gap-32">
                  <OutsideAlerter
                    action={() => setOpenAssigneeSelection(false)}
                  >
                    {
                      <XetModal
                        backdropClose={true}
                        open={sampleDialog}
                        handleClose={handleSampleClose}
                      >
                        <SampleDialog
                          handleClose={handleSampleClose}
                          taskId={details.id}
                          onSampleFormSubmit={updateStatus}
                        />
                      </XetModal>
                    }

                    <div className="flex-column gap-2">
                      <p className="title xetgo-font-tag field-key">
                        Assignees
                      </p>
                      <div
                        className="flex-row justify-content-flex-end position-relative cursor-pointer"
                        onClick={() => setOpenAssigneeSelection(true)}
                      >
                        {sales &&
                          sales.map((user) => {
                            return (
                              <Tooltip
                                title={`SALES - ${user.name}`}
                                key={user.id}
                              >
                                <img
                                  className="assignees"
                                  src={user.avatar}
                                  alt={user.name}
                                  width={22}
                                  height={22}
                                />
                              </Tooltip>
                            );
                          })}
                        {sourcing &&
                          sourcing.map((user) => {
                            return (
                              <Tooltip
                                title={`SOURCING - ${user.name}`}
                                key={user.id}
                              >
                                <XetImage
                                  className="assignees"
                                  src={user.avatar}
                                  alt={user.name}
                                  width={22}
                                  height={22}
                                />
                              </Tooltip>
                            );
                          })}
                        {operation &&
                          operation.map((user) => {
                            return (
                              <Tooltip
                                title={`OPERATION - ${user.name}`}
                                key={user.id}
                              >
                                <XetImage
                                  className="assignees"
                                  src={user.avatar}
                                  alt={user.name}
                                  width={22}
                                  height={22}
                                />
                              </Tooltip>
                            );
                          })}
                        {openAssigneeSelection && (
                          <AssigneeSelector
                            task_id={details.id}
                            assigneeList={details.assignees}
                            roles={["SALES", "SOURCING", "OPERATIONS"]}
                            type={"EQS"}
                          />
                        )}
                      </div>
                    </div>
                  </OutsideAlerter>
                  {user?.role && user.role.indexOf("SOURCING") === -1 && (
                    <OutsideAlerter action={() => setOpenPOCDetails(false)}>
                      <div className="flex-column justify-content-center align-items-center rfq-client">
                        <p className="xetgo-font-tag field-key">Client</p>
                        <div
                          className="xetgo-font-tag flex-row justify-content-center align-items-center gap-4"
                          onClick={() => setOpenPOCDetails(true)}
                        >
                          <span
                            className={`client-type-pill ${
                              details.client_detail.client_type == "NEW"
                                ? "new"
                                : "existing"
                            }`}
                          >
                            {details.client_detail.client_type.toLowerCase()}
                          </span>
                          <Tooltip title={details.client_detail.name}>
                            <span>
                              <XetImage
                                className="assignees"
                                src={details.client_detail.avatar}
                                alt={details.client_detail.name}
                                width={22}
                                height={22}
                              />
                            </span>
                          </Tooltip>
                        </div>
                        {openPOCDetails && (
                          <POCDetails
                            id={details.id}
                            pocs={details.pocs}
                            client_detail={details.client_detail}
                          />
                        )}
                      </div>
                    </OutsideAlerter>
                  )}
                </div>
              </div>
              <div className="task-content-section flex-column border-box justify-content-space-between gap-16">
                <div className="flex-column gap-16">
                  <div className="task-info-container flex-row px-16 py-4 justify-content-space-between">
                    <div className="flex-row align-items-center gap-40">
                      <div className="flex-row gap-16 align-items-center">
                        <OutsideAlerter action={() => setStatusDropdown(false)}>
                          <div className="flex-row gap-2 status-container">
                            <div
                              className="status px-8 py-6 xetgo-font-tag-bold cursor-pointer"
                              style={{
                                background: colorSchema?.background,
                                color: colorSchema?.color,
                              }}
                              onClick={toggleStatus}
                            >
                              {details.status}
                            </div>
                            {statusDropdown && (
                              <div className="p-8 flex-column status-dropdown">
                                {(statusAllowed[details.status] || []).map(
                                  (val) => {
                                    return (
                                      <div
                                        className="option flex-row gap-8 px-12 py-8 xetgo-font-tag-bold align-items-center"
                                        key={val}
                                        onClick={() =>
                                          updateTaskStatus(details.id, val)
                                        }
                                      >
                                        <div
                                          className="round-bg"
                                          style={{
                                            backgroundColor:
                                              TaskStatusColorSchema[val]
                                                .background,
                                          }}
                                        ></div>
                                        {val}
                                      </div>
                                    );
                                  },
                                )}
                              </div>
                            )}
                          </div>
                        </OutsideAlerter>
                        <OutsideAlerter
                          action={() => setOpenTaskTracker(false)}
                          className="task-tracker-container flex-row align-items-center position-relative"
                        >
                          <img
                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231121092301-Progress.svg"
                            alt="tracker"
                            height={22}
                            width={22}
                            className="cursor-pointer"
                            onClick={toggleTaskStatusTracker}
                          />
                          {openTaskTracker && (
                            <div className="flex-column gap-10 px-12 py-8 tracker-popup">
                              {taskCategoryStatuses.map((val, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="tracker-option flex-row justify-content-space-between align-items-center"
                                  >
                                    {val.id ? (
                                      <a
                                        href={`/eqs/${val.name.toLowerCase()}/${
                                          val.id
                                        }`}
                                        rel="noopener noreferrer"
                                        className="xetgo-font-tag-bold cursor-pointer"
                                        style={{
                                          textDecoration: "unset",
                                          color: "#000000",
                                        }}
                                      >
                                        {"//"} {val.name} {"//"}
                                      </a>
                                    ) : (
                                      <p className="xetgo-font-tag-bold">
                                        {"//"} {val.name} {"//"}
                                      </p>
                                    )}
                                    <div
                                      className="status px-8 py-6 xetgo-font-tag-bold"
                                      style={{
                                        background:
                                          TaskStatusColorSchema[val.status]
                                            ?.background,
                                        color:
                                          TaskStatusColorSchema[val.status]
                                            ?.color,
                                        borderRadius: "6px",
                                      }}
                                    >
                                      {val.status}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </OutsideAlerter>

                        <span
                          className="clone-task"
                          title="Clone Task"
                          onClick={() => setOpenCreateTaskForm(true)}
                        >
                          <img
                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103081029-duplicate-outline.svg"
                            alt="copy"
                            className="cursor-pointer"
                            width={16}
                            height={16}
                          />
                        </span>

                        {details.status === "RFQ Generated" && (
                          <img
                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103081029-trash-outline.svg"
                            alt="delete"
                            className="cursor-pointer"
                            width={16}
                            height={16}
                            onClick={onDeleteTask}
                          />
                        )}
                      </div>
                      <div className="flex-column gap-2">
                        <p className="title xetgo-font-tag field-key">
                          Task due on
                        </p>
                        <div className="due-value xetgo-font-tag">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={dayjs(
                                details.due_date_formatted
                                  .split("-")
                                  .reverse()
                                  .join("-"),
                              )}
                              disablePast
                              format={"DD-MM-YYYY"}
                              views={["year", "month", "day"]}
                              onChange={(event) =>
                                handleDueDateChange(
                                  event?.toDate().toISOString(),
                                )
                              }
                            />
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div className="flex-column justify-content-center">
                        <p className="xetgo-font-tag field-key">
                          Task created by
                        </p>
                        <p
                          className="xetgo-font-tag"
                          style={{ color: "#bcbcbc" }}
                        >
                          <span
                            className="xetgo-font-tag-bold"
                            style={{ color: "#484848" }}
                          >
                            {details.creator_name}{" "}
                          </span>
                          on {details.start_date_formatted}
                        </p>
                      </div>
                    </div>
                    <div className="flex-row gap-16 align-items-center">
                      <div className="flex-row align-items-center gap-12">
                        <OutsideAlerter action={() => setOpenPriority(false)}>
                          <div className="priority-container flex-row align-items-center">
                            <img
                              className="priority-flag cursor-pointer"
                              src={
                                details.priority === TASK_PRIORITY[0].name
                                  ? TASK_PRIORITY[0].avatar
                                  : details.priority === TASK_PRIORITY[1].name
                                  ? TASK_PRIORITY[1].avatar
                                  : TASK_PRIORITY[2].avatar
                              }
                              alt="priority"
                              onClick={() => setOpenPriority((old) => !old)}
                            />
                            {openPriority && (
                              <div className="p-8 flex-column priority-dropdown">
                                {TASK_PRIORITY.map((row, index) => {
                                  return (
                                    <div
                                      key={index}
                                      onClick={() =>
                                        updateTaskPriority(row.name)
                                      }
                                      className="option flex-row gap-8 px-12 py-8 xetgo-font-tag-bold align-items-center"
                                    >
                                      <img
                                        src={row.avatar}
                                        width={16}
                                        alt={row.name}
                                      />
                                      {row.name}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </OutsideAlerter>
                        <p className="task-uuid xetgo-font-tag">
                          XGPQ{90000 + details.task_id}
                        </p>
                      </div>
                      <div
                        onClick={handleEscalateToggle}
                        className={`escalate-toggle-main-container flex-row align-items-center gap-4 px-8 py-4 xetgo-font-tag bold cursor-pointer ${
                          escalated && "escalate-active "
                        }`}
                      >
                        <p
                          className={`gradient-circle ${
                            escalated && "gradient-circle-escalate"
                          }`}
                        ></p>
                        <p>Escalate</p>
                      </div>
                    </div>
                  </div>
                  <div className="description-container px-16">
                    <XetEditor
                      value={description || ""}
                      defaultHeight="248px"
                      onChange={descriptionChangeHandler}
                      showSaveContent={descriptionActive}
                      onSaveContent={saveDescription}
                    />
                  </div>
                </div>
                <div className="flex-column gap-16">
                  <div className=" flex-column gap-12">
                    {details.product_details.length === 0 ? (
                      <div className="product-listing-container p-12 flex-column gap-12 justify-content-space-between">
                        <span
                          className="flex-row xetgo-font-tag-bold cursor-pointer gap-2"
                          style={{
                            color: "#5151EC",
                            width: "fit-content",
                          }}
                          onClick={handleAddProduct}
                        >
                          <img
                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                            alt="plus"
                            width={16}
                            height={16}
                          />
                          Add product
                        </span>
                      </div>
                    ) : (
                      details.product_details.map((row, index) => (
                        <div
                          className="product-listing-container p-12 flex-column gap-12"
                          style={{
                            backgroundColor: BackgroundColors[index % 4],
                          }}
                          key={index}
                        >
                          {category === "rfq" ? (
                            <RFQAddressCard
                              taskId={details.task_id}
                              clientId={details.client_detail.id}
                              address={row}
                              status={details.status}
                            />
                          ) : category === "sourcing" ||
                            category === "quotation" ? (
                            <SourcingProductCard
                              products={row.products}
                              address={row}
                              key={index}
                              category={category || ""}
                              category_id={details.id}
                              assigneeName={
                                details.assignees.find(
                                  (assignee) => assignee.role === "SALES",
                                )?.name || ""
                              }
                            />
                          ) : (
                            <SamplingProductCard
                              products={row.products}
                              address={row}
                              key={index}
                            />
                          )}
                        </div>
                      ))
                    )}
                  </div>
                  <TaskFiles
                    fileList={files || []}
                    handleFileUpload={handleFileChange}
                    deleteFile={deleteFile}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>

      <XetDrawer
        open={openCreateTaskForm}
        handleClose={() => setOpenCreateTaskForm(false)}
        position="right"
      >
        <NewRFQForm
          onSubmit={handleCreateTaskSubmission}
          onClose={() => setOpenCreateTaskForm(false)}
          preFilledData={details}
        />
      </XetDrawer>

      {!openCommentSection && (
        <div
          className="comment-container-open flex-row align-items-center justify-content-center"
          onClick={() => {
            updateURLParameter("comment", "true");
            setOpenCommentSection(true);
          }}
        >
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240117070437-chatbubbles.svg"
            alt="chat-icon"
          />
        </div>
      )}
      {openCommentSection && details && (
        <div className="comment-floating-section">
          <OutsideAlerter
            action={() => {
              updateURLParameter("comment", null);
              setOpenCommentSection(false);
            }}
          >
            <TaskComment
              task_id={details.task_id}
              handleClose={() => {
                updateURLParameter("comment", null);
                setOpenCommentSection(false);
              }}
            />
          </OutsideAlerter>
        </div>
      )}
      {details && (
        <XetModal
          backdropClose={false}
          open={openAddProduct}
          handleClose={() => setOpenAddProduct(false)}
        >
          <AddProductDialog
            handleClose={() => setOpenAddProduct(false)}
            taskId={details.task_id}
            clientId={details.client_detail.id}
          />
        </XetModal>
      )}
      {details && (
        <XetModal
          backdropClose={false}
          open={openRFQLostReasonDialog}
          handleClose={() => setOpenRFQLostReasonDialog(false)}
        >
          <RFQLostReasonDialog
            handleClose={() => setOpenRFQLostReasonDialog(false)}
            taskId={details.id}
            reasonSubmitted={updateStatus}
          />
        </XetModal>
      )}
      <ToastContainer position="top-right" />
    </div>
  );
};

export default TaskDetail;
