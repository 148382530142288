import React from "react";
import "./CommentCard.scss";
import { getFileIcon } from "../../../../utils/Helper";

interface CommentCardProps {
  avatar: string;
  name: string;
  timestamp: string;
  description: string;
  commentFiles?: {
    name: string;
    url: string;
    asset_record_id: number;
    task_comment_id: number;
  }[];
}

const CommentCard: React.FC<CommentCardProps> = ({
  avatar,
  name,
  timestamp,
  description,
  commentFiles,
}) => {
  return (
    <div className="flex-row gap-8 comment-card full-width">
      <img
        src={avatar}
        alt="avatar"
        width={20}
        height={20}
        className="border-round"
      ></img>
      <div className="flex-column gap-4 flex-1  ">
        <div className="flex-row gap-8">
          <p className="xetgo-font-tag-bold user">{name}</p>
          <p className="xetgo-font-mini timestamp flex-row gap-4 align-items-center">
            <span className="dot" />
            {timestamp}
          </p>
        </div>
        <div
          className="xetgo-font-tag description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
        {commentFiles &&
          commentFiles.map((commentFile, index) => (
            <div
              style={{ background: "white", height: "40px" }}
              className="file-card flex-row p-8 justify-content-space-between border-box"
              key={index}
            >
              <div className="file-left flex-row gap-8 align-items-center">
                <img
                  src={getFileIcon(commentFile)}
                  alt="file-icon"
                  height={16}
                  width={16}
                  className="file-preview"
                />
                <span style={{ color: "#E1E1E1" }}>|</span>
                <p
                  style={{ color: "#4A4A4A" }}
                  className="xetgo-font-tag-bolder file-name"
                >
                  {commentFile.name}
                </p>
              </div>
              <div className="flex-row gap-12 align-items-center">
                <a
                  href={commentFile.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240118110822-download-outline.svg"
                    alt="download"
                    width={16}
                    height={16}
                  />
                </a>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default CommentCard;
