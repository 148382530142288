import { createSelector } from "reselect";
import { RootState } from "../store";
import { OMSState } from "./omsReducer";
import { FilterKeys, FilterOptionSchema } from "./omsTypes";
import { has_role } from "../../utils/Helper";

// Non-memoized selector function to access the user state from the Redux store
const selectFilterState = (state: RootState): OMSState => state.oms;

// Memoized selector using createSelector
export const selectFilter = createSelector(
  [selectFilterState],
  (filterState) => filterState,
);

export const selectTaskDetailLoading = createSelector(
  [selectFilterState],
  (filterState) => filterState.detailsLoading,
);

export const selectTaskDetailFiles = createSelector(
  [selectFilterState],
  (filterState) => filterState.detail?.assets,
);

export const selectAllFilterOptions = createSelector(
  [selectFilterState],
  (filterState) => {
    return {
      assignees: filterState.assignees,
      clients: filterState.clients,
      vendors: filterState.vendors,
      client_type: filterState.client_type,
    };
  },
);

export const selectFilterOptions = createSelector(
  [selectFilterState, (state, category) => category],
  (filterState, category: FilterKeys) => filterState[category],
);

export const selectVendorMap = createSelector(
  [selectFilterState],
  (filterState) => {
    const map: { [key: number]: FilterOptionSchema } = {};
    filterState.vendors.forEach((vendor: FilterOptionSchema) => {
      map[vendor.id] = vendor;
    });
    return map;
  },
);

export const selectStatusAllowed = createSelector(
  [selectFilterState],
  (filterState) => filterState.status_allowed,
);

// SELECTORS FOR ASSIGNEES
export const selectAssignees = createSelector(
  [selectFilterState],
  (filterState) => filterState.assignees,
);

export const selectBaseProducts = createSelector(
  [selectFilterState],
  (filterState) => filterState.products,
);

export const selectSalesAssignees = createSelector(
  [selectAssignees],
  (assignees) =>
    assignees.filter(
      (assignee) =>
        has_role(assignee, "SALES") || has_role(assignee, "SALES_ADMIN"),
    ),
);

export const selectOperationsAssignees = createSelector(
  [selectAssignees],
  (assignees) =>
    assignees.filter(
      (assignee) =>
        has_role(assignee, "OPERATIONS") ||
        has_role(assignee, "OPERATION_ADMIN"),
    ),
);

export const selectSourceAssignees = createSelector(
  [selectAssignees],
  (assignees) =>
    assignees.filter(
      (assignee) =>
        has_role(assignee, "SOURCING") || has_role(assignee, "SOURCING_ADMIN"),
    ),
);

export const selectUserList = createSelector(
  [selectFilterState, (state, search) => search],
  (filterState, search: string) =>
    filterState.assignees.filter(
      (row) =>
        row.name.toLowerCase().startsWith(search.toLowerCase()) ||
        search === "",
    ),
);

//SELECTORS FOR TASKS
export const selectFiltersApplied = createSelector(
  [selectFilterState],
  (rfqState) => rfqState.selected,
);

export const selectSearchFilter = createSelector(
  [selectFilterState],
  (rfqState) => rfqState.search,
);

export const selectClientAddresses = createSelector(
  [selectFilterState],
  (filterState) => filterState.client_addresses,
);

export const selectClientPocs = createSelector(
  [selectFilterState],
  (filterState) => filterState.client_pocs,
);

export const selectTasks = createSelector(
  [selectFilterState],
  (filterState) => filterState.tasks,
);

export const selectTaskDetail = createSelector(
  [selectFilterState],
  (filterState) => filterState.detail,
);

export const selectTaskClientDetail = createSelector(
  [selectFilterState],
  (filterState) => filterState.detail?.client_detail,
);

export const selectTaskComments = createSelector(
  [selectFilterState],
  (filterState) => filterState.detail?.comments || [],
);

export const selectTaskStatuses = createSelector(
  [selectFilterState],
  (filterState) => filterState.statuses,
);

// SELECTORS FOR PURCHASE ORDERS
export const selectPurchaseOrderDetail = createSelector(
  [selectFilterState],
  (filterState) => filterState.po_detail,
);

export const selectPurchaseOrderComments = createSelector(
  [selectFilterState],
  (filterState) => filterState.po_detail?.comments || [],
);

export const selectOrderedProducts = createSelector(
  [selectFilterState],
  (filterState) => filterState.po_detail?.ordered_products || [],
);

export const selectFilteredTasks = createSelector(
  [selectTasks, selectFiltersApplied, selectSearchFilter],
  (allTasks, filtersApplied, search) => {
    let taskList = [...allTasks];
    Object.keys(filtersApplied).forEach((key) => {
      const filters = filtersApplied[key as FilterKeys].map((row) => row.name);
      if (filters.length > 0) {
        if (key === "assignees") {
          taskList = taskList.filter((task) =>
            task.assignees.some((r) => filters.indexOf(r.name) >= 0),
          );
        } else if (key === "client_type") {
          taskList = taskList.filter(
            (task) =>
              filters.indexOf(task.client.client_type.toUpperCase()) >= 0,
          );
        } else if (key === "clients") {
          taskList = taskList.filter(
            (task) => filters.indexOf(task.client.client_name) >= 0,
          );
        } else if (key == "vendors") {
          taskList = taskList.filter((task) =>
            task.vendors.some((r) => filters.indexOf(r.name) >= 0),
          );
        }
      }
    });
    if (search !== "") {
      taskList = taskList.filter(
        (task) =>
          (task.po_number == null &&
            task.task_id.toString().indexOf(search.toLowerCase()) > -1) ||
          task.title.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          (task.po_number &&
            task.po_number?.toLowerCase().indexOf(search.toLowerCase()) > -1) ||
          (task.vendors.length > 0 &&
            task.vendors.some(
              (vendor) =>
                vendor.po_number &&
                vendor.po_number.toLowerCase().includes(search.toLowerCase()),
            )),
      );
    }
    return taskList;
  },
);
