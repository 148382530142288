import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseAuthConfig = {
  apiKey: "AIzaSyDRGSGveLhQKJJZFYomutRvzvKzJAf02d0",
  authDomain: "xetool.firebaseapp.com",
  projectId: "xetool",
  storageBucket: "xetool.appspot.com",
  messagingSenderId: "285607414843",
  appId: "1:285607414843:web:f62f3883426f4d5e56e01e",
};

const app = initializeApp(firebaseAuthConfig);
export const auth = getAuth(app);
