import { StyleSheet, Font } from "@react-pdf/renderer";

Font.register({
  family: `Montserrat`,
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Regular.ttf",
      fontWeight: 400,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Medium.ttf",
      fontWeight: 500,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-SemiBold.ttf",
      fontWeight: 600,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-Bold.ttf",
      fontWeight: 700,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-MediumItalic.ttf",
      fontStyle: "italic",
      fontWeight: 500,
    },
    {
      src: "https://cdn.jsdelivr.net/npm/@typopro/web-montserrat@3.7.5/TypoPRO-Montserrat-BoldItalic.ttf",
      fontStyle: "italic",
      fontWeight: 700,
    },
  ],
});

export const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 16,
    fontSize: 6,
    fontFamily: "Montserrat",
  },

  section: {
    flexGrow: 1,
    border: "0.5px solid black",
  },

  // ------------ HEADER SECTION STYLES ----------------- //

  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  imageContainer: {
    padding: "0 12px",
    height: "100%",
    borderRight: "0.5px solid black",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  image: {
    width: 35,
    height: 35,
  },

  headerRightSection: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
  },

  headerRightUpperSection: {
    borderBottom: "0.5px solid black",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  titleSection: {
    padding: 4,
    height: "100%",
    textAlign: "center",
    flex: 1.3,
  },

  title: {
    fontSize: 16,
    color: "#1E1A55",
    fontWeight: 500,
  },

  addressSection: {
    display: "flex",
    flexDirection: "column",
    borderLeft: "0.5px solid black",
    height: "100%",
    flex: 0.7,
  },

  address: {
    borderBottom: "0.5px solid black",
    fontSize: 8,
    flex: 1,
    padding: 2,
  },

  cinNumber: {
    fontSize: 8,
    flex: 1,
    padding: 2,
  },

  headerRightLowerSection: {
    padding: "6 4",
  },

  headerRightLowerSectionText: {
    fontSize: 7,
    display: "flex",
    flexWrap: "wrap",
  },

  // ---------------------------------------------------- //

  // ------------ CLIENT SECTION STYLES ----------------- //

  clientAndXetgoSection: {
    display: "flex",
    flexDirection: "column",
  },

  clientSectionHeader: {
    backgroundColor: "#1E1A55",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 4,
  },

  clientInfoHeaderText: {
    color: "white",
    fontSize: 9,
    fontWeight: "bold",
  },

  xetgoInfoHeaderText: {
    color: "white",
    fontSize: 9,
    fontWeight: "bold",
    marginRight: 60,
  },

  clientInfoContent: {
    display: "flex",
    flexDirection: "row",
  },

  clientSection: {
    flex: 1,
    padding: 4,
    display: "flex",
    flexDirection: "column",
    gap: 2,
    borderBottom: "0.5px solid black",
    fontSize: 8,
  },

  clientAddress: {},

  xetgoSection: {
    flex: 1,
    borderLeft: "0.5px solid black",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  xetgoSectionRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "0.5px solid black",
    flex: 1,
  },

  rowLabel: {
    flex: 0.5,
    fontSize: 8,
    padding: "1px 2px",
    borderRight: "0.5px solid black",
    height: "100%",
  },

  rowValue: {
    flex: 0.5,
    fontSize: 8,
    padding: "1px 2px",
    height: "100%",
  },

  // ----------------------------------------------------- //

  // ------------ PROJECT INFO SECTION STYLES ------------ //

  projectInfo: {
    display: "flex",
    flexDirection: "column",
  },

  projectInfoHeader: {
    backgroundColor: "#1E1A55",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 4,
  },

  projectInfoHeaderText: {
    color: "white",
    fontSize: 9,
    fontWeight: "bold",
  },

  projectInfoContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  projectInfoDescription: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "0.5px solid black",
  },

  // ------------ CLIENT INFO SECTION STYLES ------------ //

  productDetails: {
    marginTop: "10px",
  },

  productDetailHeader: {
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderBottomColor: "#000",
    backgroundColor: "#1E1A55",
    justifyContent: "center",
    alignItems: "center",
  },

  productDetailHeaderCell: {
    fontSize: 8,
    fontWeight: "bold",
    borderRight: "0.2px solid white",
    color: "white",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: 2,
  },

  productDetailContent: {
    display: "flex",
    flexDirection: "column",
  },

  productDetailRow: {
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderBottomColor: "#000",
  },

  productDetailCell: {
    fontSize: 8,
    borderRight: "0.5px solid #000",
    color: "#000",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: 2,
  },

  totalCell: {
    color: "#000059",
    fontSize: 10,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRight: "0.5px solid #000",
    height: "100%",
  },

  productDetailContentSummary: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    borderBottom: "0.5px solid black",
  },

  rupeeSymbolImage: {
    height: "6px",
  },

  summaryText: {
    flex: 1,
    backgroundColor: "#1E1A55",
    color: "white",
    padding: "4 8",
    fontSize: 10,
    fontWeight: "bold",
    borderRight: "0.5px solid black",
    height: "100%",
  },

  totalRupeeSymbolImage: {
    width: 9,
    height: 9,
  },

  totalPrice: {
    flex: 0.1,
    padding: 4,
    borderRight: "0.5px solid black",
    height: "100%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  },

  totalGst: {
    flex: 0.1,
    padding: 4,
    borderRight: "0.5px solid black",
    height: "100%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  },

  totalPriceWithGst: {
    flex: 0.1,
    padding: 4,
    height: "100%",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  },
  detailEndText: {
    fontSize: 8,
  },

  termsAndConditionSection: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    padding: 4,
    marginTop: 20,
    borderTop: "0.5px solid black",
  },

  termsAndConditionPoint: {
    display: "flex",
    flexDirection: "column",
    gap: 6,
  },

  termsAndConditionLabel: {
    color: "#1E1A55",
    textDecoration: "underline",
    fontSize: 9,
    fontWeight: "bold",
  },

  termsAndConditionValue: {
    fontSize: 8,
  },
});
