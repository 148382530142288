import { FilterOptionSchema } from "../redux/oms/omsTypes";

export const MATERIAL_ID: { [key: string]: string } = {
  PAPER: "PA",
  PLASTIC: "PL",
  METAL: "MT",
  WOOD: "WD",
  CLOTH_TEXTILE: "TX",
  BIODEGRADABLE: "BD",
  GLASS: "GL",
};

export const generateSKUName = (product: FilterOptionSchema): string => {
  if (product != null) {
    const uuid = product.code;
    const material = product.material;
    if (material && uuid) {
      return `${MATERIAL_ID[material]}/${uuid}`;
    }
  }
  return "";
};
