import { UserActions, UserActionTypes } from "./userTypes";
import {
  JWT_TOKEN,
  removeCookie,
  setCookie,
} from "../../services/cookieService";

export interface UserState {
  user: any;
  loading: boolean;
  error: string | null | undefined;
  newNotification: boolean;
}

const initialState: UserState = {
  user: null,
  loading: true,
  error: null,
  newNotification: false,
};

const userReducer = (
  state = initialState,
  action: UserActionTypes,
): UserState => {
  switch (action.type) {
    case UserActions.FETCH_USER:
      return {
        ...state,
        loading: true,
      };
    case UserActions.FETCH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
        error: null,
      };
    case UserActions.FETCH_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case UserActions.SET_USER:
      if (action.payload && action.payload.jwtToken !== undefined) {
        setCookie(JWT_TOKEN, action.payload.jwtToken);
      }
      return {
        ...state,
        user: action.payload,
      };
    case UserActions.LOGOUT_USER:
      removeCookie(JWT_TOKEN);
      return {
        ...state,
        user: null,
      };
    case UserActions.TOGGLE_NEW_NOTIFICATION:
      return {
        ...state,
        newNotification: !state.newNotification,
      };
    default:
      return state;
  }
};

export default userReducer;
