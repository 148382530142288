import React, { ChangeEvent, useEffect, useState } from "react";
import "./SamplingProductCard.scss";
import { ProductDetailSchema, TaskProductDetails } from "../../models/Task";
import OutsideAlerter from "../../utils/OutsideClickDetector";
import { useDispatch } from "react-redux";
import { round } from "../../utils/Helper";
import { updateCoa } from "../../redux/oms/omsActions";

interface ProductCardProps {
  products: ProductDetailSchema[];
  address: TaskProductDetails;
}

const SamplingProductCard: React.FC<ProductCardProps> = ({
  products,
  address,
}) => {
  const dispatch = useDispatch();
  const [productsList, setProductsList] = useState<ProductDetailSchema[]>([]);
  const [productAddressInfoVisibleIndex, setProductAddressVisibleIndex] =
    useState(-1);

  useEffect(() => {
    setProductsList(products);
  }, [products]);

  const toggleAddressInfoVisible = (index: number) => {
    if (productAddressInfoVisibleIndex === -1) {
      setProductAddressVisibleIndex(index);
    }
    if (productAddressInfoVisibleIndex === index) {
      setProductAddressVisibleIndex(-1);
    }
  };

  const outInfoClickDetect = (index: number) => {
    if (productAddressInfoVisibleIndex === index) {
      setProductAddressVisibleIndex(-1);
    }
  };

  const handleFileChange = (
    event: ChangeEvent<HTMLInputElement>,
    tpm_id: number,
  ) => {
    const fileInput = event.target;
    if (fileInput.files && fileInput.files.length > 0) {
      const formData = new FormData();
      formData.append("tpm_id", tpm_id.toString());
      formData.append("files[]", fileInput.files[0]);
      formData.append("displayNames[]", fileInput.files[0].name);
      dispatch(updateCoa(formData));
    }
  };

  return (
    <>
      {productsList.map((product, pindex) => {
        return (
          <div key={pindex} className="flex-column gap-12">
            <div className="sampling-product-card-container flex-row justify-content-space-between gap-12">
              <div className="sampling-mid-title-container flex-row align-items-center gap-4">
                <p className="product-name xetgo-font-tag">{product.name},</p>
                <span className="material-name xetgo-font-tag">
                  {product.material}
                </span>
                <span className="city-name xetgo-font-tag">
                  ({address.address_city})
                </span>
                <OutsideAlerter
                  action={() => outInfoClickDetect(pindex)}
                  className="flex-row align-items-center position-relative"
                  dependency={[productAddressInfoVisibleIndex]}
                >
                  <img
                    onClick={() => {
                      toggleAddressInfoVisible(pindex);
                    }}
                    className="sampling-info-btn"
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231113123425-information-circle.svg"
                    alt=""
                  />
                  {productAddressInfoVisibleIndex === pindex && (
                    <>
                      <div className="sampling-info-container flex-column gap-16 xetgo-font-tag p-16 ">
                        <div className="flex-column gap-8">
                          <div className="flex-row gap-8 align-items-start">
                            <img
                              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231113152941-file-box.svg"
                              alt=""
                            />
                            <div className="flex-column">
                              <div className="flex-row gap-8">
                                <p className="xetgo-font-tag-bolder visible-title-color">
                                  {product.name}
                                </p>
                                <span className="visible-subTitle-color xetgo-font-tag visible-title-color">
                                  {product.material.toLowerCase()},{" "}
                                  {product.quantity} U
                                </span>
                              </div>
                              <p
                                className="xetgo-font-tag"
                                style={{ color: "#5B5B5B" }}
                              >
                                {product.description}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="flex-row gap-8">
                          <img
                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231113153116-truck.svg"
                            alt=""
                          />
                          <p className="xetgo-font-tag-bolder visible-title-color">
                            {address.address_city}
                            <span
                              className="xetgo-font-tag"
                              style={{ color: "#A8A8AE", marginLeft: "8px" }}
                            >
                              {address.address_line}
                            </span>
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </OutsideAlerter>
              </div>
              <div className="sampling-mid-right-input-containers flex-row gap-8 align-items-center">
                <div className="disabled-box-container flex-row gap-8">
                  <p className="xetgo-font-tag">Quantity</p>
                  <p className="xetgo-font-bolder">
                    {product.quantity} <span>U</span>{" "}
                  </p>
                </div>

                <div className="disabled-box-container flex-row gap-8">
                  <p className="xetgo-font-tag">Final Price</p>
                  <p className="xetgo-font-bolder">
                    {round(Number(product.final_price), 2) || ""}
                  </p>
                  <span className="xetgo-font-bolder">
                    {product.final_price ? "₹" : "-"}
                  </span>
                </div>

                <div className="disabled-box-container flex-row gap-8">
                  <p className="xetgo-font-tag">Client Target Price</p>
                  <p className="xetgo-font-bolder">
                    {round(Number(product.client_target_price), 2) || ""}
                  </p>
                  <span className="xetgo-font-bolder">
                    {product.client_target_price ? "₹" : "-"}
                  </span>
                </div>

                <div className="coa-upload-container flex-row justify-content-center align-items-center">
                  <input
                    type="file"
                    id={"select-file-" + product.name.toString()}
                    className="select-file-input"
                    onChange={(event) =>
                      handleFileChange(event, product.tpm_id)
                    }
                  />
                  <span
                    className={
                      "coa-preview flex-row align-items-center justify-content-space-between " +
                      (product.coa_details?.name ? "file-selected" : "")
                    }
                  >
                    <span className="file-name">
                      {product.coa_details?.name
                        ? product.coa_details?.name
                        : "No file chosen"}
                    </span>

                    {product.coa_details?.url && (
                      <img
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231123122600-download.svg"
                        alt="save"
                        className="save-button cursor-pointer"
                        onClick={() => window.open(product.coa_details?.url)}
                      />
                    )}
                  </span>

                  <label
                    htmlFor={"select-file-" + product.name.toString()}
                    className="select-file-label flex-row align-items-center justify-content-center cursor-pointer"
                  >
                    Choose COA
                  </label>
                </div>
              </div>
            </div>

            <div className="sample-details-container flex-column">
              {product.sample_details?.map((sample_detail, index) => {
                return (
                  <div
                    key={index}
                    className="flex-row justify-content-space-between align-items-center"
                  >
                    <span className="sample-name">
                      SAMPLE {" " + (index + 1)}{" "}
                    </span>
                    <span className="sample-address">{sample_detail} </span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SamplingProductCard;
