import React, { useEffect, useState } from "react";
import "./SampleDialog.scss";
import { useDispatch } from "react-redux";
import { sampleAddressStart } from "../../redux/oms/omsActions";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import { SampleProduct } from "../../redux/oms/omsTypes";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";

interface SampleDialogProps {
  handleClose: () => void;
  taskId: number;
  onSampleFormSubmit: (taskId: number, status: string) => void;
}

const SampleDialog: React.FC<SampleDialogProps> = ({
  handleClose,
  taskId,
  onSampleFormSubmit,
}) => {
  const [addAddresses, setAddAddresses] = useState<{ [key: number]: string[] }>(
    {},
  );
  const [products, setProducts] = useState<SampleProduct[]>([]);
  const dispatch = useDispatch();

  const fetchProducts = async () => {
    try {
      const response = await ApiService().client.get(
        URL.OMS.GET_PRODUCTS(taskId),
      );
      const val: SampleProduct[] = response.data.data;
      setProducts(val);
      const data: { [key: number]: string[] } = {};
      val.forEach((product) => {
        data[product.tpm_id] = [
          `${product.address_line},${product.city}, ${product.state}, ${product.country}, ${product.pincode}`,
        ];
      });
      setAddAddresses(data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };
  const handleProductSubmit = () => {
    let totalAddress = 0;
    const sampleAddressesWithTpm = Object.keys(addAddresses).map(
      (tpmId: any) => {
        const trimmedAddresses = addAddresses[tpmId].map((address: string) =>
          address.trim(),
        );
        const cleanedAddresses = trimmedAddresses.filter(Boolean);
        totalAddress += cleanedAddresses.length;
        return {
          tpmId: tpmId,
          address: cleanedAddresses,
        };
      },
    );
    if (totalAddress === 0) {
      toast.error("Fields cannot be empty !!", toastOptions);
      return;
    }
    const sampleAddressFormData = new FormData();
    sampleAddressFormData.append("task_id", taskId.toString());
    sampleAddressFormData.append(
      "data",
      JSON.stringify(sampleAddressesWithTpm),
    );
    dispatch(sampleAddressStart(sampleAddressFormData));
    onSampleFormSubmit(taskId, "Samples");
    handleClose();
  };

  const handleIncreaseLength = (tpmId: number) => {
    setAddAddresses((prevAddresses) => ({
      ...prevAddresses,
      [tpmId]: [...(prevAddresses[tpmId] || []), ""],
    }));
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div className="add-sample-container flex-column gap-8 p-12 xetgo-font-tag-bold">
      <h3 className="xetgo-font-button-bolder m-0">Add Sample Details</h3>
      <div className="addsample-box-container">
        {products.map((product, index) => (
          <div className="add-sample-box p-8 flex-column gap-8" key={index}>
            <div className="flex-column gap-2">
              <div className="flex-row gap-16">
                <p className="add-sample-title xetgo-font-button-bold">
                  {product.name}
                </p>
                <p className="sampling-city-line xetgo-font-tag-bold flex-row gap-8">
                  <span className="sampling-product-city">{product.city}</span>{" "}
                  <span>{product.address_line}</span>
                </p>
              </div>
              <p className="description xetgo-font-tag">
                {product.description}
              </p>
            </div>
            <div className="flex-column gap-2">
              <p className="description xetgo-font-tag">Address </p>
              {addAddresses[product.tpm_id]?.map((address, index) => (
                <div
                  className="flex-row justify-content-space-between gap-8"
                  key={index}
                >
                  <input
                    className="sample-address-input border-box flex-1 px-4 py-8 gap-10 xetgo-font-tag-bold "
                    placeholder="Add Address"
                    value={address}
                    onChange={(e) => {
                      const newAddresses = { ...addAddresses };
                      newAddresses[product.tpm_id][index] = e.target.value;
                      setAddAddresses(newAddresses);
                    }}
                  />
                  <img
                    className="cursor-pointer"
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114095750-trash.svg"
                    alt="trash"
                    onClick={() => {
                      const newAddresses = { ...addAddresses };
                      newAddresses[product.tpm_id].splice(index, 1);
                      setAddAddresses(newAddresses);
                    }}
                  />
                </div>
              ))}
            </div>
            <div>
              <span
                className="addProductBtnContainer flex-row xetgo-font-tag-bold cursor-pointer gap-2"
                style={{
                  color: "#5151EC",
                }}
                onClick={() => {
                  handleIncreaseLength(product.tpm_id);
                }}
              >
                <img
                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                  alt="plus"
                  width={16}
                  height={16}
                />
                Add Address
              </span>
            </div>
          </div>
        ))}
      </div>
      <div className="add-product-modal-btns-container justify-content-end flex-row gap-12 new-form-btn-container">
        <div onClick={handleClose} className="cancel-btn xetgo-font-tag">
          Cancel
        </div>
        <div onClick={handleProductSubmit} className="add-btn xetgo-font-tag">
          Done
        </div>
      </div>
    </div>
  );
};

export default SampleDialog;
