import React, { useEffect, useState } from "react";
import { FieldArray, FieldGroup, FormArray } from "react-reactive-form";
import {
  MULTI_ADDRESS_FORM,
  NEW_ADDRESS_FORM,
  NEW_PRODUCT_FORM,
} from "../../models/RFQ";
import { useDispatch } from "react-redux";
import AddProduct from "../addProduct/AddProduct";
import "./AddProductDialog.scss";
import { AddTaskProductStart } from "../../redux/oms/omsActions";
import { AbstractControl } from "react-reactive-form";
interface AddProductDialogProps {
  handleClose: () => void;
  taskId: number;
  clientId: number;
}

const AddProductDialog: React.FC<AddProductDialogProps> = ({
  handleClose,
  taskId,
  clientId,
}) => {
  const dispatch = useDispatch();
  const [productForm, _setProductForm] =
    useState<AbstractControl>(MULTI_ADDRESS_FORM);

  const handleProductSubmit = () => {
    const formDataObject: any = [];
    const addresses = productForm.get("address") as FormArray;
    addresses.controls.forEach((control) => {
      const data = {
        delivery: control.get("delivery").value,
        products: control.get("products").value,
      };
      formDataObject.push(data);
    });
    const productFormData = new FormData();
    productFormData.append("id", taskId.toString());
    productFormData.append("category_type", "Rfq");
    productFormData.append("address", JSON.stringify(formDataObject));
    dispatch(AddTaskProductStart(productFormData));
    resetForm();
    handleClose();
  };

  const closeProductDialog = () => {
    resetForm();
    handleClose();
  };

  const resetForm = () => {
    NEW_PRODUCT_FORM.reset();
    NEW_ADDRESS_FORM.reset();
    MULTI_ADDRESS_FORM.reset();
    const addresses = MULTI_ADDRESS_FORM.get("address") as FormArray;
    for (let i = 1; i <= addresses.length - 1; i++) {
      addresses.removeAt(i);
      addresses.controls.forEach((control) => {
        const products = control.get("products") as FormArray;
        for (let i = 1; i <= products.length - 1; i++) {
          products.removeAt(i);
        }
      });
    }
  };

  const addNewAddressHandler = () => {
    const addressControls = productForm.get("address") as FormArray;
    const index = addressControls.length + 1;
    const control = NEW_ADDRESS_FORM;
    control.meta = {
      key: index,
    };
    addressControls.push(control);
  };

  return (
    <div className="add-product-container flex-column">
      <div className="add-product-form-section">
        <FieldGroup
          control={productForm}
          strict={false}
          render={() => (
            <FieldArray
              name="address"
              strict={false}
              render={(addressList) => {
                const { controls } = addressList as FormArray;
                return (
                  <div className="flex-column gap-12">
                    {controls.map(
                      (addressControl: AbstractControl, index: number) => (
                        <div
                          key={`${addressControl.meta.key}-${String(index)}`}
                        >
                          <AddProduct
                            productForm={addressControl}
                            clientId={clientId}
                          />
                        </div>
                      ),
                    )}
                    <span
                      className="flex-row xetgo-font-tag-bold cursor-pointer gap-2"
                      style={{
                        color: "#5151EC",
                        width: "fit-content",
                      }}
                      onClick={addNewAddressHandler}
                    >
                      <img
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                        alt="plus"
                        width={16}
                        height={16}
                      />
                      Add one more delivery address
                    </span>
                  </div>
                );
              }}
            />
          )}
        />
      </div>
      <div className="add-product-modal-btns-container justify-content-end flex-row gap-12 new-form-btn-container">
        <div onClick={closeProductDialog} className="cancel-btn xetgo-font-tag">
          Cancel
        </div>
        <div onClick={handleProductSubmit} className="add-btn xetgo-font-tag">
          Add product
        </div>
      </div>
    </div>
  );
};

export default AddProductDialog;
