import React, { useEffect, useState } from "react";
import SideNav from "../sidenav/SideNav";
import "./XetNotification.scss";
import {
  NotificationPreferences,
  NotificationSchema,
  NotificationTabsData,
  NotificationTypeTags,
} from "../../../models/NotificationSchema";
import ApiService from "../../../services/apiService";
import URL from "../../../urls/Urls";
import { useNavigate } from "react-router-dom";
import SocketService from "../../../services/socketService";
import { User } from "../../../models/User";
import { useSelector } from "react-redux";
import { userDetails } from "../../../redux/user/userSelectors";
import { TaskStatusColorSchema } from "../../../models/Task";
import XetNotificationPreference from "../xet-notification-preference/XetNotificationPreference";
import { formatNotificationStampDate } from "../../../utils/date-helper";
import { toast } from "react-toastify";
import { toastOptions } from "../../../utils/toast";
import XetImage from "../xet-image/XetImage";

const XetNotification: React.FC = () => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("All");
  const [selectedNotificationType, setSelectedNotificationType] =
    useState<string>("");
  const [notificationList, setNotificationList] = useState<
    NotificationSchema[]
  >([]);
  const [filteredNotificationList, setFilteredNotificationList] =
    useState<NotificationSchema[]>(notificationList);
  const [openMoreOptionIndex, setOpenMoreOptionIndex] = useState(-1);
  const [openPreferenceSetting, setOpenPreferenceSetting] = useState(false);
  const [notificationPreferences, setNotificationPreferences] =
    useState<NotificationPreferences>();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const user: User | null | undefined = useSelector(userDetails);

  useEffect(() => {
    const socket = SocketService.getInstance().getSocket();
    const notificationSound = new Audio(
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240121174730-mixkit-correct-answer-tone-2870.wav",
    );

    socket.on("ADMIN_NOTIFICATIONS", (data: never) => {
      setFilteredNotificationList((prevList) => {
        return [data["message"], ...prevList];
      });
      toast.info("New notification", toastOptions);
      try {
        notificationSound.play().then((r) => {
          return r;
        });
      } catch (e) {
        console.error("ERROR PLAYING NOTIFICATION SOUND", e);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    fetchNotificationsList(currentPage, selectedNotificationType).then((r) => {
      return r;
    });
    fetchNotificationPreferences().then((r) => {
      return r;
    });
  }, [currentPage, selectedNotificationType]);

  const fetchNotificationsList = async (
    page: number,
    notification_type: string,
  ) => {
    const response = await ApiService().client.get(
      URL.USERS.FETCH_NOTIFICATIONS,
      {
        params: {
          page,
          notification_type,
        },
      },
    );

    setNotificationList(response.data["notifications"]);
    setFilteredNotificationList(response.data["notifications"]);
    setTotalPages(response.data["total_pages"]);

    if (page > response.data["total_pages"]) {
      setCurrentPage(response.data["total_pages"]);
    }
  };

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  const fetchNotificationPreferences = async () => {
    const response = await ApiService().client.get(
      URL.USERS.FETCH_NOTIFICATION_PREFERENCES,
    );
    const data = response.data["preferences"];
    setNotificationPreferences(data);
  };

  const handleTabClick = (tabName: string, notificationType: string) => {
    setSelectedTab(tabName);
    setCurrentPage(1);
    setSelectedNotificationType(notificationType);
  };

  const handleNotificationStatus = async (
    status: string,
    notificationId: number,
  ) => {
    try {
      const response = await ApiService().client.put(
        URL.USERS.UPDATE_NOTIFICATION_STATUS(notificationId),
        {
          read: status === "read",
        },
      );

      if (response.status === 200) {
        setFilteredNotificationList((prevNotificationList) => {
          return prevNotificationList.map((notification) => {
            if (notification.id === notificationId) {
              return {
                ...notification,
                read: status === "read",
              };
            }
            return notification;
          });
        });
        setOpenMoreOptionIndex(-1);
      } else {
        console.error("Failed to update notification status");
      }
    } catch (error) {
      console.error("Error updating notification status:", error);
    }
  };

  const notificationClicked = (
    event: React.MouseEvent,
    notification: NotificationSchema,
  ) => {
    event.stopPropagation();
    if (!notification.read) {
      handleNotificationStatus("read", notification.id);
    }
    navigate(notification.redirection);
  };

  return (
    <div className="notifications-page flex-row">
      <SideNav></SideNav>
      <div className="notifications-wrapper flex-column">
        <div className="notifications-header p-16">
          <span className="inbox-heading flex-row">
            Inbox
            <span className="unread-notification-count">
              {
                notificationList.filter((notification) => !notification.read)
                  .length
              }
            </span>
          </span>
        </div>

        <div className="notification-list-container">
          <div className="notification-type-tabs-container flex-row">
            {NotificationTabsData.map((tabData, index) => {
              return (
                <span
                  key={index}
                  className={`notification-type-tab cursor-pointer${
                    selectedTab === tabData.tab_name ? " selected-tab" : ""
                  }`}
                  onClick={() => {
                    handleTabClick(tabData.tab_name, tabData.notification_type);
                  }}
                >
                  {tabData.tab_name}
                </span>
              );
            })}

            <span
              className="preference-setting-button"
              onClick={() => setOpenPreferenceSetting(!openPreferenceSetting)}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240119123320-settings-2.svg"
                alt=""
              />
            </span>

            <XetNotificationPreference
              openPreferenceSetting={openPreferenceSetting}
              notificationPreferences={notificationPreferences}
            />
          </div>

          {/* Notification card */}

          {filteredNotificationList.map((notification, index) => {
            return (
              <div
                key={index}
                className="notification-tile flex-row"
                style={notification.read ? { background: "#F9F9F9" } : {}}
                onClick={(event) => {
                  notificationClicked(event, notification);
                }}
              >
                {notification.miscellaneous_details &&
                notification.miscellaneous_details.creator_avatar ? (
                  <div className="user-avatar-container">
                    <img
                      src={notification.miscellaneous_details?.creator_avatar}
                      width={32}
                      height={32}
                      alt="profile"
                    />
                  </div>
                ) : (
                  <XetImage
                    alt={notification.miscellaneous_details.creator_name || ""}
                    height={32}
                    width={32}
                  />
                )}

                <div className="notification-tile-content flex-column">
                  <span className="user-name">
                    <span>
                      {notification.miscellaneous_details?.creator_name}
                    </span>

                    <span
                      className="notification-type"
                      style={{
                        color:
                          NotificationTypeTags[notification.notification_type]
                            .color,
                        background:
                          NotificationTypeTags[notification.notification_type]
                            .background,
                      }}
                    >
                      {
                        NotificationTypeTags[notification.notification_type]
                          .displayName
                      }
                    </span>
                  </span>

                  <span className="notification-title">
                    {notification.title}
                  </span>

                  <span className="notification-description">
                    {notification.notification_type === "STATUS_UPDATE" && (
                      <div className="status-update-content flex-row">
                        <span
                          className="previous-status"
                          style={{
                            background:
                              TaskStatusColorSchema[
                                notification.miscellaneous_details["old_status"]
                              ].background,
                            color:
                              TaskStatusColorSchema[
                                notification.miscellaneous_details["old_status"]
                              ].color,
                            textTransform: "capitalize",
                          }}
                        >
                          {notification.miscellaneous_details["old_status"]}
                        </span>

                        <img
                          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240119120158-arrow-right.svg"
                          alt=""
                        />

                        <span
                          className="next-status"
                          style={{
                            background:
                              TaskStatusColorSchema[
                                notification.miscellaneous_details["new_status"]
                              ].background,
                            color:
                              TaskStatusColorSchema[
                                notification.miscellaneous_details["new_status"]
                              ].color,
                            textTransform: "capitalize",
                          }}
                        >
                          {notification.miscellaneous_details["new_status"]}
                        </span>
                      </div>
                    )}
                    {notification.notification_type === "TASK_ASSIGNED" && (
                      <div className="status-update-content flex-row">
                        <span
                          className="next-status"
                          style={{
                            background:
                              TaskStatusColorSchema[
                                notification.miscellaneous_details["new_status"]
                              ].background,
                            color:
                              TaskStatusColorSchema[
                                notification.miscellaneous_details["new_status"]
                              ].color,
                            textTransform: "capitalize",
                          }}
                        >
                          {notification.miscellaneous_details["new_status"]}
                        </span>
                      </div>
                    )}

                    {(notification.notification_type === "TAG" ||
                      notification.notification_type === "COMMENT") && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: notification.content,
                        }}
                      />
                    )}
                  </span>

                  <span className="notification-time-stamp">
                    {formatNotificationStampDate(notification.created_at)}
                  </span>
                </div>

                <div
                  className="more-options"
                  onClick={(event) => {
                    event.stopPropagation();
                    openMoreOptionIndex === index
                      ? setOpenMoreOptionIndex(-1)
                      : setOpenMoreOptionIndex(index);
                  }}
                >
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240119121234-more-dots-vertical.svg"
                    alt="more"
                  />
                </div>

                {openMoreOptionIndex === index && (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                    className="open-more-option flex-column p-4"
                  >
                    <span
                      className="option p-4"
                      onClick={() =>
                        handleNotificationStatus("read", notification.id)
                      }
                    >
                      Mark as Read
                    </span>
                    <span
                      className="option p-4"
                      onClick={() =>
                        handleNotificationStatus("unread", notification.id)
                      }
                    >
                      Mark as Unread
                    </span>
                  </div>
                )}
              </div>
            );
          })}
        </div>

        <div className="pagination-container flex-row align-items-center ">
          {Array.from({ length: totalPages }, (_, index) => index + 1).map(
            (page) => (
              <span
                key={page}
                className={`pagination-number xetgo-font-tag-bold cursor-pointer${
                  currentPage === page ? " active-page" : ""
                }`}
                onClick={() => handlePageClick(page)}
              >
                {page}
              </span>
            ),
          )}
        </div>
      </div>
    </div>
  );
};

export default XetNotification;
