import React, { useState } from "react";
import "./RFQLostReasonDialog.scss";
import Filter from "../oms/Options/Filter/Filter";
import { RFQ_LOST_REASONS } from "../../models/RFQ";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";
import { FilterOptionSchema } from "../../redux/oms/omsTypes";

interface RFQLostReasonDialogProps {
  handleClose: () => void;
  taskId: number;
  reasonSubmitted: (taskId: number, status: string, reason: string) => void;
}

const RFQLostReasonDialog: React.FC<RFQLostReasonDialogProps> = ({
  handleClose,
  taskId,
  reasonSubmitted,
}) => {
  const [reason, setReason] = useState<FilterOptionSchema>();

  const handleReason = () => {
    if (reason == null) {
      toast.warning("Please select reason!!", toastOptions);
    } else {
      reasonSubmitted(taskId, "RFQ Lost", reason.name);
      handleClose();
    }
  };

  return (
    <div className="add-sample-container flex-column gap-8 p-12 xetgo-font-tag-bold">
      <h3 className="xetgo-font-button-bolder m-0">Add reason for the loss?</h3>
      <Filter
        option={{ key: "reason", name: "Reason" }}
        optionList={RFQ_LOST_REASONS}
        multipleSelection={false}
        onSelection={(val, option) => {
          setReason(val[0]);
        }}
        preSelected={reason ? [reason] : []}
        mode="showNames"
        showPlaceholder={false}
        borderStyle="solid"
        addNew={false}
      />
      <div className="add-product-modal-btns-container justify-content-end flex-row gap-12 new-form-btn-container">
        <div onClick={handleClose} className="cancel-btn xetgo-font-tag">
          Cancel
        </div>
        <div onClick={handleReason} className="add-btn xetgo-font-tag">
          Done
        </div>
      </div>
    </div>
  );
};

export default RFQLostReasonDialog;
