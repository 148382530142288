export interface NotificationSchema {
  id: number;
  title: string;
  user_name: string;
  content: string;
  miscellaneous_details: any;
  notification_type: string;
  read: boolean;
  redirection: string;
  created_at: string;
  source_type: string;
  source_id: number;
}

export interface NotificationPreferences {
  tag_notifications_enabled: boolean;
  task_status_notifications_enabled: boolean;
  email_notifications_enabled: boolean;
  push_notifications_enabled: boolean;
}

export const NotificationTypeTags: {
  [key: string]: { displayName?: string; background: string; color: string };
} = {
  TAG: {
    displayName: "Tagged",
    background: "#E0FFD5",
    color: "#228500",
  },
  STATUS_UPDATE: {
    displayName: "Status Changed",
    background: "#FFBEC8",
    color: "#8D0016",
  },
  COMMENT: {
    displayName: "Comment",
    background: "#FFD1F6",
    color: "#88006D",
  },
  TASK_ASSIGNED: {
    displayName: "Task Assigned",
    background: "#DBEBFF",
    color: "#438CEB",
  },
};

export const NotificationTabsData = [
  {
    tab_name: "All",
    notification_type: "",
  },
  {
    tab_name: "Tags",
    notification_type: "TAG",
  },
  {
    tab_name: "Status Change",
    notification_type: "STATUS_UPDATE",
  },
  {
    tab_name: "Comment",
    notification_type: "COMMENT",
  },
  {
    tab_name: "Task Assigned",
    notification_type: "TASK_ASSIGNED",
  },
];

export const NOTIFICATION_PREFERENCES_FORM = {
  tag_notifications_enabled: false,
  task_status_notifications_enabled: false,
  email_notifications_enabled: false,
  push_notifications_enabled: false,
};
