import "./App.scss";
import "./helpers/_helperClasses.scss";
import { Suspense, useEffect, useState } from "react";
import AvailableRoutes from "./utils/AvailableRoutes";
import ApiService from "./services/apiService";
import Loader from "./components/home/loader/Loader";
import SocketService from "./services/socketService";
import { User } from "./models/User";
import { useSelector } from "react-redux";
import { userDetails } from "./redux/user/userSelectors";
import { toast } from "react-toastify";
import { toastOptions } from "./utils/toast";
import { toggleNewNotification } from "./redux/user/userActions";
import { useDispatch } from "react-redux";
import { isNotNullOrUndefined } from "./utils/Helper";

function App() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user: User | null | undefined = useSelector(userDetails);

  useEffect(() => {
    const socket = SocketService.getInstance().getSocket();
    const notificationSound = new Audio(
      "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240121174730-mixkit-correct-answer-tone-2870.wav",
    );

    socket.on("connect", function () {
      if (isNotNullOrUndefined(user?.id)) {
        socket.emit("CONFIG", user);
      }
    });

    socket.on("ADMIN_NOTIFICATIONS", (_data: never) => {
      dispatch(toggleNewNotification());
      toast.info("New notification", toastOptions);
      try {
        notificationSound
          .play()
          .then((r) => {
            return r;
          })
          .catch((e) => {
            console.log("ERROR PLAYING NOTIFICATION SOUND", e);
          });
      } catch (e) {
        console.log("ERROR PLAYING NOTIFICATION SOUND", e);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const subscription = ApiService().loadingSubject.subscribe((res) => {
      setLoading(res);
    });
    return () => subscription.unsubscribe();
  }, []);

  return (
    <div>
      {loading && <Loader></Loader>}
      <Suspense fallback={<div>Loading...</div>}>
        <AvailableRoutes />
      </Suspense>
    </div>
  );
}

export default App;
