import React, { useEffect, useRef, useState } from "react";
import "./PurchaseOrderProducts.scss";
import XetModal from "../../shared/xet-modal/XetModal";
import AddPurchaseProductDialog from "../../AddPurchaseProductDialog/AddPurchaseProductDialog";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import {
  DeliverySchedule,
  PurchaseProduct,
  PurchaseProductDetails,
} from "../../../models/Task";
import { useDispatch } from "react-redux";
import {
  addProductDeliveryScheduleStart,
  deleteProductDeliveryScheduleStart,
  deletePurchaseRequestVendor,
  fetchPurchaseRequestComment,
  updateDeliveryDetailsStart,
  updatePurchaseOrderProductStart,
} from "../../../redux/oms/omsActions";
import { useSelector } from "react-redux";
import { selectVendorMap } from "../../../redux/oms/omsSelectors";
import { formatDate } from "../../../utils/Helper";
import { useParams, useSearchParams } from "react-router-dom";
import OutsideAlerter from "../../../utils/OutsideClickDetector";
import TaskComment from "../TaskComment/TaskComment";
import { userDetails, userRoles } from "../../../redux/user/userSelectors";

interface PurchaseOrderProductsProps {
  productDetails: PurchaseProductDetails[];
  purchaseRequestId: number;
}
const BackgroundColors = ["#FADAA4", "#DFE6D2", "#F7F7F7", "#F2BDEF"];

const PurchaseOrderProducts: React.FC<PurchaseOrderProductsProps> = ({
  productDetails,
  purchaseRequestId,
}) => {
  const dispatch = useDispatch();
  const { category } = useParams();
  const roles = useSelector(userRoles);
  const vendorMap = useSelector(selectVendorMap);
  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [deliveryOpen, setDeliveryOpen] = useState<string>("");
  const [deliveryDate, setDeliveryDate] = useState<string>("");
  const [openCommentSection, setOpenCommentSection] = useState(-1);
  const [newDeliverySchedule, setNewDeliverySchedule] = useState<string>("");
  const quantityRef = useRef<null | HTMLDivElement>(null);
  const [params, setSearchParams] = useSearchParams();

  useEffect(() => {
    const index = parseInt(params.get("comment") || "-1");
    setOpenCommentSection(index);
    if (index !== -1) {
      dispatch(
        fetchPurchaseRequestComment({
          po_id: purchaseRequestId,
          vendor_id: index,
        }),
      );
    }
  }, [params]);

  const updateURLParameter = (param: string, value: any) => {
    setSearchParams((params) => {
      params.set(param, value);
      if (value === -1 || value == null) {
        params.delete(param);
      }
      return params;
    });
  };

  const handleAddProduct = () => {
    setOpenAddProduct(true);
  };

  const handleDueDateChange = async (dueDate: string | undefined) => {
    if (dueDate) {
      toggleDeliveryDateSelector("");
      setDeliveryDate(formatDate(dueDate));
    }
  };

  const toggleDeliveryDateSelector = (
    key: string,
    type?: "expected" | "actual" | "new",
  ) => {
    if (deliveryOpen === `${type}-${key}`) {
      setDeliveryOpen("");
    } else {
      setDeliveryOpen(`${type}-${key}`);
    }
  };

  const submitNewSchedule = (prtpm_id: number) => {
    if (quantityRef.current && deliveryDate !== "") {
      const formData = new FormData();
      formData.append("prtpm_id", prtpm_id.toString());
      formData.append("quantity", quantityRef.current.innerHTML);
      formData.append("expected_delivery_date", deliveryDate);
      dispatch(addProductDeliveryScheduleStart(formData));
      setNewDeliverySchedule("");
    }
  };

  const deleteSchedule = (id: number) => {
    dispatch(deleteProductDeliveryScheduleStart(id));
  };

  const onEnterPress = (
    e: React.KeyboardEvent,
    key: "description",
    prtpm_id: number,
    vendor_id: number,
  ) => {
    const value = (e.target as HTMLInputElement).value;
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      dispatch(
        updatePurchaseOrderProductStart({
          id: purchaseRequestId,
          prtpm_id: prtpm_id,
          [key]: value,
          vendor_id,
        }),
      );
    }
  };

  const updateDeliveryDetails = (
    key:
      | "delivered_quantity"
      | "expected_delivery_date"
      | "track_link"
      | "actual_delivery_date",
    delivery_id: number,
    prtpm_id: number,
    vendor_id: number,
    value: string,
  ) => {
    dispatch(
      updateDeliveryDetailsStart({
        id: delivery_id,
        prtpm_id,
        vendor_id,
        key,
        value,
      }),
    );
    setDeliveryOpen("");
  };

  const deletePurchaseVendor = (po_id: number, vendor_id: number) => {
    dispatch(deletePurchaseRequestVendor({ po_id, vendor_id }));
  };

  return (
    <>
      <div>
        {productDetails.map(
          (vendors: PurchaseProductDetails, vendorIndex: number) => (
            <div
              key={vendorIndex}
              className="purchase-order-card-container p-12"
              style={{
                backgroundColor: BackgroundColors[vendorIndex % 4],
              }}
            >
              <div className="po-upper-product-headings flex-row justify-content-space-between align-items-center">
                <p className="xetgo-font-tag-bolder po-product-name">
                  {vendorMap[vendors.vendor_id]?.name}
                </p>
                <div className="flex-row gap-12 align-items-center">
                  <span
                    className="flex-row xetgo-font-tag-bold cursor-pointer gap-2"
                    style={{
                      color: "#5151EC",
                      width: "fit-content",
                    }}
                    onClick={handleAddProduct}
                  >
                    <img
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                      alt="plus"
                      width={16}
                      height={16}
                    />
                    Add product
                  </span>
                  <div className="po-number-container py-4 px-12">
                    <p className="xetgo-font-button-bolder">
                      {vendors.vendor_po_number}
                    </p>
                  </div>
                  <div
                    className="product-comment-container-open flex-row align-items-center justify-content-center"
                    onClick={() => {
                      updateURLParameter("comment", vendors.vendor_id);
                    }}
                  >
                    <img
                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240117070437-chatbubbles.svg"
                      alt="chat-icon"
                      width={18}
                      height={18}
                    />
                  </div>
                  {category === "sales-order" && (
                    <div
                      className="vendor-delete-container flex-row align-items-center justify-content-center"
                      onClick={() => {
                        deletePurchaseVendor(
                          vendors.purchase_request_id,
                          vendors.vendor_id,
                        );
                      }}
                    >
                      <img
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114095750-trash.svg"
                        alt="delete"
                        width={18}
                        height={18}
                        className="cursor-pointer"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="purchase-order-all-products-container flex-column gap-12">
                {vendors.products.map(
                  (product: PurchaseProduct, productIndex: number) => (
                    <div
                      key={productIndex}
                      className="purchase-order-product p-8 flex-column gap-12"
                    >
                      <div className="flex-row align-items-center justify-content-space-between">
                        <div className="flex-row gap-8">
                          <p className="xetgo-font-tag-bolder">
                            {product.name} ,
                          </p>
                          <span
                            style={{ color: "#969696" }}
                            className="xetgo-font-tag"
                          >
                            {product.material}
                          </span>
                          <img
                            className="po-info-btn"
                            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103125647-information-circle.svg"
                            alt="info-btn"
                          />
                        </div>
                        <div className="flex-row gap-16">
                          <div className="price-box-container flex-row py-4 px-8 gap-12 xetgo-font-tag">
                            <p>Quantity</p>
                            <p
                              style={{ color: "#484848" }}
                              className="xetgo-font-tag-bolder"
                            >
                              {product.quantity} <span> pcs</span>{" "}
                            </p>
                          </div>
                          <div className="price-box-container flex-row py-4 px-8 gap-12 xetgo-font-tag">
                            <p>Cost Price</p>
                            <p
                              style={{ color: "#484848" }}
                              className="xetgo-font-tag-bolder"
                            >
                              {product.cost_price} <span> ₹</span>
                            </p>
                          </div>
                          <div className="price-box-container flex-row py-4 px-8 gap-12 xetgo-font-tag">
                            <p>Selling Price</p>
                            <p
                              style={{ color: "#484848" }}
                              className="xetgo-font-tag-bolder"
                            >
                              {product.selling_price} <span> ₹</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <textarea
                        key={product.prtpm_id}
                        className="purchase-order-product-text-input p-8 border-box"
                        placeholder="SKU/Description"
                        defaultValue={product.description}
                        rows={2}
                        onKeyDown={(e) => {
                          onEnterPress(
                            e,
                            "description",
                            product.prtpm_id,
                            vendors.vendor_id,
                          );
                        }}
                      />
                      <div className="delivery-container flex-column gap-12 p-8">
                        <div className="flex-row justify-content-space-between">
                          <p className="xetgo-font-tag-bolder">Delivery</p>
                          <span
                            className="flex-row xetgo-font-tag-bold cursor-pointer gap-2"
                            style={{
                              color: "#5151EC",
                              width: "fit-content",
                            }}
                            onClick={() =>
                              setNewDeliverySchedule(
                                `${vendorIndex}-${productIndex}`,
                              )
                            }
                          >
                            <img
                              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                              alt="plus"
                              width={16}
                              height={16}
                            />
                            Add Delivery Details
                          </span>
                        </div>
                        {product.delivery_schedules.map(
                          (
                            delivery: DeliverySchedule,
                            scheduleIndex: number,
                          ) => {
                            return (
                              <div
                                className="po-detail-delivery-detail-container px-8 gap-18"
                                key={scheduleIndex}
                              >
                                <div className="flex-row price-box-container justify-content-space-between  xetgo-font-tag px-8 py-4">
                                  <p>Delivered Quantity</p>
                                  <div
                                    style={{ color: "#484848" }}
                                    className="xetgo-font-tag-bolder delivery-input"
                                    contentEditable={true}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter" && !e.shiftKey) {
                                        e.preventDefault();
                                        updateDeliveryDetails(
                                          "delivered_quantity",
                                          delivery.id,
                                          delivery.prtpm_id,
                                          vendors.vendor_id,
                                          (e.target as HTMLDivElement)
                                            .innerHTML,
                                        );
                                      }
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        delivery.delivered_quantity?.toString(),
                                    }}
                                  ></div>
                                  <p className="xetgo-font-tag-bolder"> U</p>
                                </div>
                                <div className="flex-row price-box-container justify-content-space-between  xetgo-font-tag px-8 py-4">
                                  <p>Expected Delivery Date</p>
                                  <div
                                    style={{ color: "#484848" }}
                                    className="xetgo-font-tag-bolder flex-row align-items-center gap-12"
                                  >
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DatePicker
                                        value={dayjs(
                                          delivery.expected_delivery_date,
                                        )}
                                        format={"DD-MM-YYYY"}
                                        views={["year", "month", "day"]}
                                        onChange={(event) =>
                                          updateDeliveryDetails(
                                            "expected_delivery_date",
                                            delivery.id,
                                            delivery.prtpm_id,
                                            vendors.vendor_id,
                                            event?.toDate().toISOString() || "",
                                          )
                                        }
                                        open={
                                          deliveryOpen ===
                                          `expected-${delivery.id.toString()}`
                                        }
                                        className="delivery-date-selector"
                                      />
                                    </LocalizationProvider>
                                    <img
                                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103135613-calender+check+blue.svg"
                                      onClick={() =>
                                        toggleDeliveryDateSelector(
                                          delivery.id.toString(),
                                          "expected",
                                        )
                                      }
                                      className="calendar-icon cursor-pointer"
                                      height={16}
                                      width={16}
                                      alt=""
                                    />
                                  </div>
                                </div>
                                {roles.indexOf("DELIVERY_SCHEDULE_EDITOR") ===
                                -1 ? (
                                  <div className="flex-row price-box-container justify-content-space-between  xetgo-font-tag px-8 py-4">
                                    <p>Actual Delivery Date</p>
                                    <p
                                      style={{ color: "#484848" }}
                                      className="xetgo-font-tag-bolder flex-row align-items-center gap-12"
                                    >
                                      {formatDate(
                                        delivery.actual_delivery_date,
                                      )}{" "}
                                      <img src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103135613-calender+check+blue.svg" />
                                    </p>
                                  </div>
                                ) : (
                                  <div className="flex-row price-box-container justify-content-space-between  xetgo-font-tag px-8 py-4">
                                    <p>Actual Delivery Date</p>
                                    <div
                                      style={{ color: "#484848" }}
                                      className="xetgo-font-tag-bolder flex-row align-items-center gap-12"
                                    >
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <DatePicker
                                          value={dayjs(
                                            delivery.actual_delivery_date,
                                          )}
                                          format={"DD-MM-YYYY"}
                                          views={["year", "month", "day"]}
                                          onChange={(event) =>
                                            updateDeliveryDetails(
                                              "actual_delivery_date",
                                              delivery.id,
                                              delivery.prtpm_id,
                                              vendors.vendor_id,
                                              event?.toDate().toISOString() ||
                                                "",
                                            )
                                          }
                                          open={
                                            deliveryOpen ===
                                            `actual-${delivery.id.toString()}`
                                          }
                                          className="delivery-date-selector"
                                        />
                                      </LocalizationProvider>
                                      <img
                                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103135613-calender+check+blue.svg"
                                        onClick={() =>
                                          toggleDeliveryDateSelector(
                                            delivery.id.toString(),
                                            "actual",
                                          )
                                        }
                                        className="calendar-icon cursor-pointer"
                                        height={16}
                                        width={16}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                )}
                                <div className="flex-row price-box-container justify-content-space-between  xetgo-font-tag px-8 py-4 gap-4 align-items-center">
                                  <p className="bold">Link : </p>
                                  <input
                                    key={productIndex}
                                    style={{ color: "#484848" }}
                                    className="xetgo-font-tag-bolder delivery-input price-box-delivery-location"
                                    placeholder="Tracking Link"
                                    defaultValue={delivery.track_link}
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter" && !e.shiftKey) {
                                        e.preventDefault();
                                        updateDeliveryDetails(
                                          "track_link",
                                          delivery.id,
                                          delivery.prtpm_id,
                                          vendors.vendor_id,
                                          (e.target as HTMLInputElement).value,
                                        );
                                      }
                                    }}
                                  />

                                  <a
                                    href={delivery.track_link}
                                    target="_blank_page"
                                  >
                                    <img
                                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231228215913-location-outline.svg"
                                      alt="location-icon"
                                    />
                                  </a>
                                </div>
                                {delivery?.pod?.name && (
                                  <a
                                    href={delivery.pod.url}
                                    target="_blank_page"
                                    className="pod-file"
                                  >
                                    <img
                                      src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240423091903-file-open.svg"
                                      alt="pod-file"
                                      width={16}
                                      height={16}
                                      className="cursor-pointer"
                                    />
                                  </a>
                                )}
                                <img
                                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114095750-trash.svg"
                                  alt="delete"
                                  width={16}
                                  height={16}
                                  className="cursor-pointer"
                                  onClick={() => deleteSchedule(delivery.id)}
                                />
                              </div>
                            );
                          },
                        )}
                        {newDeliverySchedule ===
                          `${vendorIndex}-${productIndex}` && (
                          <div
                            className="po-detail-new-delivery-detail-container px-8 gap-18"
                            key={productIndex}
                          >
                            <div className="flex-row price-box-container xetgo-font-tag px-8 py-4 gap-4">
                              <p>Delivered Quantity</p>
                              <div
                                style={{ color: "#484848" }}
                                className="xetgo-font-tag-bolder delivery-input"
                                ref={quantityRef}
                                contentEditable={true}
                              ></div>
                              <p className="xetgo-font-tag-bolder"> U</p>
                            </div>
                            <div className="flex-row price-box-container justify-content-space-between  xetgo-font-tag px-8 py-4">
                              <p>Expected Delivery Date</p>
                              <p
                                style={{ color: "#484848" }}
                                className="xetgo-font-tag-bolder flex-row align-items-center gap-12"
                              >
                                {deliveryDate}
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    value={dayjs()}
                                    format={"DD-MM-YYYY"}
                                    views={["year", "month", "day"]}
                                    onChange={(event) =>
                                      handleDueDateChange(
                                        event?.toDate().toISOString(),
                                      )
                                    }
                                    open={
                                      deliveryOpen ===
                                      `new-${vendorIndex}-${productIndex}`
                                    }
                                    className="delivery-date-selector new-date-entry"
                                  />
                                </LocalizationProvider>
                                <img
                                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103135613-calender+check+blue.svg"
                                  onClick={() =>
                                    toggleDeliveryDateSelector(
                                      `${vendorIndex}-${productIndex}`,
                                      "new",
                                    )
                                  }
                                  className="cursor-pointer"
                                  height={16}
                                  width={16}
                                />
                              </p>
                            </div>
                            <div className="flex-row price-box-container justify-content-space-between  xetgo-font-tag px-8 py-4">
                              <p>Actual Delivery Date</p>
                              <p
                                style={{ color: "#484848" }}
                                className="xetgo-font-tag-bolder flex-row align-items-center gap-12"
                              >
                                <img
                                  src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240103135613-calender+check+blue.svg"
                                  height={16}
                                  width={16}
                                />
                              </p>
                            </div>
                            <div
                              className="submit-delivery-schedule cursor-pointer flex-row align-items-center justify-content-center"
                              onClick={() =>
                                submitNewSchedule(product.prtpm_id)
                              }
                            >
                              <img
                                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240106094117-green_tick.svg"
                                alt="green-tick"
                                width={11}
                                height={9}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ),
                )}
              </div>
            </div>
          ),
        )}
        {productDetails.length === 0 && (
          <div className="m-16 p-12 flex-row no-product-container">
            <span
              className="flex-row xetgo-font-tag-bold cursor-pointer gap-2"
              style={{
                color: "#5151EC",
                width: "fit-content",
              }}
              onClick={handleAddProduct}
            >
              <img
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                alt="plus"
                width={16}
                height={16}
              />
              Add Product
            </span>
          </div>
        )}
      </div>
      {productDetails && (
        <XetModal
          backdropClose={false}
          open={openAddProduct}
          handleClose={() => setOpenAddProduct(false)}
        >
          <AddPurchaseProductDialog
            handleClose={() => {
              setOpenAddProduct(false);
              location.reload();
            }}
            taskId={purchaseRequestId}
          />
        </XetModal>
      )}

      {openCommentSection !== -1 && (
        <div className="comment-floating-section">
          <OutsideAlerter
            action={() => {
              updateURLParameter("comment", -1);
            }}
          >
            <TaskComment
              purchase_request_id={purchaseRequestId}
              vendor_id={openCommentSection}
              handleClose={() => {
                updateURLParameter("comment", -1);
              }}
            />
          </OutsideAlerter>
        </div>
      )}
    </>
  );
};

export default PurchaseOrderProducts;
