interface Environment {
  production: boolean;
  staging: boolean;
  local: boolean;
  baseUrl: string;
  clientUrl: string;
  socketUrl: string;
}

const environmentLocal: Environment = {
  production: false,
  staging: false,
  local: true,
  baseUrl: "http://localhost:3000",
  clientUrl: "http://localhost:3001",
  socketUrl: "http://localhost:8000",
};

const environmentProduction: Environment = {
  production: true,
  staging: false,
  local: false,
  baseUrl: "https://xetool2.xetgo.co",
  clientUrl: "https://admin.xetgo.co",
  socketUrl: "https://adminio.xetgo.co",
};

const environment: Environment =
  process.env.REACT_APP_ENV === "local"
    ? environmentLocal
    : environmentProduction;

export default environment;
