import React, { useEffect, useState } from "react";
import "./NewCompanyForm.scss";
import { NewClientPayloadSchema } from "../../../redux/oms/omsTypes";
import { toast } from "react-toastify";
import { toastOptions } from "../../../utils/toast";

interface NewCompanyFormProps {
  type: "CLIENT" | "VENDOR" | "POC" | "CONTACT";
  onCancel: () => void;
  onAddClient: (payload: NewClientPayloadSchema) => void;
}

const commonFormDetails = {
  company_name: "",
  domain: "",
  category: "",
};

const initContactDetail = {
  poc_name: "",
  designation: "",
  phoneNumber: "",
  email: "",
};

const NewCompanyForm: React.FC<NewCompanyFormProps> = ({
  type,
  onCancel,
  onAddClient,
}) => {
  const [commonFormData, setCommonFormData] = useState(commonFormDetails);
  const [contactDetails, setContactDetails] = useState([initContactDetail]);

  useEffect(() => {
    setCommonFormData((data) => ({
      ...data,
      category: type.toUpperCase(),
    }));
  }, [type]);

  const onAddClientHandler = () => {
    const formData = {
      ...commonFormData,
      contactDetails,
    };

    // Validate and call onAddClient
    if (Object.values(formData).some((value) => value === "")) {
      toast.error("All fields are compulsory", toastOptions);
    } else {
      onAddClient(formData);
    }
  };

  const updateCommonFormEntry = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof typeof commonFormDetails,
  ) => {
    setCommonFormData((data) => ({
      ...data,
      [key]: (event.target as HTMLInputElement).value,
    }));
  };

  const updateContactDetail = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof typeof initContactDetail,
    index: number,
  ) => {
    setContactDetails((details) =>
      details.map((detail, i) =>
        i === index
          ? { ...detail, [key]: (event.target as HTMLInputElement).value }
          : detail,
      ),
    );
  };

  const addNewContactDetail = () => {
    setContactDetails((details) => [...details, { ...initContactDetail }]);
  };

  return (
    <div className="px-12 py-16 new-client-form-container flex-column gap-24">
      <p className="title xetgo-font-button-bold">Add New {type}</p>
      <div className="new-client-form flex-column gap-12">
        <div className="company-details flex-row gap-12">
          <div className="new-client-entry flex-column gap-8">
            <p className="label xetgo-font-tag">Company Name</p>
            <input
              className="new-client-form-input xetgo-font-tag"
              placeholder="Xetgo Pvt Ltd"
              value={commonFormData.company_name}
              onChange={(e) => updateCommonFormEntry(e, "company_name")}
            />
          </div>

          <div className="new-client-entry flex-column gap-8">
            <p className="label xetgo-font-tag">Domain</p>
            <input
              className="new-client-form-input xetgo-font-tag"
              placeholder="xetgo.co.in"
              value={commonFormData.domain}
              onChange={(e) => updateCommonFormEntry(e, "domain")}
            />
          </div>
        </div>

        {contactDetails.map((contact, index) => (
          <div key={index} className="new-client-details-container">
            <div className="new-client-entry flex-column gap-8">
              <p className="label xetgo-font-tag">POC Name</p>
              <input
                className="new-client-form-input xetgo-font-tag"
                placeholder="John Doe"
                value={contact.poc_name}
                onChange={(e) => updateContactDetail(e, "poc_name", index)}
              />
            </div>

            <div className="new-client-entry flex-column gap-8">
              <p className="label xetgo-font-tag">Designation</p>
              <input
                className="new-client-form-input xetgo-font-tag"
                placeholder="Marketing Exectutive"
                value={contact.designation}
                onChange={(e) => updateContactDetail(e, "designation", index)}
              />
            </div>

            <div className="new-client-entry flex-column gap-8">
              <p className="label xetgo-font-tag">Phone Number</p>
              <input
                className="new-client-form-input xetgo-font-tag"
                placeholder="9830981388"
                value={contact.phoneNumber}
                onChange={(e) => updateContactDetail(e, "phoneNumber", index)}
              />
            </div>

            <div className="new-client-entry flex-column gap-8">
              <p className="label xetgo-font-tag">Email</p>
              <input
                className="new-client-form-input xetgo-font-tag"
                placeholder="example@gmail.com"
                value={contact.email}
                onChange={(e) => updateContactDetail(e, "email", index)}
              />
            </div>
          </div>
        ))}

        {type === "CLIENT" && (
          <span
            className="flex-row xetgo-font-tag-bold cursor-pointer gap-2"
            style={{ color: "#5151EC", width: "fit-content" }}
            onClick={addNewContactDetail}
          >
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
              alt="plus"
              width={16}
              height={16}
            />
            Add one more contact detail
          </span>
        )}
      </div>
      <div className="flex-row gap-12 new-form-btn-container">
        <div className="cancel-btn xetgo-font-tag" onClick={onCancel}>
          Cancel
        </div>
        <div className="add-btn xetgo-font-tag" onClick={onAddClientHandler}>
          Add {type.charAt(0) + type.slice(1).toLowerCase()}
        </div>
      </div>
    </div>
  );
};

export default NewCompanyForm;
