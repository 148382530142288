import React from "react";
import "./AssigneeSelector.scss";
import Filter from "../Options/Filter/Filter";
import { FilterOptionSchema } from "../../../redux/oms/omsTypes";
import { useSelector } from "react-redux";
import {
  selectOperationsAssignees,
  selectSalesAssignees,
  selectSourceAssignees,
} from "../../../redux/oms/omsSelectors";
import { useDispatch } from "react-redux";
import {
  updatePurchaseOrderDetailStart,
  updateTaskDetailStart,
} from "../../../redux/oms/omsActions";
import { useParams } from "react-router-dom";

interface AssigneeSelectorProps {
  task_id: number;
  assigneeList: FilterOptionSchema[];
  roles: string[];
  type: string;
}
const AssigneeSelector: React.FC<AssigneeSelectorProps> = ({
  task_id,
  assigneeList,
  roles,
  type,
}) => {
  const dispatch = useDispatch();
  const { category } = useParams();
  const salesAssignees = useSelector(selectSalesAssignees);
  const operationAssignees = useSelector(selectOperationsAssignees);
  const sourceAssignees = useSelector(selectSourceAssignees);

  const setAssignee = (role: string, option?: FilterOptionSchema[]) => {
    if (option && option.length > 0) {
      if (type === "EQS") {
        dispatch(
          updateTaskDetailStart({
            id: task_id,
            role,
            assignee_id: option.map((val) => val.id),
            category: category || "",
          }),
        );
      } else {
        dispatch(
          updatePurchaseOrderDetailStart({
            id: task_id,
            role,
            assignee_id: option.map((val) => val.id),
            category: category || "",
          }),
        );
      }
    }
  };

  return (
    <div className="assignee-selector-container p-8 flex-column gap-8">
      {roles.indexOf("SALES") > -1 && (
        <div className="flex-column gap-4">
          <p className="xetgo-font-tag title">Sales</p>
          <Filter
            option={{ key: "sales", name: "Sales" }}
            optionList={salesAssignees}
            multipleSelection={true}
            onSelection={(data: FilterOptionSchema[]) => {
              setAssignee("SALES", data);
            }}
            mode="showNames"
            showPlaceholder={false}
            borderStyle="solid"
            preSelected={assigneeList.filter((user) => user.role === "SALES")}
          />
        </div>
      )}
      {roles.indexOf("SOURCING") > -1 && (
        <div className="flex-column gap-4">
          <p className="xetgo-font-tag title">Sourcing</p>
          <Filter
            option={{ key: "sourcing", name: "Sourcing" }}
            optionList={sourceAssignees}
            multipleSelection={true}
            onSelection={(data: FilterOptionSchema[]) => {
              setAssignee("SOURCING", data);
            }}
            mode="showNames"
            showPlaceholder={false}
            borderStyle="solid"
            preSelected={assigneeList.filter(
              (user) => user.role === "SOURCING",
            )}
          />
        </div>
      )}
      {roles.indexOf("OPERATIONS") > -1 && (
        <div className="flex-column gap-4">
          <p className="xetgo-font-tag title">Operation</p>
          <Filter
            option={{ key: "operations", name: "Operations" }}
            optionList={operationAssignees}
            multipleSelection={true}
            onSelection={(data: FilterOptionSchema[]) => {
              setAssignee("OPERATIONS", data);
            }}
            mode="showNames"
            showPlaceholder={false}
            borderStyle="solid"
            preSelected={assigneeList.filter(
              (user) => user.role === "OPERATIONS",
            )}
          />
        </div>
      )}
    </div>
  );
};
export default AssigneeSelector;
