import { useEffect, useState } from "react";
import { SideNavbarData, SideNavData } from "./SideNav.data";
import { useDispatch } from "react-redux";
import {
  logOutUser,
  toggleNewNotification,
} from "../../../redux/user/userActions";
import "./SideNav.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  newNotification,
  userDetails,
} from "../../../redux/user/userSelectors";
import { User } from "../../../models/User";

const SideNav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [openDropdownIndex, setOpenDropdownIndex] = useState(-1);
  const newNotificationIndicator = useSelector(newNotification);
  const [mini, setMini] = useState(false);
  const user: User | null | undefined = useSelector(userDetails);

  const logout = async () => {
    dispatch(logOutUser());
  };

  useEffect(() => {
    const pattern = /(((eqs|xetrack)\/[^/]*\/[^/]*)|delivery-calendar)/;
    if (pattern.test(location.pathname)) {
      setMini(true);
    } else {
      setMini(false);
    }
    if (user) {
      SideNavbarData(user.role).forEach((item, index) => {
        if (location.pathname.toLowerCase().startsWith(item.link)) {
          setOpenDropdownIndex(index);
        }
      });
    }
  }, [location]);

  const optionSelected = (item: SideNavData, index: number) => {
    if (item.name === "Notification Centre") {
      dispatch(toggleNewNotification());
    }
    if (item.options.length === 0) {
      navigate(item.link);
      setOpenDropdownIndex(-1);
    } else if (openDropdownIndex === index) {
      setOpenDropdownIndex(-1);
    } else {
      setOpenDropdownIndex(index);
    }
  };

  return (
    <div className={`sidenav-container ${mini && "mini-side"}`}>
      <div className="flex-row align-items-center justify-content-space-between">
        <img
          className="logo"
          src={
            mini
              ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231106130417-xetgo_icon-1.svg"
              : "https://d2k6zobmg5lufr.cloudfront.net/company_logo.svg"
          }
          alt="logo"
        />
      </div>
      <div className="sideNav-middle-container">
        {user &&
          SideNavbarData(user.role).map((item, index) => (
            <div
              className={`sideNav-dropdown-container ${
                location.pathname === item.link ? "sidenav-active" : ""
              } flex-row align-items-center gap-12 ${
                item.options.length === 0 && "no-dropdown"
              }`}
              key={index}
              onClick={() => optionSelected(item, index)}
            >
              <div className="flex-row justify-content-space-between gap-12 full-width align-items-center">
                <img
                  src={
                    location.pathname === item.link
                      ? item.activeImage
                      : item.image
                  }
                  alt={item.name}
                />
                {!mini && (
                  <p className="xetgo-font-button-bolder flex-1">{item.name}</p>
                )}
                {item.name === "Notification Centre" &&
                  newNotificationIndicator && (
                    <div className="new-notification"></div>
                  )}
                {item.options &&
                  item.options.length > 0 &&
                  (!mini || (mini && openDropdownIndex !== index)) && (
                    <div style={{ height: "17px" }}>
                      <img
                        className="dropdown-icon"
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231005115527-chevron-down.svg"
                        alt="Dropdown Icon"
                      />
                    </div>
                  )}
              </div>
              {openDropdownIndex === index && item.options.length > 0 && (
                <div className="side-drop-content-container ">
                  {item.options.map((nestedItem, nestedIndex) => (
                    <div
                      className="flex-row align-items-center gap-2"
                      style={{ marginTop: "-8px" }}
                      key={nestedIndex}
                    >
                      <img
                        className="nested-connect-icon"
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231223101053-Rectangle+2.svg"
                        alt="dropdown-arrow"
                      />
                      <div
                        className={`${
                          location.pathname
                            .toLowerCase()
                            .startsWith(nestedItem.link)
                            ? "active-option-container"
                            : ""
                        } nested-name flex-row gap-8 align-items-center`}
                        onClick={() => navigate(nestedItem.link)}
                      >
                        <img
                          src={
                            location.pathname
                              .toLowerCase()
                              .startsWith(nestedItem.link)
                              ? nestedItem.activeImage
                              : nestedItem.image
                          }
                          alt={nestedItem.name}
                          width={16}
                          height={16}
                        />
                        {!mini && (
                          <p
                            className={`${
                              location.pathname.toLowerCase() ===
                              nestedItem.link
                                ? "active-option"
                                : ""
                            } xetgo-font-tag`}
                          >
                            {nestedItem.name}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
      </div>
      <div className="border-gradient sidenav-lower-container">
        <img
          src={`${
            user?.avatar ||
            "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231005210014-user-dp-icon.svg"
          }`}
          alt="profile"
          className="avatar"
        />
        {!mini && (
          <>
            <div>
              <p className="sidenav-lower-title">{user?.name.split(" ")[0]}</p>
              <p className="sidenav-lower-subtitle">{user?.email}</p>
            </div>
            <img
              className="logout-icon cursor-pointer"
              onClick={logout}
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231005210602-exit-icon.svg"
              alt="logout"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default SideNav;
