import React, { useEffect, useState } from "react";
import "./XetImage.scss";
import { RandomImageSrc } from "../../../utils/randomImage";

interface IXetImage {
  src?: string | null | undefined;
  alt: string;
  className?: string;
  width?: number;
  height?: number;
}

const XetImage: React.FC<IXetImage> = ({
  src,
  alt,
  className = "",
  width,
  height,
}) => {
  const [alternateImage, setAlternateImage] = useState("");
  const [randomColor, setRandomColor] = useState("");

  useEffect(() => {
    if (width && height) {
      setAlternateImage(RandomImageSrc(width, height));
    }
    const color = getRandomColor();
    setRandomColor(color);
  }, []);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return src !== undefined && src !== null ? (
    <img
      src={src || alternateImage}
      alt={alt}
      className={className}
      width={width}
      height={height}
    />
  ) : (
    <div
      className="user-initials-avatar xetgo-font-tag flex-row justify-content-center align-items-center"
      style={{
        backgroundColor: randomColor,
        height: height ? height : "24px",
        width: width ? width : "24px",
      }}
    >
      {alt.charAt(0).toUpperCase() +
        "" +
        alt.charAt(alt.indexOf(" ") + 1)?.toUpperCase()}
    </div>
  );
};

export default XetImage;
