export const FILESATTACHMENT = {};

export const FILES_ARRAY = [
  {
    id: 1,
    name: "file 1.pdf",
    url: "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231228092959-file-text.svg",
    type: "Purchase Order",
  },
  {
    id: 1,
    name: "file2.pdf",
    url: "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231228092959-file-text.svg",
    type: "Purchase Order",
  },
];

export const BADGES = [
  {
    text: "Purchase Order",
    color: "#854D0E",
    backgroundColor: "#FEF9C3",
    value: "PURCHASE_ORDER",
  },
  {
    text: "Sales Order",
    color: "#991B1B",
    backgroundColor: "#FEE2E2",
    value: "SALES_ORDER",
  },
  {
    text: "Vendor Purchase Order",
    color: "#1E40AF",
    backgroundColor: "#DBEAFE",
    value: "VENDOR_PURCHASE_ORDER",
  },
  {
    text: "Artwork",
    color: "#115E59",
    backgroundColor: "#CCFBF1",
    value: "ARTWORK",
  },
  {
    text: "Quotation",
    color: "#990037",
    backgroundColor: "#FBCCDD",
    value: "QUOTATION",
  },
  {
    text: "COA",
    color: "#9C00B8",
    backgroundColor: "#F4CCFB",
    value: "COA",
  },
  {
    text: "Miscellaneous",
    color: "#3D00A1",
    backgroundColor: "#DECCFB",
    value: "MISCELLANEOUS",
  },
];
