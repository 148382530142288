import React, { ChangeEvent, useState } from "react";
import "./QuantityDropdown.scss";
import { AbstractControl } from "react-reactive-form";
import { MEASUREMENT_UNITS } from "./QuantityDropdown.data";

interface QuantityDropdownProps {
  onQuantityChange: (newQuantity: number, measurementUnit: string) => void;
  value: number;
  unit: string;
}

const QuantityDropdown: React.FC<QuantityDropdownProps> = ({
  onQuantityChange,
  value,
  unit,
}) => {
  const [quantityInputValue, setQuantityInputValue] = useState<number>(value);
  const [selectedMeasurementUnit, setSelectedMeasurementUnit] =
    useState<string>(unit || MEASUREMENT_UNITS[0]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newQuantity = Number(event.target.value.trim());
    setQuantityInputValue(newQuantity);
    onQuantityChange(newQuantity, selectedMeasurementUnit);
  };

  const handleMeasurementUnitChange = (
    event: ChangeEvent<HTMLSelectElement>,
  ) => {
    const newMeasurementUnit = event.target.value;
    setSelectedMeasurementUnit(newMeasurementUnit);
    onQuantityChange(quantityInputValue, newMeasurementUnit);
  };

  return (
    <div className="flex-row position-relative">
      <input
        value={quantityInputValue}
        type="number"
        onChange={handleInputChange}
        className="text-input px-8 py-4 xetgo-font-tag"
      />
      <select
        value={selectedMeasurementUnit}
        onChange={handleMeasurementUnitChange}
        className="measurement-box xetgo-font-tag"
      >
        {MEASUREMENT_UNITS.map((unit) => (
          <option key={unit} value={unit}>
            {unit}
          </option>
        ))}
      </select>
    </div>
  );
};

export default QuantityDropdown;
