import React from "react";
import Drawer from "@mui/material/Drawer";

interface XetDrawerProps {
  open: boolean;
  handleClose: () => void;
  backdropClose?: boolean;
  position?: "top" | "left" | "bottom" | "right";
  children: React.ReactNode;
}

const XetDrawer: React.FC<XetDrawerProps> = ({
  open,
  handleClose,
  backdropClose = true,
  position,
  children,
}) => {
  return (
    <Drawer
      open={open}
      onClose={backdropClose ? handleClose : undefined}
      anchor={position}
    >
      {children}
    </Drawer>
  );
};

export default XetDrawer;
