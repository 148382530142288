import React, { useEffect, useState } from "react";
import "./Sku.scss";
import SideNav from "../shared/sidenav/SideNav";
import SkuCard from "../SkuCard/SkuCard";
import ApiService from "../../services/apiService";
import URL from "../../urls/Urls";
import Filter from "../oms/Options/Filter/Filter";
import { FilterKeys, FilterOptionSchema } from "../../redux/oms/omsTypes";
import { useSelector } from "react-redux";
import {
  selectClientAddresses,
  selectFilterOptions,
} from "../../redux/oms/omsSelectors";
import { filters } from "../oms/Options/Filter/Filter.data";
import { useDispatch } from "react-redux";
import {
  fetchCompanyAddressStart,
  fetchFilter,
} from "../../redux/oms/omsActions";
import { SKU } from "../../models/Sku";
import { ToastContainer, toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";

const Sku: React.FC = () => {
  const dispatch = useDispatch();
  const clients = useSelector((state) =>
    selectFilterOptions(state, filters[1].key),
  );
  const client_addresses = useSelector(selectClientAddresses);

  const [skuList, setSkuList] = useState<SKU[]>([]);
  const [selectedClient, setSelectedClient] =
    useState<FilterOptionSchema | null>(null);
  const [selectedClientAddress, setSelectedClientAddress] =
    useState<FilterOptionSchema | null>(null);

  useEffect(() => {
    dispatch(fetchFilter("SKU"));
  }, []);

  useEffect(() => {
    setSelectedClientAddress(client_addresses[0]);
  }, [client_addresses]);

  useEffect(() => {
    if (!!selectedClient?.id && !!selectedClientAddress?.id) {
      getSkuList(selectedClient.id, selectedClientAddress?.id).then((r) => {
        return r;
      });
    }
  }, [selectedClientAddress]);

  const getSkuList = async (
    selectedClientId: number,
    selectedClientAddressId: number,
  ) => {
    const { data } = await ApiService().client.get(
      URL.OMS.GET_SKU_LIST(selectedClientId),
      {
        params: {
          address_id: selectedClientAddressId,
        },
      },
    );
    setSkuList(data.data);
  };

  const getClientAddresses = async (clientId: number) => {
    dispatch(fetchCompanyAddressStart(clientId));
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    tpmId: number,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const formData = new FormData();
      const files = Array.from(event.target.files);
      const displayNames = files.map((file) => file.name);
      files.forEach((file, index) => {
        formData.append("files[]", file);
        const displayName = displayNames[index]?.trim();
        if (displayName) {
          formData.append("displayNames[]", displayName);
        }
      });
      try {
        const { data } = await ApiService().client.put(
          URL.OMS.UPDATE_SKU_LIST(tpmId),
          formData,
        );
        skuList.map((sku, index) => {
          if (sku.tpm_id === tpmId) {
            sku.attachments = data.data["tpm_attachments"];
          }
          return sku;
        });
        toast.success("Uploaded File Successfully", toastOptions);
      } catch (error) {
        toast.error("Failed Uploading File", toastOptions);
      }
    }
  };

  const deleteFile = async (tpmId: number, attachmentId: number) => {
    try {
      const { data } = await ApiService().client.put(
        URL.OMS.UPDATE_SKU_LIST(tpmId),
        { to_be_deleted_file_ids: [attachmentId] },
      );

      skuList.map((sku, index) => {
        if (sku.tpm_id === tpmId) {
          sku.attachments = data.data["tpm_attachments"];
        }
        return sku;
      });
      toast.success("Deleted File Successfully", toastOptions);
    } catch (error) {
      toast.error("Failed Deleting File", toastOptions);
    }
  };

  const updateLeadTime = (tpmId: number, updatedLeadTime: number) => {
    skuList.map((sku, index) => {
      if (sku.tpm_id === tpmId) {
        sku.lead_time = updatedLeadTime;
      }
      return sku;
    });
    toast.success("Lead Time Updated", toastOptions);
  };

  const onClientSelection = async (data: FilterOptionSchema[]) => {
    if (data[0]) {
      setSelectedClient(data[0]);
      await getClientAddresses(data[0].id);
    } else {
      setSelectedClient(null);
      setSelectedClientAddress(null);
      setSkuList([]);
    }
  };

  return (
    <div className="flex-row">
      <SideNav></SideNav>
      <div className="sku-list-page">
        <div className="sku-upper-container border-box flex-row justify-content-space-between p-16 align-items-center">
          <div className="flex-row gap-8 align-items-center">
            <p className="sku-title xetgo-font-h3 bold">SKU - Actions </p>
            <span className="sku-action-count px-8 py-4 xetgo-font-button bolder">
              {skuList.length}
            </span>
          </div>
          <div className="upper-right-container flex-row align-items-center gap-8">
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240227122733-list-filter.svg"
              alt="filter"
            />
            <div className="sku-client-filter-main-container">
              <Filter
                option={{ key: FilterKeys.CLIENTS, name: "Client Name" }}
                key={FilterKeys.CLIENTS}
                optionList={clients}
                multipleSelection={false}
                onSelection={onClientSelection}
                preSelected={selectedClient ? [selectedClient] : []}
                mode="showNames"
                showPlaceholder={false}
                borderStyle="solid"
                addNew={false}
              />
            </div>
            <div className="sku-client-address-filter-main-container">
              <Filter
                option={{
                  key: "address",
                  name: "Address",
                }}
                optionList={client_addresses}
                multipleSelection={false}
                onSelection={(val, option) => {
                  setSelectedClientAddress(val[0]);
                }}
                mode="showNames"
                showPlaceholder={false}
                borderStyle="solid"
                preSelected={
                  selectedClientAddress ? [selectedClientAddress] : []
                }
                addNew={false}
              />
            </div>
          </div>
        </div>
        <div className="p-16 sku-lower-container ">
          {skuList && skuList.length > 0 && (
            <div className="flex-column sku-card-list-container ">
              {skuList.map((sku, index) => (
                <SkuCard
                  key={index}
                  handleFileUpload={handleFileUpload}
                  skuDetails={sku}
                  updateLeadTime={updateLeadTime}
                  deleteFile={deleteFile}
                />
              ))}
            </div>
          )}

          {skuList && skuList.length === 0 && (
            <div className="sku-empty-container flex-column gap-12 xetgo-font-tag bold align-items-center">
              <img
                height={24}
                width={24}
                src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240404074726-glass-box-empty-state.svg"
                alt="empty-box-icon"
              />
              <p className="sku-empty-phhrase">
                No SKUs found for selected filters Change company or address
              </p>
            </div>
          )}
        </div>
      </div>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default Sku;
