import { format, isThisYear, isToday, isYesterday } from "date-fns";

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

export const formatNotificationStampDate = (timestamp: string): string => {
  const date = new Date(timestamp);

  if (isToday(date)) {
    return `Today at ${format(date, "h:mm a")}`;
  } else if (isYesterday(date)) {
    return `Yesterday at ${format(date, "h:mm a")}`;
  } else if (isThisYear(date)) {
    return format(date, "EEE, MMM d, h:mm a");
  } else {
    return format(date, "EEE, MMM d, yyyy, h:mm a");
  }
};
