import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./XetEditor.scss";

interface IXetEditor {
  value?: string;
  defaultHeight: string;
  onChange: (content: string) => void;
  showSaveContent?: boolean;
  onSaveContent?: () => void;
}

const XetEditor: React.FC<IXetEditor> = ({
  value,
  defaultHeight,
  onChange,
  showSaveContent,
  onSaveContent,
}) => {
  const [expandEditor, setExpandEditor] = useState<boolean>(false);

  const handleOnExpandClick = () => {
    setExpandEditor(!expandEditor);
  };

  return (
    <main
      className="editor-container"
      style={
        expandEditor ? { height: "fit-content" } : { height: defaultHeight }
      }
    >
      <CKEditor
        editor={ClassicEditor}
        data={value}
        onChange={(_event, editor) => onChange(editor?.getData())}
      />

      <div className="flex-row action-buttons align-items-center py-4">
        {showSaveContent && (
          <>
            {" "}
            <img
              src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231114120329-send.svg"
              alt="save-desc"
              height={16}
              width={16}
              className="cursor-pointer py-4"
              onClick={onSaveContent}
            />
            <div className="separator"></div>
          </>
        )}

        <img
          src={
            expandEditor
              ? "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231116134951-expand_highlight.svg"
              : "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231115110319-expand.svg"
          }
          alt="expand-description"
          height={16}
          width={16}
          className="expand-button cursor-pointer py-2"
          onClick={handleOnExpandClick}
        />
      </div>
    </main>
  );
};

export default XetEditor;
