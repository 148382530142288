import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import routes from "./Routes";
import {
  isAuthenticated,
  isUserLoading,
  selectAuthToken,
} from "../redux/user/userSelectors";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { JWT_TOKEN, setCookie } from "../services/cookieService";
import { isNullOrUndefined } from "./Helper";
import { fetchUser } from "../redux/user/userActions";

const ActiveRoutes = () => {
  const userLoading = useSelector(isUserLoading);
  const [loadContent, setLoadContent] = useState(true);
  const isUserAuthenticated: boolean = useSelector(isAuthenticated);
  const authToken: string | undefined | null = useSelector(selectAuthToken);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authToken) {
      dispatch(
        fetchUser({
          jwtToken: authToken,
        }),
      );
      setCookie(JWT_TOKEN, authToken);
    }
    setLoadContent(false);
  }, [authToken]);

  if (!loadContent && (!userLoading || isNullOrUndefined(authToken))) {
    if (isUserAuthenticated) {
      return (
        <Router>
          <Routes>
            {routes.map((route, index) =>
              route.protected ? <Route key={index} {...route} /> : null,
            )}

            <Route
              key={routes.length}
              path="/notfound"
              element={<div> PAGE NOT FOUND </div>}
            />
            <Route path="*" element={<Navigate to={`/eqs/rfq`} replace />} />
          </Routes>
        </Router>
      );
    } else {
      return (
        <Router>
          <Routes>
            {routes.map((route, index) =>
              !route.protected ? <Route key={index} {...route} /> : null,
            )}

            <Route
              key={routes.length}
              path="/notfound"
              element={<div> PAGE NOT FOUND </div>}
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      );
    }
  } else {
    return <></>;
  }
};

export default ActiveRoutes;
