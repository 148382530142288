import React, { useEffect, useMemo, useState } from "react";
import { BlobProvider } from "@react-pdf/renderer";
import { ProductDetailSchema, TaskProductDetails } from "../../../models/Task";
import QuotationReport from "../../../reports/QuotationReport/QuotationReport";
import "./QuotationDataModal.scss";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { selectTaskClientDetail } from "../../../redux/oms/omsSelectors";
import { userDetails } from "../../../redux/user/userSelectors";

interface QuotationDataModalProps {
  products: ProductDetailSchema[];
  address: TaskProductDetails;
  handleClose: () => void;
  assigneeName: string;
}

interface QuotationFormData {
  projectInfo: string;
  quotedDescription: string;
  summaryNotes: string;
  leadTimeFirstOrder: string;
  leadTimeSubsequentOrder: string;
  paymentTermsAdvance: string;
  paymentTermsRemaining: string;
  deliveryLocation: string;
  companyName: string;
  companyAddress: string;
  clientPocName: string;
  clientPocPhone: string;
  clientPocEmail: string;
  clientPocDesignation: string;
}

const newQuotationFormData: QuotationFormData = {
  projectInfo: "",
  quotedDescription: "",
  summaryNotes: "",
  leadTimeFirstOrder: "",
  leadTimeSubsequentOrder: "",
  paymentTermsAdvance: "",
  paymentTermsRemaining: "",
  deliveryLocation: "",
  companyName: "",
  companyAddress: "",
  clientPocName: "",
  clientPocPhone: "",
  clientPocEmail: "",
  clientPocDesignation: "",
};

const QuotationDataModal: React.FC<QuotationDataModalProps> = ({
  address,
  products,
  handleClose,
  assigneeName,
}) => {
  const [formData, setFormData] =
    useState<QuotationFormData>(newQuotationFormData);
  const formKeys = [
    { label: "Project Info", name: "projectInfo" },
    { label: "Quoted Description", name: "quotedDescription" },
    { label: "Summary Notes", name: "summaryNotes" },
  ];
  const [productDetails, setProductDetails] = useState<{
    [key: number]: {
      notes: string;
      qty: number;
      unit: string;
      gst: number;
      description: string;
    };
  }>({});
  const [showGeneratePdfButton, setShowGeneratePdfButton] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const clientInfo = useSelector(selectTaskClientDetail);
  const currentUser = useSelector(userDetails);

  useEffect(() => {
    const formattedProductDetails = products.reduce(
      (acc, product) => ({
        ...acc,
        [product.tpm_id]: {
          qty: product.quantity,
          unit: product.unit,
          gst: 18,
          description: product.description,
        },
      }),
      {},
    );
    setProductDetails(formattedProductDetails);
  }, []);

  useEffect(() => {
    setFormData((prevState) => {
      return {
        ...prevState,
        companyName: clientInfo?.name || "",
        companyAddress:
          address.address_line +
            ", " +
            address.address_city +
            ", " +
            address.address_state +
            ", " +
            address.address_pincode || "",
        clientPocName: clientInfo?.client_poc_name || "",
        clientPocPhone: clientInfo?.client_poc_phone || "",
        clientPocEmail: clientInfo?.client_poc_email || "",
        clientPocDesignation: clientInfo?.client_poc_designation || "",
      };
    });
  }, [clientInfo]);

  useEffect(() => {
    return () => {
      setFormData(newQuotationFormData);
      setProductDetails({});
    };
  }, []);

  useEffect(() => {
    setShowGeneratePdfButton(false);
  }, [formData, productDetails]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleProductDetailsChange = (
    tpmId: number | undefined,
    key: string,
    value: string,
  ) => {
    console.log("CHANGED: ", tpmId, key, value);
    setProductDetails((prevDetails) =>
      tpmId
        ? {
            ...prevDetails,
            [tpmId]: {
              ...prevDetails[tpmId],
              [key]: value,
            },
          }
        : prevDetails,
    );
  };

  const handleOpenQuotationPdf = (url: string, filename: string) => {
    window.open(url, "_blank");
    handleClose();
  };

  const handleGenerateQuotation = () => {
    setLoading(true);
    setTimeout(() => {
      setShowGeneratePdfButton(true);
      setLoading(false);
    }, 700);
  };

  const memoizedDocument = useMemo(() => {
    console.log("productDetails: ", productDetails);
    return (
      <QuotationReport
        products={products}
        productDetails={productDetails}
        currentUser={currentUser}
        formData={formData}
        assigneeName={assigneeName}
      />
    );
  }, [address, products, productDetails, formData]);

  return (
    <div className="quotation-modal">
      <div className="data-form">
        <p className="quotation-info-heading"> Quotation Information </p>

        {formKeys.map((field, index) => (
          <div key={index} className="form-group">
            <label className="form-label">{field.label}</label>
            <input
              name={field.name}
              value={(formData as any)[field.name]}
              onChange={handleChange}
              className="form-input"
            />
          </div>
        ))}

        <div className="flex-row gap-12">
          <div className="form-group half-width">
            <label className="form-label">Lead Time (First Order)</label>
            <input
              name="leadTimeFirstOrder"
              value={formData.leadTimeFirstOrder}
              onChange={handleChange}
              className="form-input"
            />
          </div>
          <div className="form-group half-width">
            <label className="form-label">Lead Time (Subsequent Order)</label>
            <input
              name="leadTimeSubsequentOrder"
              value={formData.leadTimeSubsequentOrder}
              onChange={handleChange}
              className="form-input"
            />
          </div>
        </div>

        <div className="flex-row gap-12">
          <div className="form-group half-width">
            <label className="form-label">Payment Terms (Advance)</label>
            <input
              name="paymentTermsAdvance"
              value={formData.paymentTermsAdvance}
              onChange={handleChange}
              className="form-input"
            />
          </div>
          <div className="form-group half-width">
            <label className="form-label">
              Payment Terms (Remaining in Days)
            </label>
            <input
              name="paymentTermsRemaining"
              value={formData.paymentTermsRemaining}
              onChange={handleChange}
              className="form-input"
            />
          </div>
        </div>

        <div className="form-group">
          <label className="form-label">Delivery Location</label>
          <input
            name="deliveryLocation"
            value={formData.deliveryLocation}
            onChange={handleChange}
            className="form-input"
          />
        </div>

        <div className="product-details">
          {products.map((product, index) => (
            <div
              key={`details-${index}`}
              className="detail-group flex-column gap-12"
            >
              <p className="product-detail-heading">
                {index + 1} - {product.name}{" "}
              </p>

              <span className="flex-row gap-12">
                <div className="form-group half-width">
                  <label className="form-label">Quantity</label>
                  <input
                    type="text"
                    name={`qty-${product.tpm_id}`}
                    value={productDetails[product?.tpm_id || -1]?.qty ?? ""}
                    onChange={(e) =>
                      handleProductDetailsChange(
                        product.tpm_id,
                        "qty",
                        e.target.value,
                      )
                    }
                    className="form-input"
                  />
                </div>

                <div className="form-group half-width">
                  <label className="form-label">Unit</label>
                  <input
                    type="text"
                    name={`unit-${product.tpm_id}`}
                    value={productDetails[product?.tpm_id || -1]?.unit ?? ""}
                    onChange={(e) =>
                      handleProductDetailsChange(
                        product.tpm_id,
                        "unit",
                        e.target.value,
                      )
                    }
                    className="form-input"
                  />
                </div>

                <div className="form-group half-width">
                  <label className="form-label">GST</label>
                  <input
                    type="text"
                    name={`gst-${product.tpm_id}`}
                    value={productDetails[product?.tpm_id || -1]?.gst ?? ""}
                    onChange={(e) =>
                      handleProductDetailsChange(
                        product.tpm_id,
                        "gst",
                        e.target.value,
                      )
                    }
                    className="form-input"
                  />
                </div>
              </span>

              <div className="form-group">
                <label className="form-label">Description</label>
                <textarea
                  name={`description-${product.tpm_id}`}
                  value={
                    productDetails[product?.tpm_id || -1]?.description ?? ""
                  }
                  onChange={(e) =>
                    handleProductDetailsChange(
                      product.tpm_id,
                      "description",
                      e.target.value,
                    )
                  }
                  className="form-input"
                />
              </div>

              <div className="form-group">
                <label className="form-label">Notes</label>
                <textarea
                  name={`note-${product.tpm_id}`}
                  value={productDetails[product?.tpm_id || -1]?.notes ?? ""}
                  onChange={(e) =>
                    handleProductDetailsChange(
                      product.tpm_id,
                      "notes",
                      e.target.value,
                    )
                  }
                  className="form-input"
                />
              </div>
            </div>
          ))}
        </div>

        <div className="client-information-section flex-column gap-12">
          <p className="client-info-heading"> Client Information </p>
          <div className="form-group">
            <label className="form-label">Company Name</label>
            <input
              name="companyName"
              defaultValue={clientInfo?.name || formData.companyName}
              onChange={handleChange}
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label className="form-label">Address</label>
            <input
              name="companyAddress"
              defaultValue={
                address.address_line +
                  ", " +
                  address.address_city +
                  ", " +
                  address.address_state +
                  ", " +
                  address.address_pincode || formData.companyAddress
              }
              onChange={handleChange}
              className="form-input"
            />
          </div>

          <div className="flex-row gap-12">
            <div className="form-group half-width">
              <label className="form-label">POC Name</label>
              <input
                name="clientPocName"
                defaultValue={
                  clientInfo?.client_poc_name || formData.clientPocName
                }
                onChange={handleChange}
                className="form-input"
              />
            </div>

            <div className="form-group half-width">
              <label className="form-label">POC Email</label>
              <input
                name="clientPocEmail"
                defaultValue={
                  clientInfo?.client_poc_email || formData.clientPocEmail
                }
                onChange={handleChange}
                className="form-input"
              />
            </div>
          </div>

          <div className="flex-row gap-12">
            <div className="form-group half-width">
              <label className="form-label">POC Phone</label>
              <input
                name="clientPocPhone"
                defaultValue={
                  clientInfo?.client_poc_phone || formData.clientPocPhone
                }
                onChange={handleChange}
                className="form-input"
              />
            </div>

            <div className="form-group half-width">
              <label className="form-label">POC Designation</label>
              <input
                name="clientPocDesignation"
                defaultValue={
                  clientInfo?.client_poc_designation ||
                  formData.clientPocDesignation
                }
                onChange={handleChange}
                className="form-input"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="actionables flex-row align-items-center gap-8">
        <div
          className="saveAndGeneratePdf cursor-pointer"
          onClick={() => handleGenerateQuotation()}
        >
          Generate Quotation
        </div>

        {loading && <CircularProgress size={20} />}

        {!loading && showGeneratePdfButton && (
          <BlobProvider document={memoizedDocument}>
            {({ blob, url, loading, error }) => {
              if (loading) return <CircularProgress size={20} />;
              if (error) {
                return (
                  <button className="report-download-button" disabled>
                    Error generating PDF
                  </button>
                );
              }

              const filename = `Quotation Report -${formData.companyName} ${formData.projectInfo} ${Date.now}.pdf`;

              return (
                <button
                  onClick={() => handleOpenQuotationPdf(url || "", filename)}
                  className="report-download-button flex-row align-items-center gap-8"
                >
                  Preview/Save
                  <img
                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20240624120337-download.svg"
                    alt="download"
                    height={16}
                    width={16}
                  />
                </button>
              );
            }}
          </BlobProvider>
        )}
      </div>
    </div>
  );
};

export default QuotationDataModal;
