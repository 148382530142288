import { FormBuilder, Validators } from "react-reactive-form";

export const PURCHASE_REQUEST_FORM = FormBuilder.group({
  client: [null, Validators.required],
  due_date: ["DD-MM-YYYY", Validators.required],
  priority: [null, [Validators.required, Validators.email]],
  description: ["", Validators.required],
  client_type: ["EXISTING", Validators.required],
  po_number: [null, Validators.required],
  po_value: [null, Validators.required],
  po_date: ["DD-MM-YYYY", Validators.required],
  address: [null, Validators.required],
  map_link: [null, Validators.required],
  uploads: FormBuilder.array([]),
});

export const PURCHASE_REQUEST_PRODUCT_FORM = FormBuilder.group({
  products: FormBuilder.array([
    FormBuilder.group({
      name: [null, Validators.required],
      material: [null, Validators.required],
      sku_name: [null, Validators.required],
      quantity: [null, Validators.required],
      vendor: [null, Validators.required],
      vendor_po_number: [null, Validators.required],
      cost_price: [null, Validators.required],
      selling_price: [null, Validators.required],
      description: [null, Validators.required],
    }),
  ]),
});

export const ICON_LIST = {
  default:
    "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231228092959-file-x.svg",
  file: "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231228092959-file-text.svg",
  zip: "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231228092959-file-stack.svg",
  sheet:
    "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231228092959-file-spreadsheet.svg",
  image:
    "https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231228092958-file-image.svg",
};

export const PURCHASE_REQUEST_FILE_FORM = (
  id: number,
  name: string,
  category: string,
) =>
  FormBuilder.group({
    id: [id, Validators.required],
    name: [name, Validators.required],
    category: [category, Validators.required],
  });
