import React, { useEffect, useState } from "react";
import "./PurchaseRequestForm.scss";
import { useSelector } from "react-redux";
import {
  selectClientAddresses,
  selectFilterOptions,
} from "../../../redux/oms/omsSelectors";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { filters } from "../Options/Filter/Filter.data";
import Filter from "../Options/Filter/Filter";
import {
  FilterOptionSchema,
  NewClientPayloadSchema,
  NewCompanyAddressPayloadSchema,
} from "../../../redux/oms/omsTypes";
import XetEditor from "../../shared/xet-editor/XetEditor";
import { useDispatch } from "react-redux";
import {
  fetchCompanyAddressStart,
  newCompanyAddressStart,
  newCompanyStart,
  removeCompanyAddress,
} from "../../../redux/oms/omsActions";
import { TASK_PRIORITY } from "../../../models/RFQ";
import {
  AbstractControl,
  FormArray,
  FieldGroup,
  FieldControl,
} from "react-reactive-form";
import NewAddressForm from "../NewAddressForm/NewAddressForm";
import { isNotNullOrUndefined, isNullOrUndefined } from "../../../utils/Helper";
import { TaskFileSchema } from "../../../models/Task";
import { BADGES } from "./PurchaseRequest.data";
import {
  ICON_LIST,
  PURCHASE_REQUEST_FILE_FORM,
  PURCHASE_REQUEST_FORM,
} from "../../../models/PurchaseRequest";
import NewCompanyForm from "../NewCompanyForm/NewCompanyForm";

interface PurchaseRequestFormProps {
  onSubmit: (task: FormData) => void;
  onClose: () => void;
}

const PurchaseRequestForm: React.FC<PurchaseRequestFormProps> = ({
  onSubmit,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [productForm, _setProductForm] = useState(PURCHASE_REQUEST_FORM);
  const clients = useSelector((state) =>
    selectFilterOptions(state, filters[1].key),
  );
  const client_addresses = useSelector(selectClientAddresses);
  const [newUserData, setNewUserData] = useState<{
    name?: string;
    type?: string;
    control?: AbstractControl;
  }>();
  const [fileList, setFileList] = useState<File[]>([]);
  const [fileCategory, setFileCategory] = useState<number[]>([]);
  const [openNewClientForm, setOpenNewClientForm] = useState(false);
  const [openNewAddressForm, setOpenNewAddressForm] = useState(false);
  const [tagSelectedIndex, setTagSelectedIndex] = useState(-1);
  const [descriptionTracker, setDescriptionTracker] = useState("");

  const handleDueDateChange = (newValue: any) => {
    if (newValue) {
      const formattedDate = dayjs(newValue).format("DD-MM-YYYY");
      productForm.patchValue({ due_date: formattedDate });
    }
  };

  const handlePODateChange = (newValue: any) => {
    if (newValue) {
      const formattedDate = dayjs(newValue).format("DD-MM-YYYY");
      productForm.patchValue({ po_date: formattedDate });
    }
  };

  const handleSubmit = () => {
    const purchaseFormData = new FormData();
    purchaseFormData.append("clientId", productForm.get("client").value.id);
    purchaseFormData.append("priority", productForm.get("priority").value.name);
    purchaseFormData.append("dueDate", productForm.get("due_date").value);
    purchaseFormData.append("description", descriptionTracker);
    purchaseFormData.append("poNumber", productForm.get("po_number").value);
    purchaseFormData.append("poValue", productForm.get("po_value").value);
    purchaseFormData.append("poDate", productForm.get("po_date").value);
    purchaseFormData.append("addressId", productForm.get("address").value.id);
    purchaseFormData.append("mapLink", productForm.get("map_link").value);
    purchaseFormData.append("clientType", productForm.get("client_type").value);
    const displayNames = fileList.map((file) => file.name);
    fileList.forEach((file, index) => {
      purchaseFormData.append("files[]", file);
      const displayName = displayNames[index]?.trim();
      if (displayName) {
        purchaseFormData.append("fileNames[]", displayName);
      }
      purchaseFormData.append("fileTags[]", BADGES[fileCategory[index]].value);
    });
    onSubmit(purchaseFormData);
    resetForm();
  };

  const handleDescriptionChange = (description: string) => {
    setDescriptionTracker(description);
  };

  const resetForm = () => {
    PURCHASE_REQUEST_FORM.reset();
    const uploads = productForm.get("uploads") as FormArray;
    for (let i = 0; i <= uploads.length; i++) {
      uploads.removeAt(0);
    }
    uploads.removeAt(0);
    setDescriptionTracker("");
    productForm.patchValue({ description: "" });
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleFileUpload = (event: any) => {
    const uploadControls = productForm.get("uploads") as FormArray;
    const categoryList: number[] = [];
    Array.from(event.target.files).forEach((val: any, index: number) => {
      const control = PURCHASE_REQUEST_FILE_FORM(
        index,
        val.name,
        BADGES[tagSelectedIndex].text,
      );
      control.meta = {
        key: uploadControls.length + 1,
      };
      uploadControls.push(control);
      categoryList.push(tagSelectedIndex);
    });

    setFileList((old) => {
      if (Array.from(event.target.files).length > 0) {
        return [...old, ...(Array.from(event.target.files) as File[])];
      }
      return old;
    });
    setFileCategory((old) => [...old, ...categoryList]);
    setTagSelectedIndex(-1);
  };

  const getFileIcon = (file: { name: string; url?: string }) => {
    if (file.name) {
      const ext = file.name.split(".").at(-1) || "";
      if (["pdf", "doc", "docx"].includes(ext)) {
        return ICON_LIST.file;
      }
      if (["xlsx", "csv"].includes(ext)) {
        return ICON_LIST.sheet;
      }
      if (["zip", "gzip"].includes(ext)) {
        return ICON_LIST.zip;
      }
      if (["svg", "jpg", "jpeg", "webp", "gif", "avif"].includes(ext)) {
        return ICON_LIST.image;
      }
    }
    return ICON_LIST.default;
  };

  const deleteFile = (file: TaskFileSchema) => {
    setFileList((old) => old.filter((row) => row.name !== file.name));
    const uploadControls = productForm.get("uploads") as FormArray;
    let selected = -1;
    fileList.forEach((row, index) => {
      if (row.name === file.name) {
        selected = index;
      }
    });
    uploadControls.removeAt(selected);
  };

  const addNewAddress = (payload: NewCompanyAddressPayloadSchema) => {
    dispatch(newCompanyAddressStart(payload));
    setNewUserData((old) => ({
      ...old,
      name: `${payload.city} - ${payload.line}`,
      type: "ADDRESS",
    }));
    setOpenNewAddressForm(false);
  };
  const handlAddTag = (index: number) => {
    setTagSelectedIndex(index);
  };

  const loadClientAddress = (val: FilterOptionSchema[]) => {
    if (val.length > 0) {
      productForm.patchValue({ client: val[0] });
      productForm.patchValue({ client_type: "EXISTING" });
      dispatch(fetchCompanyAddressStart(val[0].id));
      setNewUserData({ name: val[0].name, type: "COMPANY" });
    } else {
      dispatch(removeCompanyAddress());
    }
  };

  useEffect(() => {
    if (isNotNullOrUndefined(newUserData?.name)) {
      if (newUserData?.type === "COMPANY") {
        const user = clients.filter((val) => val.name === newUserData?.name)[0];
        productForm.patchValue({ client: user });
        productForm.patchValue({ client_type: "NEW" });
      } else {
        const address = client_addresses.filter((val) =>
          val.name.includes(newUserData?.name || ""),
        )[0];
        productForm.patchValue({ address: address });
      }
      setNewUserData({});
    }
  }, [clients, client_addresses]);

  const addNewCompany = (payload: NewClientPayloadSchema) => {
    dispatch(newCompanyStart(payload));
    setNewUserData({ name: payload.company_name, type: "COMPANY" });
    setOpenNewClientForm(false);
  };

  return (
    <main
      className="p-24 new-task-form-section position-relative"
      style={{ overflowY: openNewClientForm ? "hidden" : "unset" }}
    >
      <div className="new-task-form-header flex-row align-items-center justify-content-space-between">
        <div className="flex-row align-items-center gap-8">
          <img
            className="new-task-image"
            alt="newTask"
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026191944-duplicate.svg"
          />
          <span className="new-task-heading">Purchase Request</span>
        </div>
        <div className="flex-row align-items-center ">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012084858-close.svg"
            alt="close"
            width={18}
            height={18}
            className="cursor-pointer"
            onClick={onClose}
          ></img>
        </div>
      </div>
      <FieldGroup
        control={productForm}
        strict={false}
        render={() => (
          <form className="purchaseRequest-form flex-column gap-12">
            <div className="purchase-request-form-row">
              <FieldControl
                name="client"
                render={({ handler }: AbstractControl) => (
                  <span className="form-data flex-column">
                    <p className="input-label xetgo-font-tag-bold">
                      Company Name
                    </p>
                    <Filter
                      option={{ key: "client", name: "Client Name" }}
                      optionList={clients}
                      multipleSelection={false}
                      onSelection={(val, _option) => {
                        loadClientAddress(val);
                      }}
                      mode="showNames"
                      showPlaceholder={false}
                      borderStyle="solid"
                      preSelected={
                        isNullOrUndefined(productForm.get("client").value)
                          ? []
                          : [productForm.get("client").value]
                      }
                      addNew={true}
                      onAddNew={() => setOpenNewClientForm(true)}
                    />
                  </span>
                )}
              />
              <FieldControl
                name="priority"
                render={() => (
                  <span className="form-data flex-column">
                    <p className="input-label xetgo-font-tag-bold">Priority</p>
                    <Filter
                      option={{ key: "priority", name: "Priority" }}
                      optionList={TASK_PRIORITY}
                      multipleSelection={false}
                      onSelection={(val, option) => {
                        productForm.patchValue({ priority: val[0] });
                      }}
                      preSelected={
                        isNullOrUndefined(productForm.get("priority").value)
                          ? []
                          : [productForm.get("priority").value]
                      }
                      mode="showNames"
                      showPlaceholder={false}
                      borderStyle="solid"
                      addNew={false}
                    />
                  </span>
                )}
              />
              <FieldControl
                name="due_date"
                render={({ handler }: AbstractControl) => (
                  <>
                    <span className="form-data flex-column ">
                      <p className="input-label xetgo-font-tag-bold">
                        Due Date
                      </p>

                      <div className="flex-row text-input">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={dayjs(
                              productForm.get("due_date").value,
                            ).format("DD-MM-YYYY")}
                            format={"DD-MM-YYYY"}
                            views={["year", "month", "day"]}
                            onChange={(newValue) =>
                              handleDueDateChange(newValue)
                            }
                          />
                        </LocalizationProvider>
                      </div>
                    </span>
                  </>
                )}
              />
            </div>
            <FieldControl
              name="description"
              render={({ handler }: AbstractControl) => (
                <span className="form-data flex-column">
                  <p className="input-label xetgo-font-tag-bold">Description</p>
                  <span className="description-input">
                    <XetEditor
                      value={productForm.get("description").value || ""}
                      defaultHeight="120px"
                      onChange={handleDescriptionChange}
                    />
                  </span>
                </span>
              )}
            />
            <FieldControl
              name="po_number"
              render={({ handler }: AbstractControl) => (
                <span className="form-data flex-column">
                  <p className="input-label xetgo-font-tag-bold">
                    Purchase Order Number
                  </p>
                  <input
                    {...handler()}
                    className="text-input px-8 py-4 xetgo-font-tag input-color"
                  />
                </span>
              )}
            />
            <FieldControl
              name="po_date"
              render={({ handler }: AbstractControl) => (
                <>
                  <span className="form-data flex-column ">
                    <p className="input-label xetgo-font-tag-bold">
                      Purchase Order Date
                    </p>

                    <div className="flex-row text-input">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dayjs(productForm.get("po_date").value).format(
                            "DD-MM-YYYY",
                          )}
                          format={"DD-MM-YYYY"}
                          views={["year", "month", "day"]}
                          onChange={(newValue) => handlePODateChange(newValue)}
                        />
                      </LocalizationProvider>
                    </div>
                  </span>
                </>
              )}
            />
            <FieldControl
              name="po_value"
              render={({ handler }: AbstractControl) => (
                <span className="form-data flex-column">
                  <p className="input-label xetgo-font-tag-bold">
                    Purchase Order Value
                  </p>
                  <input
                    {...handler()}
                    className="text-input px-8 py-4 xetgo-font-tag input-color"
                  />
                </span>
              )}
            />
            <FieldControl
              name="address"
              strict={false}
              render={() => (
                <span className="form-data flex-column">
                  <div className="flex-row align-items-center justify-content-space-between">
                    <p className=" input-label  xetgo-font-tag-bold">
                      Delivery Address
                    </p>
                  </div>
                  <Filter
                    option={{
                      key: "address",
                      name: "Address",
                    }}
                    optionList={client_addresses}
                    multipleSelection={false}
                    onSelection={(val, option) => {
                      productForm.patchValue({
                        address: val[0],
                      });
                    }}
                    mode="showNames"
                    showPlaceholder={false}
                    borderStyle="solid"
                    preSelected={
                      isNullOrUndefined(productForm.get("address").value)
                        ? []
                        : [productForm.get("address").value]
                    }
                    addNew={true}
                    onAddNew={() => {
                      setNewUserData({
                        control: productForm,
                      });
                      setOpenNewAddressForm(true);
                    }}
                  />
                </span>
              )}
            />
            {openNewAddressForm && (
              <div className="new-user-form-container position-relative">
                <NewAddressForm
                  type="CLIENT"
                  onCancel={() => setOpenNewAddressForm(false)}
                  user_id={1}
                  onAddAddress={addNewAddress}
                />
              </div>
            )}
            <FieldControl
              name="map_link"
              render={({ handler }: AbstractControl) => (
                <span className="form-data flex-column">
                  <p className="input-label xetgo-font-tag-bold">
                    Address Location
                  </p>
                  <input
                    {...handler()}
                    className="text-input px-8 py-4 xetgo-font-tag input-color"
                  />
                </span>
              )}
            />
            <FieldControl
              name="uploads"
              render={({ handler }: AbstractControl) => (
                <div className="flex-column gap-6">
                  <p className="xetgo-font-tag-bold"> File Attachment</p>
                  {productForm.get("uploads").value.length > 0 && (
                    <div className="flex-row gap-12 ">
                      <div className="file-listing flex-column gap-12 full-width">
                        {productForm
                          .get("uploads")
                          .value.map((file: TaskFileSchema, index: number) => {
                            return (
                              <div
                                className="purchase-request-file-card flex-row p-8 justify-content-space-between border-box"
                                key={index}
                              >
                                <div className="file-left flex-row gap-16 align-items-center">
                                  <img
                                    src={getFileIcon(file)}
                                    alt="file-icon"
                                    height={24}
                                    width={24}
                                    className="file-preview"
                                  />
                                  <p className="xetgo-font-tag file-name">
                                    {file.name}
                                  </p>
                                  <div
                                    style={{
                                      backgroundColor:
                                        BADGES[fileCategory[index]]
                                          .backgroundColor,
                                    }}
                                    className="xetgo-font-mini tag-box cursor-pointer px-4 py-2 flex-row justify-content-center align-items-center m-10"
                                  >
                                    <p
                                      style={{
                                        color:
                                          BADGES[fileCategory[index]].color,
                                      }}
                                    >
                                      {BADGES[fileCategory[index]].text}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex-row gap-12 align-items-center">
                                  <img
                                    className="cursor-pointer"
                                    src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012084858-close.svg"
                                    alt="close"
                                    width={16}
                                    height={16}
                                    onClick={() => deleteFile(file)}
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
              )}
            />
            <div
              className="flex-column gap-24"
              style={{ paddingBottom: "12px" }}
            >
              <div className="">
                <div className=" flex-row align-items-center">
                  {tagSelectedIndex !== -1 && (
                    <div
                      style={{
                        position: "absolute",
                        backgroundColor:
                          BADGES[tagSelectedIndex].backgroundColor,
                      }}
                      className="xetgo-font-mini tag-box cursor-pointer px-4 py-2 flex-row justify-content-center align-items-center m-10"
                    >
                      <p style={{ color: BADGES[tagSelectedIndex].color }}>
                        {BADGES[tagSelectedIndex].text}
                      </p>
                    </div>
                  )}
                  <input
                    className="text-input px-8 py-4 xetgo-font-tag-bold upload-input border-box full-width input-color"
                    placeholder={
                      tagSelectedIndex === -1
                        ? "Select tag and then upload file"
                        : ""
                    }
                    disabled={true}
                  />

                  <input
                    disabled={tagSelectedIndex === -1}
                    onChange={handleFileUpload}
                    id="fileInput"
                    name="file"
                    type="File"
                    style={{ display: "none" }}
                    multiple
                  />
                  <label htmlFor="fileInput">
                    <div
                      className={`${
                        tagSelectedIndex !== -1 &&
                        "purchase-request-upload-active"
                      } purchase-request-upload-btn xetgo-font-tag-bold  px-16 py-8 border-box cursor-pointer`}
                    >
                      Choose File
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="flex-row gap-12 tags-container px-16 py-7">
              <p className="tagAdd xetgo-font-tag">Add Tags</p>
              {BADGES.map((badge, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => handlAddTag(index)}
                    style={{ backgroundColor: badge.backgroundColor }}
                    className="xetgo-font-mini tag-box cursor-pointer px-4 py-2 flex-row justify-content-center align-items-center"
                  >
                    <p style={{ color: badge.color }}>{badge.text}</p>
                  </div>
                );
              })}
            </div>
          </form>
        )}
      />
      <div className="purchaseRequest-footer py-12 px-24">
        <button
          className="create-task-button cursor-pointer xetgo-font-tag px-10 py-4"
          onClick={handleSubmit}
        >
          Create Task
        </button>
      </div>
      {openNewClientForm && (
        <div className="new-user-form-container position-relative">
          <NewCompanyForm
            type="CLIENT"
            onCancel={() => setOpenNewClientForm(false)}
            onAddClient={addNewCompany}
          />
        </div>
      )}
      {openNewAddressForm && (
        <div className="new-user-form-container position-relative">
          <NewAddressForm
            type="CLIENT"
            onCancel={() => setOpenNewAddressForm(false)}
            user_id={productForm.get("client").value.id}
            onAddAddress={addNewAddress}
          />
        </div>
      )}
    </main>
  );
};

export default PurchaseRequestForm;
