import { toast } from "react-toastify";
import React from "react";
import "./CSVReader.scss";
import { toastOptions } from "../../../utils/toast";
import { ChangeEvent, useState } from "react";
import Papa from "papaparse";
import { CSVRow } from "../../../models/Task";

interface CSVReaderProps {
  csvDataUploaded: (parsedData: CSVRow[]) => void;
  template_link: string;
  onCsvFileAdded?: (file: any) => void;
}

const CSVReader: React.FC<CSVReaderProps> = ({
  csvDataUploaded,
  template_link,
  onCsvFileAdded,
}) => {
  const [fileName, setFileName] = useState("");

  const handleParse = (event: ChangeEvent<HTMLInputElement>) => {
    let file = null;
    const target = event?.target as HTMLInputElement;

    if (target.files && target.files.length > 0) {
      const inputFile = target.files[0];
      const fileExtension = inputFile?.type.split("/")[1];
      if (fileExtension !== "csv") {
        toast.error("Invalid File Extension", toastOptions);
        return;
      }

      file = inputFile;
      setFileName(file.name);
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = async ({ target }) => {
        if (target && target.result) {
          const csv = Papa.parse(target.result as any, {
            header: true,
          });
          const parsedData = csv?.data as CSVRow[];
          csvDataUploaded(parsedData);
        }
      };
      reader.readAsText(file);
      if (onCsvFileAdded) {
        onCsvFileAdded(file);
      }
    } else {
      toast.error("Enter valid file", toastOptions);
    }
  };

  return (
    <div className="flex-column gap-12">
      <div className="upload-csv-container flex-row">
        <input
          className="xetgo-font-tag-bolder csv-name py-4 px-8"
          placeholder="No File Choosen"
          value={fileName}
          disabled={true}
        />
        <input
          onChange={handleParse}
          id="csvInput"
          name="file"
          type="File"
          accept=".csv"
          style={{ display: "none" }}
        />
        <label htmlFor="csvInput">
          <div className="upload-csv xetgo-font-tag py-5 px-16 cursor-pointer">
            Upload CSV
          </div>
        </label>
      </div>
      <a
        href={template_link}
        target="_blank"
        rel="noreferrer"
        className="xetgo-font-tag-bold download-csv-template"
      >
        Download CSV Template
      </a>
    </div>
  );
};

export default CSVReader;
