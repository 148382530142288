import { put, call, takeLatest, all } from "redux-saga/effects";
import URL from "../../urls/Urls";
import ApiService from "../../services/apiService";
import {
  AddProductDeliveryScheduleStartAction,
  AddPurchaseProductStartAction,
  AddPurchaseRequestCommentAction,
  AddTaskCommentAction,
  AddTaskProductStartAction,
  AddVendorDetailAction,
  CreatePurchaseRequestAction,
  CreateTaskAction,
  DeleteProductDeliveryScheduleStartAction,
  DeletePurchaseRequestVendorAction,
  DeleteTask,
  DeleteTaskAttachmentStartAction,
  DeleteTaskProduct,
  DeleteVendorDetailAction,
  FetchCompanyAddressStartAction,
  FetchCompanyPocStartAction,
  FetchFilterAction,
  FetchPurchaseRequestAction,
  FetchPurchaseRequestCommentAction,
  FetchTaskDetailAction,
  FetchTasksAction,
  FilterPayload,
  NewCompanyAddressStartAction,
  NewCompanyStartAction,
  NewUserStartAction,
  OmsActions,
  PurchaseOrderDetailAction,
  UpdateCoaAction,
  UpdateDeliveryDetailsStartAction,
  UpdatePurchaseOrderDetailStartAction,
  UpdatePurchaseOrderProductStartAction,
  UpdateTaskAttachmentsStartAction,
  UpdateTaskDetailStartAction,
  UpdateTaskPOCAction,
  UpdateVendorDetailAction,
} from "./omsTypes";
import {
  fetchFilterFailure,
  fetchFilterSuccess,
  fetchTasksSuccess,
  fetchTasksFailure,
  newUserSuccess,
  newUserFailure,
  newCompanyAddressSuccess,
  newCompanyAddressFailure,
  fetchCompanyAddressFailure,
  fetchCompanyAddressSuccess,
  newCompanySuccess,
  newCompanyFailure,
  fetchTaskDetailFailure,
  fetchTaskDetailSuccess,
  updateTaskDetailSuccess,
  updateTaskDetailFailure,
  createTaskFailure,
  createTaskSuccess,
  AddTaskProductSuccess,
  AddTaskProductFailure,
  updateTaskPOCSuccess,
  updateTaskPOCFailure,
  updateVendorDetailsSuccess,
  updateVendorDetailsFailure,
  deleteVendorDetailsSuccess,
  deleteVendorDetailsFailure,
  addVendorDetailSuccess,
  addVendorDetailFailure,
  addTaskCommentSuccess,
  addTaskCommentFailure,
  sampleAddressSuccess,
  sampleAddressFailure,
  updateCoaSuccess,
  updateCoaFailure,
  fetchCompanyPocSuccess,
  fetchCompanyPocFailure,
  deleteTaskProductSuccess,
  deleteTaskProductFailure,
  fetchPurchaseRequestsSuccess,
  fetchPurchaseRequestsFailure,
  createPurchaseRequestSuccess,
  createPurchaseRequestFailure,
  purchaseOrderDetailFailure,
  purchaseOrderDetailSuccess,
  addProductDeliveryScheduleSuccess,
  addProductDeliveryScheduleFailure,
  deleteTaskSuccess,
  deleteTaskFailure,
  updatePurchaseOrderDetailFailure,
  updatePurchaseOrderDetailSuccess,
  addPurchaseRequestCommentSuccess,
  addPurchaseRequestCommentFailure,
  updatePurchaseOrderProductFailure,
  updatePurchaseOrderProductSuccess,
  updateDeliveryDetailsSuccess,
  updateDeliveryDetailsFailure,
  deleteProductDeliveryScheduleSuccess,
  deleteProductDeliveryScheduleFailure,
  fetchPurchaseRequestCommentSuccess,
  fetchPurchaseRequestCommentFailure,
  updateTaskAttachmentsSuccess,
  updateTaskAttachmentsFailure,
  deleteTaskAttachmentSuccess,
  deleteTaskAttachmentFailure,
  deletePurchaseRequestVendorSuccess,
  deletePurchaseRequestVendorFailure,
} from "./omsActions";
import { PurchaseOrderDetail, TaskCard, TaskDetail } from "../../models/Task";

// FILTER SAGA

function* getFiltersSaga(action: FetchFilterAction) {
  const { category } = action.payload;
  try {
    const { data } = yield call(
      ApiService().client.get,
      URL.OMS.GET_FILTERS(category),
    );
    const filters: FilterPayload = data;
    yield put(fetchFilterSuccess(filters));
  } catch (error) {
    yield put(fetchFilterFailure("Error fetching user data."));
  }
}

function* watchGetFilters() {
  yield takeLatest(OmsActions.FETCH_FILTER_LIST, getFiltersSaga);
}

function* newUserSaga(action: NewUserStartAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.USERS.NEW,
      action.payload,
    );
    yield put(newUserSuccess(data));
  } catch (error) {
    yield put(newUserFailure("Error fetching tasks."));
  }
}

function* watchNewUser() {
  yield takeLatest(OmsActions.NEW_USER_START, newUserSaga);
}

function* newCompanySaga(action: NewCompanyStartAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.COMPANY.NEW,
      action.payload,
    );
    yield put(newCompanySuccess(data));
  } catch (error) {
    yield put(newCompanyFailure("Error fetching tasks."));
  }
}

function* watchNewCompany() {
  yield takeLatest(OmsActions.NEW_COMPANY_START, newCompanySaga);
}

function* getCompanyPocSaga(action: FetchCompanyPocStartAction) {
  try {
    const { data } = yield call(
      ApiService().client.get,
      URL.COMPANY.GET_POCS(action.payload.company_id),
    );
    yield put(fetchCompanyPocSuccess(data));
  } catch (error) {
    yield put(fetchCompanyPocFailure("Error fetching company poc"));
  }
}

function* watchCompanyPoc() {
  yield takeLatest(OmsActions.FETCH_COMPANY_POC_START, getCompanyPocSaga);
}

function* newCompanyAddressSaga(action: NewCompanyAddressStartAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.USERS.NEW_ADDRESS(action.payload.user_id),
      action.payload,
    );
    yield put(newCompanyAddressSuccess(data));
  } catch (error) {
    yield put(newCompanyAddressFailure("Error fetching tasks."));
  }
}

function* watchNewCompanyAddress() {
  yield takeLatest(OmsActions.NEW_COMPANY_ADDRESS_START, newCompanyAddressSaga);
}

function* fetchCompanyAddressSaga(action: FetchCompanyAddressStartAction) {
  try {
    const { data } = yield call(
      ApiService().client.get,
      URL.USERS.FETCH_ADDRESS(action.payload || -1),
    );
    yield put(fetchCompanyAddressSuccess(data.list));
  } catch (error) {
    yield put(fetchCompanyAddressFailure("Error fetching tasks."));
  }
}

function* watchFetchCompanyAddress() {
  yield takeLatest(
    OmsActions.FETCH_COMPANY_ADDRESS_START,
    fetchCompanyAddressSaga,
  );
}

function* getTasksSaga(action: FetchTasksAction) {
  const { category } = action.payload;
  try {
    const { data } = yield call(
      ApiService().client.get,
      `${URL.OMS.GET_TASKS(category)}`,
      {
        params: {
          status: action.payload.status,
        },
      },
    );
    yield put(
      fetchTasksSuccess({
        tasks: data.tasks,
        statuses: data.statuses,
      }),
    );
  } catch (error) {
    yield put(fetchTasksFailure("Error fetching tasks."));
  }
}

function* watchGetTasks() {
  yield takeLatest(OmsActions.FETCH_TASKS, getTasksSaga);
}

function* getTaskDetailSaga(action: FetchTaskDetailAction) {
  try {
    let url = `${URL.OMS.GET_TASK_DETAILS(
      action.payload.id,
      action.payload.category,
    )}`;
    if (action.payload.category === "sampling") {
      url = `${URL.OMS.GET_SAMPLING_TASK_DETAILS(
        action.payload.id,
        action.payload.category,
        action.payload.vendor_id,
      )}`;
    }
    const response: { data: TaskDetail } = yield call(
      ApiService().client.get,
      url,
    );
    yield put(fetchTaskDetailSuccess(response.data));
  } catch (error) {
    yield put(fetchTaskDetailFailure("Error fetching tasks."));
  }
}

function* watchFetchTaskDetail() {
  yield takeLatest(OmsActions.FETCH_TASK_DETAIL, getTaskDetailSaga);
}

function* updateTaskDetailSaga(action: UpdateTaskDetailStartAction) {
  try {
    const { data } = yield call(
      ApiService().client.put,
      URL.OMS.UPDATE_TASK_DETAILS(action.payload?.id || -1),
      action.payload,
    );
    yield put(updateTaskDetailSuccess(data.response));
  } catch (error) {
    yield put(updateTaskDetailFailure("Error fetching tasks."));
  }
}

function* watchUpdateTaskDetail() {
  yield takeLatest(OmsActions.UPDATE_TASK_DETAIL_START, updateTaskDetailSaga);
}

function* updateTaskAttachmentsSaga(action: UpdateTaskAttachmentsStartAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.OMS.UPLOAD_TASK_FILE(action.payload?.taskId || -1),
      action.payload?.attachments,
    );
    yield put(updateTaskAttachmentsSuccess(data));
  } catch (error) {
    yield put(updateTaskAttachmentsFailure("Error updating task attachments."));
  }
}

function* watchUpdateTaskAttachments() {
  yield takeLatest(
    OmsActions.UPDATE_TASK_ATTACHMENTS_START,
    updateTaskAttachmentsSaga,
  );
}

function* deleteTaskAttachmentSaga(action: DeleteTaskAttachmentStartAction) {
  try {
    const { data } = yield call(
      ApiService().client.delete,
      URL.OMS.DELETE_TASK_FILE(
        action.payload?.taskId || -1,
        action.payload?.asset_record_id || -1,
      ),
    );
    yield put(deleteTaskAttachmentSuccess(data.data));
  } catch (error) {
    yield put(deleteTaskAttachmentFailure("Error updating task attachments."));
  }
}

function* watchDeleteTaskAttachment() {
  yield takeLatest(
    OmsActions.DELETE_TASK_ATTACHMENT_START,
    deleteTaskAttachmentSaga,
  );
}

function* createTask(action: CreateTaskAction) {
  try {
    const res: { data: { detail: TaskCard }; status: number } = yield call(
      ApiService().client.post,
      URL.OMS.CREATE_NEW_TASK,
      action.payload,
    );
    if (res.status === 201) {
      yield put(createTaskSuccess(res.data.detail));
    } else {
      yield put(createTaskFailure("Task creation failed. Please refresh."));
    }
  } catch (error) {
    yield put(createTaskFailure("Task creation failed. Please refresh."));
  }
}

export function* watchCreateTask() {
  yield takeLatest(OmsActions.CREATE_TASK, createTask);
}

function* AddTaskProductSaga(action: AddTaskProductStartAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.OMS.ADD_TASK_PRODUCT(
        parseInt(action.payload.get("id")?.toString() || "-1"),
      ),
      action.payload,
    );
    yield put(AddTaskProductSuccess(data));
  } catch (error) {
    yield put(AddTaskProductFailure("Error adding products ."));
  }
}

function* watchAddTaskProduct() {
  yield takeLatest(OmsActions.ADD_TASK_PRODUCT_START, AddTaskProductSaga);
}

function* updateTaskPOC(action: UpdateTaskPOCAction) {
  try {
    const { data } = yield call(
      ApiService().client.put,
      URL.OMS.UPDATE_TASK_POCS(action.payload.id),
      action.payload,
    );
    yield put(updateTaskPOCSuccess(data.pocs));
  } catch (error) {
    yield put(updateTaskPOCFailure("POC Update failed. Please refresh."));
  }
}

export function* watchUpdateTaskPOC() {
  yield takeLatest(OmsActions.UPDATE_TASK_POC, updateTaskPOC);
}

function* addVendorDetail(action: AddVendorDetailAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.OMS.ADD_VENDOR_DETAIL(action.payload.sourcingId),
      action.payload,
    );
    yield put(addVendorDetailSuccess(data.response));
  } catch (error) {
    yield put(
      addVendorDetailFailure("Vendor Detail is not added. Please refresh."),
    );
  }
}

export function* watchAddVendorDetail() {
  yield takeLatest(OmsActions.ADD_VENDOR_DETAIL, addVendorDetail);
}

function* updateVendorDetails(action: UpdateVendorDetailAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.OMS.UPDATE_VENDOR_DETAILS,
      action.payload,
    );
    yield put(updateVendorDetailsSuccess(action.payload));
  } catch (error) {
    yield put(
      updateVendorDetailsFailure(
        "Vendor Detail Update Failed. Please refresh.",
      ),
    );
  }
}

export function* watchUpdateVendorDetails() {
  yield takeLatest(OmsActions.UPDATE_VENDOR_DETAIL, updateVendorDetails);
}

function* deleteVendorDetails(action: DeleteVendorDetailAction) {
  try {
    const { data } = yield call(
      ApiService().client.delete,
      URL.OMS.DELETE_VENDOR_DETAIL(action.payload.sourcingId),
      { data: action.payload },
    );
    yield put(
      deleteVendorDetailsSuccess({
        tpm_id: action.payload.tpm_id,
        vendor_id: action.payload.vendor_id,
      }),
    );
  } catch (error) {
    yield put(
      deleteVendorDetailsFailure(
        "Vendor Detail Deletion Failed. Please refresh.",
      ),
    );
  }
}

export function* watchDeleteVendorDetails() {
  yield takeLatest(OmsActions.DELETE_VENDOR_DETAIL, deleteVendorDetails);
}

function* addTaskComment(action: AddTaskCommentAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.OMS.ADD_TASK_COMMENT(
        parseInt((action.payload.get("task_id") || "").toString()),
      ),
      action.payload,
    );
    yield put(addTaskCommentSuccess(data));
  } catch (error) {
    yield put(addTaskCommentFailure("Comment addition failed!!"));
  }
}

export function* watchAddTaskComment() {
  yield takeLatest(OmsActions.ADD_TASK_COMMENT, addTaskComment);
}

function* addPurchaseRequestComment(action: AddPurchaseRequestCommentAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.OMS.ADD_PURCHASE_REQUEST_COMMENT(
        parseInt((action.payload.get("purchase_request_id") || "").toString()),
      ),
      action.payload,
    );
    yield put(addPurchaseRequestCommentSuccess(data));
  } catch (error) {
    yield put(addPurchaseRequestCommentFailure("Comment addition failed!!"));
  }
}

export function* watchAddPurchaseRequestComment() {
  yield takeLatest(
    OmsActions.ADD_PURCHASE_REQUEST_COMMENT,
    addPurchaseRequestComment,
  );
}

function* addSampleAddress(action: AddTaskCommentAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.OMS.ADD_SAMPLE_ADDRESS(
        parseInt((action.payload.get("task_id") || "").toString()),
      ),
      action.payload,
    );
    yield put(sampleAddressSuccess(data.addressMap));
  } catch (error) {
    yield put(sampleAddressFailure("Sample address addition failed!!"));
  }
}

export function* watchAddSampleAddress() {
  yield takeLatest(OmsActions.SAMPLE_ADDRESS_START, addSampleAddress);
}

function* updateCoaSaga(action: UpdateCoaAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.OMS.UPDATE_COA(Number(action.payload.get("tpm_id"))),
      action.payload,
    );
    yield put(updateCoaSuccess(data.data));
  } catch (error) {
    yield put(updateCoaFailure("Error adding coa."));
  }
}

function* watchUpdateCoa() {
  yield takeLatest(OmsActions.UPDATE_COA, updateCoaSaga);
}

function* deleteTaskProduct(action: DeleteTaskProduct) {
  try {
    const { data } = yield call(
      ApiService().client.delete,
      URL.OMS.DELETE_TASK_PRODUCT(action.payload.tpm_id),
      { data: action.payload },
    );
    yield put(
      deleteTaskProductSuccess({
        tpm_id: action.payload.tpm_id,
      }),
    );
  } catch (error) {
    yield put(
      deleteTaskProductFailure("Product Deletion Failed. Please refresh."),
    );
  }
}

export function* watchDeleteTaskProduct() {
  yield takeLatest(OmsActions.DELETE_TASK_PRODUCT, deleteTaskProduct);
}

function* deleteTask(action: DeleteTask) {
  try {
    yield call(
      ApiService().client.delete,
      URL.OMS.DELETE_TASK(action.payload.id),
    );
    yield put(deleteTaskSuccess("Success"));
  } catch (error) {
    yield put(deleteTaskFailure("RFQ deletion Failed. Please refresh."));
  }
}

export function* watchDeleteTask() {
  yield takeLatest(OmsActions.DELETE_TASK, deleteTask);
}

function* purchaseOrderDetailSaga(action: PurchaseOrderDetailAction) {
  try {
    const response: { data: PurchaseOrderDetail } = yield call(
      ApiService().client.get,
      URL.OMS.GET_PURCHASE_ORDER_DETAILS(
        action.payload.id,
        action.payload.category,
        action.payload.vendor_id,
      ),
    );
    yield put(purchaseOrderDetailSuccess(response.data));
  } catch (error) {
    yield put(
      purchaseOrderDetailFailure("Error fetching Purchase Order Details."),
    );
  }
}

function* watchPurchaseOrderDetail() {
  yield takeLatest(OmsActions.PURCHASE_ORDER_DETAIL, purchaseOrderDetailSaga);
}

// PURCHASE REQUESTS ///

function* getPurchaseRequestSaga(action: FetchPurchaseRequestAction) {
  const { category } = action.payload;
  try {
    const { data } = yield call(
      ApiService().client.get,
      `${URL.OMS.GET_PURCHASE_REQUESTS(category)}`,
    );
    yield put(
      fetchPurchaseRequestsSuccess({
        purchase_requests: data.purchase_requests,
        statuses: data.statuses,
      }),
    );
  } catch (error) {
    yield put(fetchPurchaseRequestsFailure("Error fetching tasks."));
  }
}

function* watchGetPurchaseRequests() {
  yield takeLatest(OmsActions.FETCH_PURCHASE_REQUESTS, getPurchaseRequestSaga);
}

function* createPurchaserequest(action: CreatePurchaseRequestAction) {
  try {
    const res: { data: { detail: TaskCard }; status: number } = yield call(
      ApiService().client.post,
      URL.OMS.CREATE_PURCHASE_REQUEST,
      action.payload,
    );
    if (res.status === 201 || res.status === 200) {
      yield put(createPurchaseRequestSuccess(res.data.detail));
    } else {
      yield put(
        createPurchaseRequestFailure(
          "Purchase Request creation failed. Please refresh.",
        ),
      );
    }
  } catch (error) {
    yield put(
      createPurchaseRequestFailure(
        "Purchase Request creation failed. Please refresh.",
      ),
    );
  }
}

export function* watchCreatePurchaseRequest() {
  yield takeLatest(OmsActions.CREATE_PURCHASE_REQUEST, createPurchaserequest);
}

function* addPurchaseProductSaga(action: AddPurchaseProductStartAction) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.OMS.ADD_PURCHASE_PRODUCT(
        parseInt(action.payload.get("id")?.toString() || "-1"),
      ),
      action.payload,
    );
    yield put(AddTaskProductSuccess(data));
  } catch (error) {
    yield put(AddTaskProductFailure("Error adding products ."));
  }
}

function* watchAddPurchaseProduct() {
  yield takeLatest(
    OmsActions.ADD_PURCHASE_PRODUCT_START,
    addPurchaseProductSaga,
  );
}

function* addProductDeliveryScheduleSaga(
  action: AddProductDeliveryScheduleStartAction,
) {
  try {
    const { data } = yield call(
      ApiService().client.post,
      URL.OMS.ADD_PRODUCT_DELIVERY_SCHEDULE(
        parseInt(action.payload.get("prtpm_id")?.toString() || "-1"),
      ),
      action.payload,
    );
    yield put(addProductDeliveryScheduleSuccess(data.schedule));
  } catch (error) {
    yield put(addProductDeliveryScheduleFailure("Error adding products ."));
  }
}

function* watchAddProductDeliverySchedule() {
  yield takeLatest(
    OmsActions.ADD_PRODUCT_DELIVERY_SCHEDULE_START,
    addProductDeliveryScheduleSaga,
  );
}

function* updatePurchaseOrderDetailSaga(
  action: UpdatePurchaseOrderDetailStartAction,
) {
  try {
    const { data } = yield call(
      ApiService().client.put,
      URL.OMS.UPDATE_PURCHASE_ORDER_DETAILS(
        action.payload.id,
        action.payload.category,
      ),
      action.payload,
    );
    yield put(updatePurchaseOrderDetailSuccess(data.response));
  } catch (error) {
    yield put(
      updatePurchaseOrderDetailFailure("Error fetching purchase order."),
    );
  }
}

function* watchUpdatePurchaseOrderDetail() {
  yield takeLatest(
    OmsActions.UPDATE_PURCHASE_ORDER_DETAIL_START,
    updatePurchaseOrderDetailSaga,
  );
}

function* watchUpdatePurchaseOrderProductSaga(
  action: UpdatePurchaseOrderProductStartAction,
) {
  try {
    const { data } = yield call(
      ApiService().client.put,
      URL.OMS.UPDATE_PURCHASE_ORDER_PRODUCT(
        action.payload.id,
        action.payload.prtpm_id,
      ),
      action.payload,
    );
    yield put(updatePurchaseOrderProductSuccess(data));
  } catch (error) {
    yield put(
      updatePurchaseOrderProductFailure("Error fetching purchase order."),
    );
  }
}

function* watchUpdatePurchaseOrderProduct() {
  yield takeLatest(
    OmsActions.UPDATE_PURCHASE_ORDER_PRODUCT_START,
    watchUpdatePurchaseOrderProductSaga,
  );
}

function* watchUpdateDeliveryDetailsSaga(
  action: UpdateDeliveryDetailsStartAction,
) {
  try {
    yield call(
      ApiService().client.put,
      URL.OMS.UPDATE_DELIVERY_DETAILS(
        action.payload.id,
        action.payload.prtpm_id,
      ),
      action.payload,
    );
    yield put(updateDeliveryDetailsSuccess(action.payload));
  } catch (error) {
    yield put(updateDeliveryDetailsFailure("Error fetching purchase order."));
  }
}

function* watchUpdateDeliveryDetails() {
  yield takeLatest(
    OmsActions.UPDATE_DELIVERY_DETAILS_START,
    watchUpdateDeliveryDetailsSaga,
  );
}

function* deleteDeliveryScheduleSaga(
  action: DeleteProductDeliveryScheduleStartAction,
) {
  try {
    yield call(
      ApiService().client.delete,
      URL.OMS.DELETE_DELIVERY(action.payload),
    );
    yield put(deleteProductDeliveryScheduleSuccess(action.payload));
  } catch (error) {
    yield put(
      deleteProductDeliveryScheduleFailure("Error fetching purchase order."),
    );
  }
}

function* watchDeleteDeliverySchedule() {
  yield takeLatest(
    OmsActions.DELETE_PRODUCT_DELIVERY_SCHEDULE_START,
    deleteDeliveryScheduleSaga,
  );
}
function* fetchPurchaseRequestComment(
  action: FetchPurchaseRequestCommentAction,
) {
  try {
    const { data } = yield call(
      ApiService().client.get,
      URL.OMS.FETCH_PURCHASE_COMMENT(
        action.payload.po_id,
        action.payload.vendor_id,
      ),
    );
    yield put(fetchPurchaseRequestCommentSuccess(data.comments));
  } catch (error) {
    yield put(
      fetchPurchaseRequestCommentFailure("Error fetching purchase order."),
    );
  }
}

function* watchFetchPurchaseComment() {
  yield takeLatest(
    OmsActions.FETCH_PURCHASE_REQUEST_COMMENT,
    fetchPurchaseRequestComment,
  );
}

function* deletePurchaseRequestVendor(
  action: DeletePurchaseRequestVendorAction,
) {
  try {
    const { data } = yield call(
      ApiService().client.delete,
      URL.OMS.DELETE_PURCHASE_REQUEST_VENDOR(
        action.payload.po_id,
        action.payload.vendor_id,
      ),
    );
    yield put(deletePurchaseRequestVendorSuccess(action.payload.vendor_id));
  } catch (error) {
    yield put(
      deletePurchaseRequestVendorFailure("Error fetching purchase order."),
    );
  }
}

function* watchDeletePurchaseRequestVendor() {
  yield takeLatest(
    OmsActions.DELETE_PURCHASE_REQUEST_VENDOR,
    deletePurchaseRequestVendor,
  );
}

export default function* omsSaga() {
  yield all([
    watchGetFilters(),
    watchGetTasks(),
    watchNewUser(),
    watchNewCompanyAddress(),
    watchFetchCompanyAddress(),
    watchNewCompany(),
    watchFetchTaskDetail(),
    watchUpdateTaskDetail(),
    watchCreateTask(),
    watchAddTaskProduct(),
    watchUpdateTaskPOC(),
    watchUpdateVendorDetails(),
    watchDeleteVendorDetails(),
    watchAddVendorDetail(),
    watchAddTaskComment(),
    watchAddSampleAddress(),
    watchUpdateCoa(),
    watchCompanyPoc(),
    watchDeleteTaskProduct(),
    watchPurchaseOrderDetail(),
    watchGetPurchaseRequests(),
    watchCreatePurchaseRequest(),
    watchAddPurchaseProduct(),
    watchAddProductDeliverySchedule(),
    watchDeleteTask(),
    watchUpdatePurchaseOrderDetail(),
    watchAddPurchaseRequestComment(),
    watchUpdatePurchaseOrderProduct(),
    watchUpdateDeliveryDetails(),
    watchDeleteDeliverySchedule(),
    watchFetchPurchaseComment(),
    watchUpdateTaskAttachments(),
    watchDeleteTaskAttachment(),
    watchDeletePurchaseRequestVendor(),
  ]);
}
