import React, { useEffect, useState } from "react";
import "./NewRFQForm.scss";
import { useSelector } from "react-redux";
import {
  selectClientAddresses,
  selectClientPocs,
  selectFilterOptions,
} from "../../../redux/oms/omsSelectors";
import { filters } from "../Options/Filter/Filter.data";
import Filter from "../Options/Filter/Filter";
import {
  ClientPOC,
  FilterOptionSchema,
  NewClientPayloadSchema,
  NewCompanyAddressPayloadSchema,
  POCSchema,
} from "../../../redux/oms/omsTypes";
import XetEditor from "../../shared/xet-editor/XetEditor";
import { useDispatch } from "react-redux";
import {
  fetchCompanyAddressStart,
  fetchCompanyPocStart,
  newCompanyAddressStart,
  newCompanyStart,
  removeCompanyAddress,
} from "../../../redux/oms/omsActions";
import {
  NEW_CONTACT_FORM,
  NEW_FILE_FORM,
  NEW_RFQ_FORM,
  TASK_PRIORITY,
} from "../../../models/RFQ";
import {
  AbstractControl,
  FormArray,
  FieldGroup,
  FieldControl,
  FieldArray,
  FormBuilder,
  Validators,
} from "react-reactive-form";
import NewAddressForm from "../NewAddressForm/NewAddressForm";
import NewCompanyForm from "../NewCompanyForm/NewCompanyForm";
import { isNullOrUndefined, round } from "../../../utils/Helper";
import AddProduct from "../../addProduct/AddProduct";
import {
  ProductDetailSchema,
  TaskDetail,
  TaskFileSchema,
} from "../../../models/Task";
import { toast } from "react-toastify";
import { toastOptions } from "../../../utils/toast";
import TaskFiles from "../TaskFiles/TaskFiles";

interface NewRFQFormProps {
  onSubmit: (task: FormData) => void;
  onClose: () => void;
  preFilledData?: TaskDetail | null;
}

const NewRFQForm: React.FC<NewRFQFormProps> = ({
  onSubmit,
  onClose,
  preFilledData,
}) => {
  const dispatch = useDispatch();
  const [productForm, _setProductForm] = useState(NEW_RFQ_FORM);
  const clients = useSelector((state) =>
    selectFilterOptions(state, filters[1].key),
  );
  const clientPocs = useSelector(selectClientPocs);
  const client_addresses = useSelector(selectClientAddresses);
  const [newUserData, setNewUserData] = useState<{
    name?: string;
    type?: string;
    control?: AbstractControl;
  }>();
  const [fileList, setFileList] = useState<File[]>([]);
  const [openNewClientForm, setOpenNewClientForm] = useState(false);
  const [openNewAddressForm, setOpenNewAddressForm] = useState(false);
  const [descriptionTracker, setDescriptionTracker] = useState("");
  const [dataFilled, setDataFilled] = useState<boolean>(false);

  useEffect(() => {
    if (preFilledData) {
      initializeFormWithPrefilledData(preFilledData);
    } else {
      setDataFilled(true);
    }
  }, [preFilledData]);

  useEffect(() => {
    if (client_addresses.length > 0) {
      if (!dataFilled && preFilledData) {
        const addressForm = productForm.get("address") as FormArray;
        while (addressForm.length > 0) {
          addressForm.removeAt(0);
        }
        const result: { [key: number]: AbstractControl } = {};
        preFilledData.product_details.map((productDetail) => {
          const addressId = productDetail.address_id;
          const address = client_addresses.find((val) => val.id === addressId);
          if (address) {
            productDetail.products.forEach((product, index) => {
              addProductToForm(
                result,
                product,
                address,
                `${addressId}-${Math.round(round(Math.random(), 2) * 100)}`,
              );
            });
          }
        });
        const values = Object.values(result);
        values.forEach((row: AbstractControl, index: number) => {
          row.meta = {
            key: index,
          };
          addressForm.push(row);
        });
      }
      setDataFilled(true);
    }
  }, [client_addresses]);

  const handleSubmit = () => {
    const taskFormData = new FormData();
    taskFormData.append(
      "name",
      productForm.get("name").value || preFilledData?.title,
    );
    taskFormData.append(
      "priority",
      JSON.stringify(
        productForm.get("priority").value || { name: preFilledData?.priority },
      ),
    );
    taskFormData.append(
      "description",
      descriptionTracker || (preFilledData?.description as string),
    );
    taskFormData.append(
      "client_type",
      productForm.get("client_type").value || preFilledData?.client_type,
    );
    taskFormData.append(
      "client",
      JSON.stringify(
        productForm.get("client").value || preFilledData?.client_detail,
      ),
    );
    taskFormData.append(
      "pocs",
      JSON.stringify(productForm.get("poc").value || preFilledData?.pocs),
    );
    taskFormData.append(
      "address",
      JSON.stringify(productForm.get("address").value),
    );

    const displayNames = fileList.map((file) => file.name);
    const categories = (productForm.get("uploads") as FormArray)
      .getRawValue()
      .map((val) => val.category);

    fileList.forEach((file, index) => {
      taskFormData.append("files[]", file);
      const displayName = displayNames[index]?.trim();
      const category = categories[index]?.trim();
      if (displayName) {
        taskFormData.append("displayNames[]", displayName);
        taskFormData.append("tags[]", category);
      }
    });

    // Mandatory Conditions

    const totalFileSize = fileList.reduce((acc, file) => acc + file.size, 0);
    const maxFileSize = 18 * 1024 * 1024; // 18MB in bytes

    if (totalFileSize > maxFileSize) {
      toast.error(
        "Cannot create record. Total file size exceeds 18MB.",
        toastOptions,
      );
      return;
    }

    if (!taskFormData.get("name")) {
      toast.error("Please Fill Title", toastOptions);
      return;
    }

    if (!taskFormData.get("description")) {
      toast.error("Please Fill Description", toastOptions);
      return;
    }

    const priority = taskFormData.get("priority");
    if (priority === "{}" || !priority || isNullOrUndefined(priority)) {
      toast.error("Please Select Priority", toastOptions);
      return;
    }

    onSubmit(taskFormData);
    resetForm();
  };

  const resetForm = () => {
    NEW_RFQ_FORM.reset();
    const uploads = productForm.get("uploads") as FormArray;
    for (let i = 0; i <= uploads.length; i++) {
      uploads.removeAt(0);
    }
    uploads.removeAt(0);
    setDescriptionTracker("");
  };

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleFileChange = (event: any, tagValue: string) => {
    const uploadControls = productForm.get("uploads") as FormArray;
    Array.from(event.target.files).forEach((val: any, index: number) => {
      const control = NEW_FILE_FORM(index, val.name, tagValue);
      control.meta = {
        key: uploadControls.length + 1,
      };
      uploadControls.push(control);
    });
    setFileList((val) => [...val.concat(Array.from(event.target.files))]);
  };

  const deleteFile = (file: TaskFileSchema) => {
    setFileList((old) => old.filter((row) => row.name !== file.name));
    const uploadControls = productForm.get("uploads") as FormArray;
    let selected = -1;
    fileList.forEach((row, index) => {
      if (row.name === file.name) {
        selected = index;
      }
    });
    uploadControls.removeAt(selected);
  };

  const setCurrentCompanyPoc = (company_name: string) => {
    const currentCompany = clients.find(
      (client) => client.name === company_name,
    );
    const companyPoc = clientPocs.find(
      (poc) => poc.company_id === currentCompany?.id,
    );

    const updatedFormData = {
      poc: [
        {
          name: companyPoc?.name,
          mobile: companyPoc?.phone_number,
          email: companyPoc?.email,
          designation: companyPoc?.designation,
        },
      ],
    };
    productForm.patchValue(updatedFormData);
  };

  const addNewCompany = (payload: NewClientPayloadSchema) => {
    dispatch(newCompanyStart(payload));
    setNewUserData({ name: payload.company_name, type: "COMPANY" });
    setOpenNewClientForm(false);
    productForm.patchValue({ client_type: "NEW" });
  };

  const addNewAddress = (payload: NewCompanyAddressPayloadSchema) => {
    dispatch(newCompanyAddressStart(payload));
    setNewUserData((old) => ({
      ...old,
      name: `${payload.city} - ${payload.line}`,
      type: "ADDRESS",
    }));
    setOpenNewAddressForm(false);
  };

  const loadClientAddress = (val: FilterOptionSchema[]) => {
    if (val.length > 0) {
      productForm.patchValue({ client: val[0] });
      dispatch(fetchCompanyAddressStart(val[0].id));
      dispatch(fetchCompanyPocStart(val[0].id));
      setNewUserData({ name: val[0].name, type: "COMPANY" });
    } else {
      dispatch(removeCompanyAddress());
    }
  };

  const handleDescriptionChange = (description: string) => {
    setDescriptionTracker(description);
  };

  const handleCsvFile = (csvFile: File) => {
    const uploadControls = productForm.get("uploads") as FormArray;
    const control = NEW_FILE_FORM(
      uploadControls.length + 1,
      csvFile.name,
      "MISCELLANEOUS",
    );
    control.meta = {
      key: uploadControls.length + 1,
    };
    uploadControls.push(control);
    setFileList((val) => [...val, csvFile]);
  };

  useEffect(() => {
    if (newUserData?.control || newUserData?.name) {
      if (newUserData?.type === "COMPANY") {
        const user = clients.filter((val) => val.name === newUserData?.name)[0];
        productForm.patchValue({ client: user });
        setCurrentCompanyPoc(user.name);
      } else {
        const address = client_addresses.filter(
          (val) => val.name === newUserData?.name,
        )[0];
        newUserData?.control?.patchValue({ delivery: address });
      }
      setNewUserData({});
    }
  }, [clients, clientPocs, client_addresses]);

  const initializeFormWithPrefilledData = (data: TaskDetail) => {
    productForm.patchValue({ name: data.title });
    productForm.patchValue({ priority: { name: data.priority } });
    productForm.patchValue({ description: data.description });

    // client and address details
    productForm.patchValue({ client: data.client_detail });

    // POC CONTROLS
    const pocs = data.pocs;
    const pocControls = productForm.get("poc") as FormArray;
    while (pocControls.length) {
      pocControls.removeAt(0);
    }
    pocs.forEach((poc, index) => {
      const newControl = FormBuilder.group({
        name: poc.name || "",
        mobile: poc.mobile || "",
        email: poc.email || "",
        designation: poc.designation || "",
      });

      newControl.meta = {
        key: index + 1,
      };

      pocControls.push(newControl);
    });

    // UPLOAD CONTROLS

    const uploadControls = productForm.get("uploads") as FormArray;
    while (uploadControls.length) {
      uploadControls.removeAt(0);
    }

    data.assets.forEach((asset, index) => {
      const newAssetControl = FormBuilder.group({
        id: asset.id,
        name: asset.name,
        url: asset.url,
        category: asset.category,
      });

      newAssetControl.meta = {
        key: index + 1,
      };

      uploadControls.push(newAssetControl);
    });
  };

  const addProductToForm = (
    result: any,
    product: ProductDetailSchema,
    address: FilterOptionSchema,
    key: string,
  ) => {
    const productDetail = FormBuilder.group({
      name: [{ ...product, id: product.product_id }, Validators.required],
      quantity: [product.quantity, Validators.required],
      unit: [product.unit, Validators.required],
      description: [product.description, Validators.required],
    });
    productDetail.meta = { key };
    if (result[address.id]) {
      const productControls = result[address.id].get("products") as FormArray;
      productControls.push(productDetail);
    } else {
      result[address.id] = FormBuilder.group({
        delivery: [address, Validators.required],
        products: FormBuilder.array([productDetail]),
      });
    }
  };

  const addNewContactHandler = () => {
    const pocControls = productForm.get("poc") as FormArray;
    const control = NEW_CONTACT_FORM;
    control.meta = {
      key: pocControls.length + 1,
    };
    pocControls.push(control);
  };

  const addNewAddressHandler = () => {
    const addressControls = productForm.get("address") as FormArray;
    const index = addressControls.length + 1;
    const control = FormBuilder.group({
      delivery: null,
      products: FormBuilder.array([
        FormBuilder.group({
          name: [null, Validators.required],
          quantity: [null, Validators.required],
          description: [null, Validators.required],
          unit: [null, Validators.required],
        }),
      ]),
    });
    control.meta = {
      key: index,
    };
    addressControls.push(control);
  };

  return (
    <main
      className="p-24 new-task-form-section position-relative"
      style={{ overflowY: openNewClientForm ? "hidden" : "unset" }}
    >
      <div className="new-task-form-header flex-row align-items-center justify-content-space-between">
        <div className="flex-row align-items-center gap-8">
          <img
            className="new-task-image"
            alt="newTask"
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026191944-duplicate.svg"
          />
          <span className="new-task-heading">New Task</span>
        </div>
        <div className="flex-row align-items-center ">
          <img
            src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231012084858-close.svg"
            alt="close"
            width={18}
            height={18}
            className="cursor-pointer"
            onClick={onClose}
          ></img>
        </div>
      </div>
      <FieldGroup
        control={productForm}
        strict={false}
        render={() => (
          <form className="new-task-form flex-column gap-12">
            <div className="form-row">
              <FieldControl
                name="name"
                render={({ handler }: AbstractControl) => (
                  <span className="form-data flex-column">
                    <p className="input-label xetgo-font-tag-bold">Name</p>
                    <input
                      {...handler()}
                      className="text-input px-8 py-4 xetgo-font-tag"
                    />
                  </span>
                )}
              />
              <FieldControl
                name="priority"
                render={() => (
                  <span className="form-data flex-column">
                    <p className="input-label xetgo-font-tag-bold">Priority</p>
                    <Filter
                      option={{ key: "priority", name: "Priority" }}
                      optionList={TASK_PRIORITY}
                      multipleSelection={false}
                      onSelection={(val, option) => {
                        productForm.patchValue({ priority: val[0] });
                      }}
                      preSelected={
                        isNullOrUndefined(productForm.get("priority").value)
                          ? []
                          : [productForm.get("priority").value]
                      }
                      mode="showNames"
                      showPlaceholder={false}
                      borderStyle="solid"
                      addNew={false}
                    />
                  </span>
                )}
              />
            </div>

            <FieldControl
              name="description"
              render={() => (
                <span className="form-data flex-column">
                  <p className="input-label xetgo-font-tag-bold">Description</p>
                  <span className="description-input">
                    <XetEditor
                      value={productForm.get("description").value || ""}
                      defaultHeight="120px"
                      onChange={handleDescriptionChange}
                    />
                  </span>
                </span>
              )}
            />

            <FieldControl
              name="client"
              render={() => (
                <span className="form-data flex-column">
                  <p className="input-label xetgo-font-tag-bold">Client</p>
                  <Filter
                    option={{ key: "client", name: "Client Name" }}
                    optionList={clients}
                    multipleSelection={false}
                    onSelection={(val, _option) => {
                      loadClientAddress(val);
                    }}
                    mode="showNames"
                    showPlaceholder={false}
                    borderStyle="solid"
                    preSelected={
                      isNullOrUndefined(productForm.get("client").value)
                        ? []
                        : [productForm.get("client").value]
                    }
                    addNew={true}
                    onAddNew={() => setOpenNewClientForm(true)}
                  />
                </span>
              )}
            />

            <FieldArray
              name="poc"
              render={(pocList) => {
                const { controls } = pocList as FormArray;
                return (
                  <div className="flex-column gap-12">
                    <div className="poc-row full-width">
                      <p className="xetgo-font-tag-bold flex-1">Name</p>
                      <p className="xetgo-font-tag-bold flex-1">Phone Number</p>
                      <p className="xetgo-font-tag-bold flex-1">Email</p>
                      <p className="xetgo-font-tag-bold flex-1">Designation</p>
                    </div>
                    {controls.map(
                      (pocControl: AbstractControl, index: number) => (
                        <div key={`${pocControl.meta.key}-${String(index)}`}>
                          <FieldGroup
                            control={pocControl}
                            render={() => (
                              <div className="poc-row" key={index}>
                                <FieldControl
                                  name="name"
                                  render={({ handler }) => (
                                    <input
                                      {...handler()}
                                      className="text-input px-8 py-4 xetgo-font-tag flex-1"
                                    />
                                  )}
                                />
                                <FieldControl
                                  name="mobile"
                                  render={({ handler }) => (
                                    <input
                                      {...handler()}
                                      className="text-input px-8 py-4 xetgo-font-tag flex-1"
                                    />
                                  )}
                                />
                                <FieldControl
                                  name="email"
                                  render={({ handler }) => (
                                    <input
                                      {...handler()}
                                      className="text-input px-8 py-4 xetgo-font-tag flex-1"
                                      key={`email-${index}`}
                                    />
                                  )}
                                />
                                <FieldControl
                                  name="designation"
                                  render={({ handler }) => (
                                    <input
                                      {...handler()}
                                      className="text-input px-8 py-4 xetgo-font-tag flex-1"
                                      key={`designation-${index}`}
                                    />
                                  )}
                                />
                              </div>
                            )}
                          />
                        </div>
                      ),
                    )}
                    <span
                      className="flex-row xetgo-font-tag-bold cursor-pointer gap-2"
                      style={{ color: "#5151EC", width: "fit-content" }}
                      onClick={addNewContactHandler}
                    >
                      <img
                        src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                        alt="plus"
                        width={16}
                        height={16}
                      />
                      Add one more contact detail
                    </span>
                  </div>
                );
              }}
            />

            {productForm.get("client").value && (
              <FieldArray
                name="address"
                strict={false}
                render={(addressList) => {
                  const { controls } = addressList as FormArray;
                  return (
                    <div className="flex-column gap-12">
                      {controls.map(
                        (addressControl: AbstractControl, index: number) => (
                          <div
                            key={`${addressControl.meta.key}-${String(index)}`}
                          >
                            <AddProduct
                              productForm={addressControl}
                              clientId={productForm.get("client").value?.id}
                              onCsvFileAdded={handleCsvFile}
                            />
                          </div>
                        ),
                      )}
                      <span
                        className="flex-row xetgo-font-tag-bold cursor-pointer gap-2"
                        style={{
                          color: "#5151EC",
                          width: "fit-content",
                        }}
                        onClick={addNewAddressHandler}
                      >
                        <img
                          src="https://d2k6zobmg5lufr.cloudfront.net/assets%2F20231026080040-purple_add.svg"
                          alt="plus"
                          width={16}
                          height={16}
                        />
                        Add one more delivery address
                      </span>
                    </div>
                  );
                }}
              />
            )}
            <FieldControl
              name="uploads"
              render={() => (
                <div className="new-rfq-form-file-list">
                  <TaskFiles
                    fileList={productForm.get("uploads").value}
                    deleteFile={deleteFile}
                    handleFileUpload={handleFileChange}
                  />
                </div>
              )}
            />
          </form>
        )}
      />
      <div className="footer py-12 px-24">
        <button
          className="create-task-button cursor-pointer xetgo-font-tag px-10 py-4"
          onClick={handleSubmit}
        >
          Create Task
        </button>
      </div>
      {openNewClientForm && (
        <div className="new-user-form-container position-relative">
          <NewCompanyForm
            type="CLIENT"
            onCancel={() => setOpenNewClientForm(false)}
            onAddClient={addNewCompany}
          />
        </div>
      )}
      {openNewAddressForm && (
        <div className="new-user-form-container position-relative">
          <NewAddressForm
            type="CLIENT"
            onCancel={() => setOpenNewAddressForm(false)}
            user_id={productForm.get("client").value.id}
            onAddAddress={addNewAddress}
          />
        </div>
      )}
    </main>
  );
};

export default NewRFQForm;
