import React, { useCallback, useEffect, useRef, useState } from "react";
import "./TaskCardsList.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  updatePurchaseOrderDetailStart,
  updateTaskDetailStart,
} from "../../redux/oms/omsActions";
import { TaskCard as TaskCardSchema } from "../../models/Task";
import { selectStatusAllowed } from "../../redux/oms/omsSelectors";
import { toast } from "react-toastify";
import { toastOptions } from "../../utils/toast";
import XetModal from "../shared/xet-modal/XetModal";
import SampleDialog from "../SampleDialog/SampleDialog";
import RFQLostReasonDialog from "../RFQLostReasonDialog/RFQLostReasonDialog";
import TaskCard from "../oms/TaskCard/TaskCard";
import { VariableSizeList as List } from "react-window";
import ResizeObserver from "resize-observer-polyfill";

interface TaskCardListProps {
  tasks: TaskCardSchema[];
  status: string;
}

const TaskCardsList: React.FC<TaskCardListProps> = ({ tasks, status }) => {
  const dispatch = useDispatch();
  const [dragging, setDragging] = useState(-1);
  const [sampleDialog, setSampleDialog] = useState(-1);
  const [RFQLostDialog, setRFQLostDialog] = useState(-1);
  const { category } = useParams();
  const location = useLocation();
  const statusAllowed = useSelector(selectStatusAllowed);
  const [totalHeightDiv, setTotalHeightDiv] = useState(800);
  const [heights, setHeights] = useState<number[]>(
    new Array(tasks.length).fill(170),
  ); // Initial default heights
  const listRef = useRef<List>(null);

  useEffect(() => {
    const total = document.getElementById("total-card-status-list");
    if (total?.getBoundingClientRect()) {
      setTotalHeightDiv(total?.getBoundingClientRect().height);
    }
  }, []);

  const updateStatus = (id: number, status: string, reason?: string) => {
    const source = location.pathname.indexOf("eqs") > -1 ? "EQS" : "XETRACK";
    if (source === "EQS") {
      dispatch(
        updateTaskDetailStart({ id, status, category: category || "", reason }),
      );
    } else {
      dispatch(
        updatePurchaseOrderDetailStart({
          id,
          status,
          category: category || "",
          reason,
        }),
      );
    }
  };

  const taskDragOver = (event: React.DragEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const taskDrop = (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const payload = JSON.parse(event.dataTransfer.getData("id"));
    const id = parseInt(payload.id, 10);
    const oldStatus = payload.status;
    if ((statusAllowed[oldStatus] || []).indexOf(status) > -1) {
      if (status == "Samples") {
        setSampleDialog(id);
        return;
      }
      if (status == "RFQ Lost") {
        setRFQLostDialog(id);
        return;
      }
      updateStatus(id, status);
    } else {
      toast.warning("Update to that status is not allowed !!", toastOptions);
    }
  };

  const handleSampleClose = () => {
    setSampleDialog(-1);
  };

  const setHeight = useCallback((index: number, size: number) => {
    setHeights((prevHeights) => {
      const newHeights = [...prevHeights];
      newHeights[index] = size;
      return newHeights;
    });

    if (listRef.current) {
      listRef.current.resetAfterIndex(index);
    }
  }, []);

  const getItemSize = useCallback(
    (index: number) => heights[index] || 50,
    [heights],
  );

  const TaskItem: React.FC<{ task: TaskCardSchema; index: number }> = ({
    task,
    index,
  }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (ref.current) {
        const resizeObserver = new ResizeObserver((entries) => {
          for (const entry of entries) {
            if (entry.target === ref.current) {
              const height = entry.contentRect.height;
              if (heights[index] !== height) {
                setHeight(index, height);
              }
            }
          }
        });

        resizeObserver.observe(ref.current);

        return () => {
          resizeObserver.disconnect();
        };
      }
    }, [task, index, heights, setHeight]);

    return (
      <div ref={ref}>
        <TaskCard
          task={task}
          status={status}
          category={category}
          dragging={dragging}
          setDragging={setDragging}
        />
        <div style={{ height: "8px" }}></div>
      </div>
    );
  };

  return (
    <>
      <div
        className="full-height"
        id="total-card-status-list"
        onDrop={taskDrop}
        onDragOver={taskDragOver}
        onDragEnd={() => setDragging(-1)}
      >
        <List
          height={totalHeightDiv} // Adjust based on your needs
          itemCount={tasks.length}
          itemSize={getItemSize}
          width={"100%"}
          ref={listRef}
          className="tasks-container flex-column gap-8"
        >
          {({ index, style }) => (
            <div style={style}>
              <TaskItem task={tasks[index]} index={index} />
            </div>
          )}
        </List>
      </div>

      {
        <XetModal
          backdropClose={true}
          open={sampleDialog !== -1}
          handleClose={handleSampleClose}
        >
          <SampleDialog
            handleClose={handleSampleClose}
            taskId={sampleDialog}
            onSampleFormSubmit={updateStatus}
          />
        </XetModal>
      }
      {
        <XetModal
          backdropClose={true}
          open={RFQLostDialog !== -1}
          handleClose={() => setRFQLostDialog(-1)}
        >
          <RFQLostReasonDialog
            handleClose={() => setRFQLostDialog(-1)}
            taskId={RFQLostDialog}
            reasonSubmitted={updateStatus}
          />
        </XetModal>
      }
    </>
  );
};

export default TaskCardsList;
